import { useDispatch } from "react-redux";
import Tabcontent from "../../components/UI/TabContent";
import { partners, searchPartner } from "../../store/actions";
import { setTableBodyData } from "../../store/reducers/appSlice";
import PakamFinancials from "./Finance/Pakam/PakamFinancials";
import Partners from "./Finance/Partners/Partners";
import Filter from "../../components/UI/Filter";
import { useEffect, useState } from "react";
import { fetchFinancialSummaryNew, filterFinancialSummaryNew } from "../../store/actions/financialsActions";
import { formatAmount } from "../../utils";



const Financials = () => {
  const dispatch = useDispatch();
  const [summaryData, setSummaryData] = useState();
  const d = new Date();
	d.setDate(d.getDate());
	const payload = {
		start: "2010-01-01",
		end: d,
	};

  const handlSearch = async (key) => {
    const res = await dispatch(searchPartner({ key }));
    dispatch(setTableBodyData(res?.payload?.data));
  };

  const handleRefresh = async () => {
    const res = await dispatch(partners());
    dispatch(setTableBodyData(res?.payload?.data));
  };

  const fetchCardData = async () => {
		const data = await dispatch(fetchFinancialSummaryNew(payload));
		setSummaryData(data.payload);
	};

  const handleSummaryDataFilter = async (date) => {

		const res = await dispatch(
			filterFinancialSummaryNew({
				...date
			})
		);
		if (!res.error) {

      const {payload} = res
      setSummaryData(payload)
		}
	};


  useEffect(()=>{
    fetchCardData()
  }, [])

  const onRefresh = () => {

    handleRefresh();
  };

  const cardData = [
    {
      title:'Total Waste (KG)',
      amount: summaryData?.totalWaste || 0,
      icon:'/assets/images/f1.svg'
    },	
    {
      title:'Income from Organizations',
      amount:summaryData?.organizationIncome || 0,
      icon:'/assets/images/f2.svg'
    },	
    {
      title:'Transaction Point',
      amount:summaryData?.transactionPoint || 0,
      icon:'/assets/images/f3.svg'
    },	
    {
      title:'Pakam’s Commission',
      amount:summaryData?.commission || 0, 
      icon:'/assets/images/f4.svg'
      
    },	
  
  ]

  const data = [
    {
      title: "Pakam",
      noTable: true,
      children: <PakamFinancials />,
    },
    {
      title: "Partners",
      hideTable: true,
      node: <Partners />,
      hideFilter: true,
      searchHandler: handlSearch,
    },
  ];

  return (
    <div>

      <div className="flex justify-between w-full">

        <div>
          <h1 className=" font-Int text-[24px] font-[600]">Financials</h1>
          <p className="text-[#667185] font-Int text-[14px] mt-[-20px]">Manage all financial activities on Pakam</p>
        </div>

        <div>
          <Filter onFilter={handleSummaryDataFilter}/>
        </div>

      </div>

      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container mt-2 mb-2">

        {
          cardData.map((i, id)=>{
            return(
              <PointCard key={id} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
            )
          })
        }
        </div>


      <PakamFinancials/>
    </div>
  )
};

export default Financials;




export const PointCard = ({title, amount , icon}) =>{
	return(
		<div className="shadow-md rounded-lg bg-white border border-[#E4E7EC] p-4 flex justify-between gap-3">
			<div>
				<p className="font-[400] text-[#475367] font-Int text-[12px]">{title}</p>
				<h1 className="font-[600] text-[#344054] font-Int text-[18px] ">{amount}</h1>
			</div>
			<img className="w-40px] h-[40px]" src={icon} alt="icon" />
		</div>
	)
}