import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { toggleStatusAggregator } from "../../store/actions";
import { ApproveNewAggregators, DeleteNewAggregators } from "../../store/actions/dashboardAction";
import { set } from "lodash";
import Modal from "./modal";

const ModalBackground = styled.div`
  width: 90px;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  z-index: 999;
  right: 0px;
  .menu:hover {
    color: green;
  }
  .modalContainer {
    position: relative;
  }
`;

function ApproveCollectorModal({
  setModalOpen,
  data,
  onRefresh,
  deleteHandler,
  approveHandler
}) {


  return (
    <>
      <div className="modalContainer">
        <ModalBackground>
          <Menu style={{ height: "100px" }}>
            <Menu.Item key="0">
              <Link to="#" className="menu" onClick={approveHandler}>
                {/* {currentStatus} */}
                Approve
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1">
              <Link to="#" className="menu" onClick={deleteHandler}>
                Delete
              </Link>
            </Menu.Item>
          </Menu>
        </ModalBackground>
      </div>
    </>
  );
}

export default ApproveCollectorModal;
