import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { FlexBetween, FlexContainer } from "../styledElements";
import { ReactComponent as PakamLogo } from "../../assets/images/PakamLogo.svg";
import { fetchTransactionInvoice } from "../../store/actions";
import { useDispatch } from "react-redux";
import { capitalize } from "../../utils";

const Wrapper = styled.section`
  /* background-image: url("../../assets/images/InvoicePakam.svg"); */
  ${tw`bg-invoice-bg bg-no-repeat bg-center w-full bg-cover p-6`}
`;
const SummarySection = styled.div`
  ${tw`mt-8 pr-3`};

  > p:first-of-type {
    ${tw`font-bold text-[rgba(0, 0, 0, 0.5)] text-lg`}
  }
  > div:first-of-type {
    ${tw`border-t border-b py-2`}
  }

  > div:first-of-type > p {
    ${tw`text-base`};
    font-weight: lighter;
  }

  > div:nth-child(3) {
    ${tw`mt-4`}
  }

  > div:nth-child(3) > p {
    ${tw` text-base`}
  }

  > div:nth-child(4) > p {
    ${tw``}
  }
`;

const SummaryTable = styled.div`
  ${tw`mt-[38px] mb-[81px] w-full`};

  > div:first-of-type {
    ${tw`text-base font-bold mb-2`}
  }
  > div:nth-child(n + 2) {
    ${tw`mb-5`}
  }

  > div:nth-child(n + 2) {
    ${tw`font-light text-base`}
  }

  > div > p {
    ${tw`w-1/5`}
  }
`;

const Footer = styled.footer`
  ${tw`flex flex-col items-start justify-between bg-[#F9F9FA] p-6 text-pkblack-100 -mx-6 -mb-6 space-y-[54px]`}
`;

const Invoice = ({ invoiceId, open}, ref ) => {

  /****************************
   *
   * states and hooks
   *
   ****************************/
  const [invoice, setInvoice] = useState();
  const dispatch = useDispatch();
  const entries = [
    {
      ref: "5373748",
      description: "Payment to someone",
      category: "Can, plastic, nylons",
      weight: "10kg",
      amount: "23000",
    },
    {
      ref: "5373748",
      description: "Payment to someone",
      category: "Can, plastic, nylons",
      weight: "10kg",
      amount: "23000",
    },
    {
      ref: "5373748",
      description: "Payment to someone",
      category: "Can, plastic, nylons",
      weight: "10kg",
      amount: "23000",
    },
  ];
  
  const fetchInvoice = async () => {
    const response = await dispatch(fetchTransactionInvoice(invoiceId));
    console.log(response)
    if (!response.payload.error) setInvoice(response.payload.data);
  };

  useEffect(() => {
    if (invoiceId) fetchInvoice();
  }, [invoiceId]);

  useEffect(()=>{
    if(!open) setInvoice()
  },[open])

  return (
    <Wrapper ref={ref}>
      <div className="flex items-center justify-between pr-1 pb-[64px] pt-[35px]">
        <PakamLogo />
        <p className="font-bold text-5xl">INVOICE</p>
      </div>
      <div>
        <div className="flex items-start justify-between">
          <div className="">
            <p className="text-primary font-bold text-sm">
              {invoice?.from.name}
            </p>
            <p className="text-pkblack-100 text-xs">{invoice?.from.address}</p>
          </div>
          <div className="max-w-[224px] flex flex-col items-end">
            <p className="text-sm font-bold text-pkblack-100">
              {invoice?.company?.companyName}
            </p>

            <p className="max-w-[167px] text-right text-xs text-pkblack-100">
              {invoice?.company?.location}.{`\n`}
              {invoice?.company?.email}.{`\n`}
              {invoice?.company?.phone}.
            </p>
            <p className="max-w-[167px] text-right text-xs text-pkblack-100">
              {invoice?.company?.companyTag}.
            </p>
          </div>
        </div>
        <div className="flex items-end justify-end pt-[21px] ">
          <div className="flex items-start justify-between gap-x-[54px]">
            <div className="flex items-start flex-col">
              <p>INVOICE NUMBER:</p>
              <p>INVOICE START DATE:</p>
              <p>INVOICE END DATE</p>
              <p>INVOICE STATUS</p>
              <p className='font-bold'>EXPECTED PAYMENT DATE</p>
            </div>
            <div className="flex items-end  flex-col">
              <p>{invoiceId}</p>
              <p>{invoice?.startDate.slice(0, 10)} </p>
              <p> {invoice?.endDate.slice(0, 10)}</p>
              <p> {capitalize(invoice?.paidStatus)}</p>
              <p className='font-bold'> {invoice?.expectedPaymentDate.slice(0, 10)}</p>
            </div>
          </div>
        </div>
        {/* <p className="font-medium text-base">
          Final Temi invoice from{" "}
          <span className="w-2/3">{invoice?.startDate.slice(0, 10)}</span> to
          <span className="w-2/3"> {invoice?.endDate.slice(0, 10)}</span>{" "}
          billing period
        </p> */}
        {/* <FlexBetween className="mt-5">
          <div>
            <p className="font-bold">From</p>
            <p>
              {invoice?.from.name},
              <br /> {invoice?.from.address},
              <br />
              {invoice?.from.country}.
            </p>
          </div>
          <div>
            <p className="font-bold">To</p>
            <p>
              {invoice?.company?.companyName},
              <br /> {invoice?.company?.companyTag}.
              <br /> {invoice?.company?.email}.
              <br /> {invoice?.company?.phone}.
            </p>
          </div>
          <div>
            <p className="font-bold">Details</p>
            <p className="w-full">{invoice?.invoiceNumber},</p>
            <FlexContainer className="w-[150px]">
              <span className="w-1/3">Start:</span>
              <span className="w-2/3">{invoice?.startDate.slice(0, 10)}</span>
            </FlexContainer>
            <FlexContainer className="w-[150px]">
              <span className="w-1/3">End:</span>
              <span className="w-2/3">{invoice?.endDate.slice(0, 10)}</span>
            </FlexContainer>
          </div>
        </FlexBetween> */}
      </div>
      <div className="mt-[37px]">
        <p className="text-sm font-medium">Summary</p>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className="flex items-center justify-between text-xs">
          <p> Total Collection fee:</p>
          <p>₦{invoice?.amountWithoutServiceCharge}</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-xs">
          <p> Total Usage charge + VAT charges 7.5%:</p>
          <p>₦ {invoice?.serviceCharge}</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-xs">
          <p> Total Amount Payable:</p>
          <p>₦ {invoice?.amount}</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-xs">
          <p> Total Amount Paid:</p>
          <p>₦ {invoice?.totalPaid}</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-base font-semibold py-[10px]">
          <p> Total due</p>
          <p>₦ {invoice?.totalBalance}</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
      </div>
      {/* <SummarySection> */}
      {/* <p>Summary</p>
        <FlexBetween>
          <p>Total Service Charge</p>
          <p>
            <span className="mt-1">&#8358;</span>
            {invoice?.serviceCharge}
          </p>
        </FlexBetween> */}

      {/* <FlexBetween>
          <p>Collection Amount</p>
          <p>
            <span className="mt-1 font-medium">&#8358;</span>
            {invoice?.amountWithoutServiceCharge}
          </p>
        </FlexBetween> */}
      {/* 
        <FlexBetween>
          <p className="font-bold">Total Bill</p>
          <p>
            <span className="mt-1">&#8358;</span>
            {invoice?.amount}
          </p>
        </FlexBetween> */}
      {/* <p className="text-base text-black font-light">
          If you have a credit card on file, it will be automatically charged
          within 24 hours
        </p> */}
      <SummaryTable>
        <FlexContainer className="font-bold">
          <p>Ref No</p>
          {/* <p>Description</p> */}
          <p className="-ml-5">Customer’s Address</p>
          <p>Customer’s Phone No</p>
          {/* <p>Waste category</p> */}
          <p>Weight (Kg)</p>
          <p className="">Amount (&#8358;)</p>
        </FlexContainer>
        {invoice?.transactions.map((entry) => {
          const categories = entry.categories.map((cat) => cat.name).join(", ");
          return (
            <FlexContainer className="font-bold">
              <p>{entry._id.slice(0, 6)}</p>
              <p className="-ml-5">{entry.address}</p>
              <p>{entry.phone}</p>
              <p>{entry.weight?.toFixed()}</p>
              <p>{entry.amountTobePaid}</p>
            </FlexContainer>
          );
        })}
      </SummaryTable>
      {/* </SummarySection> */}
      <Footer>
        <div className="">
          <p className="font-bold text-base text-secondary">
            PAYMENT INSTRUCTIONS
          </p>
          <p className="mt-3 mb-2  text-pkblack-100 text-sm">
            Pakam Technology
          </p>
          <div className="space-y-[4px] text-pkblack-100 text-sm">
            <p>Bank name: First Bank</p>
            <p>Account Name: Pakam Technology</p>
            <p>Account number: 00234567830</p>
          </div>
        </div>
        <p className="">
          For any questions please contact us at support@pakam.ng
        </p>
      </Footer>
    </Wrapper>
  );
};

export default forwardRef(Invoice);
