import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import FormInput from "../auth/FormInput";
import { FlexContainer, TitleText } from "../styledElements";
import StyledButton from "../UI/btn";
import modal from "../UI/modal";
import MultiSelect from "../UI/multiSelect";
import Select from "../UI/select";
import useForm from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { createManagedArea, updateManagedArea } from "../../store/actions";


const Modal = styled(modal)`
  ${tw``};
  //   input {
  //     transform: scaleY(0.7);
  //   }
`;

const initData = {
  // id: {
  //   label: "Area ID",
  //   value: "",
  //   placeholder: "Autogenerated Value",
  //   disabled: true,
  // },
  lga: {
    label: "LCDA/LGA",
    // optionIdx: 1,
    type: "select",
    title: "Select your preferred LGA/LCDA",
    options: [
      { text: "Niger", value: "niger" },
      { text: "Nigeria", value: "nigeria" },
      { text: "Nepal", value: "nepal" },
      { text: "U.S", value: "us" },
      { text: "england", value: "england" },
    ],
  },
  coverageArea: {
    label: "Coverage Area",
    // optionIdx: 1,
    type: "select",
    title: "Enter your preferred area",
    options: [
      { text: "Niger", value: "niger" },
      { text: "Nigeria", value: "nigeria" },
      { text: "Nepal", value: "nepal" },
      { text: "U.S", value: "us" },
      { text: "england", value: "england" },
    ],
  },
};

const NewAreaModal = ({
  data,
  showModal = false,
  setShowModal = {},
  selectedArea,
  mode = "create",
  onRefresh
}) => {
  const formData = mode === "create" ? data : selectedArea;
  const dispatch = useDispatch();
  const { setValue, errorMsgs, formValues } = useForm(formData);

  const texts = {
    title: { create: "Setup", update: "Modify" }[mode],
    buttionText: { create: "Setup Area", update: "Update" }[mode],
  };

  const submitHandler = (type) => {
    switch (type) {
      case "Setup Area":
        dispatch(createManagedArea(formValues)).unwrap().then(() => {
          onRefresh();
        });
        break;
      case "Update":
        const myValues = {
          id: selectedArea.lga.lgaId,
          formValues,
        };
        
        dispatch(updateManagedArea(myValues)).unwrap().then(() => {
          onRefresh();
        });
        break;
      default:
    }
  };

  return (
    <Modal show={showModal} close={() => setShowModal(false)} width="30rem">
      <FlexContainer className="justify-between">
        <TitleText className="">{texts.title} Area</TitleText>
        <StyledButton
          buttonSize="btn--medium"
          onClick={() => setShowModal(false)}
        >
          close
        </StyledButton>
      </FlexContainer>
      <div className="flex flex-col">
        {Object.entries(formData).map(([key, input]) => {
          switch (input.type) {
            case "select":
              return (
                <Select
                  key={key}
                  width="100%"
                  height="3.5rem"
                  plain={true}
                  options={input.options}
                  label={input.label}
                  title={input.title}
                  changeHandler={(v) => setValue(key, v)}
                  optionIdx={input.optionIdx !== null && input.optionIdx}
                  disabled={input.disabled}
                  checkbox={input.checkbox}
                />
              );
            case "multiselect":
              return (
                <MultiSelect
                  key={key}
                  width="100%"
                  height="3.5rem"
                  plain={true}
                  options={input.options}
                  label={input.label}
                  title={input.title || input.label}
                  changeHandler={(v) => setValue(key, v)}
                  optionIdxs={input.optionIdxs || null}
                  disabled={input.disabled}
                />
              );
            default:
              return (
                <FormInput
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.label}
                  key={input.label}
                  height="3.5rem"
                  changeHandler={(e) => setValue(key, e.target.value)}
                  errorMsg={errorMsgs[key]}
                  value={formValues[key]}
                  disabled={input.disabled}
                />
              );
          }
        })}
        <div className="max-w-content">
          <StyledButton
            buttonSize="btn--medium"
            buttonStyle="btn--primary--outline"
            onClick={() => {
              submitHandler(texts.buttionText)
              setShowModal(false)
            }}
          >
            {texts.buttionText}
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};

export default NewAreaModal;
