import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as PakamLogo } from "../assets/images/PakamLogo.svg";
import { FlexContainer } from "../components/styledElements";

const SummaryTable = styled.div`
  ${tw`mt-[38px] mb-[81px] w-full`};

  > div:first-of-type {
    ${tw`text-base font-bold mb-2`}
  }
  > div:nth-child(n + 2) {
    ${tw`mb-5`}
  }

  > div:nth-child(n + 2) {
    ${tw`font-light text-base`}
  }

  > div > p {
    ${tw`w-1/4`}
  }
`;
const Footer = styled.footer`
  ${tw`flex flex-col items-start justify-between bg-[#F9F9FA] p-6 text-pkblack-100 -mx-6 -mb-6 space-y-[54px]`}
`;
const Invoice = () => {
  return (
    <section className="bg-invoice-bg bg-no-repeat bg-center w-full bg-cover p-6">
      <div className="flex items-center justify-between pr-1 pb-[64px] pt-[35px]">
        <PakamLogo />
        <p className="font-bold text-5xl">INVOICE</p>
      </div>
      <div className="flex items-start justify-between">
        <div className="">
          <p className="text-primary font-bold text-sm">Pakam Technology</p>
          <p className="text-pkblack-100 text-xs">
            127 Ogunlana Drive Surulere
          </p>
        </div>
        <div className="max-w-[224px] flex flex-col items-end">
          <p className="text-sm font-bold text-pkblack-100">
            Street Waste Company Limited
          </p>
          <p className="max-w-[167px] text-right text-xs text-pkblack-100">
            159 Igbosere road Lagos Island. gogreen@swcl.com.ng
          </p>
          <p className="max-w-[167px] text-right text-xs text-pkblack-100">
            9031108000.
          </p>
        </div>
      </div>
      <div className="flex items-end justify-end pt-[21px] ">
        <div className="flex items-start justify-between gap-x-[54px]">
          <div className="flex items-start flex-col">
            <p>INVOICE NUMBER:</p>
            <p>INVOICE Date:</p>
            <p>DUE</p>
          </div>
          <div className="flex items-end  flex-col">
            <p>550212916</p>
            <p>21-09-2022 </p>
            <p>23-09-2022</p>
          </div>
        </div>
      </div>
      <div className="mt-[37px]">
        <p className="text-sm font-medium">Summary</p>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className="flex items-center justify-between text-xs">
          <p> Total Collection fee:</p>
          <p>₦9,250</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-xs">
          <p> Total Usage charge + VAT charges 7.5%:</p>
          <p>₦9,250</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
        <div className=" flex items-center justify-between text-base font-semibold py-[10px]">
          <p> Total due</p>
          <p>₦9,250</p>
        </div>
        <div className="border border-[#A6A6A6] w-full my-[5px] opacity-50" />
      </div>
      <SummaryTable>
        <FlexContainer className="font-bold">
          <p>Customer’s Address</p>
          <p className="">Customer’s Phone No</p>
          <p>Weight(Kg)</p>
          <p>Amount(₦)</p>
        </FlexContainer>

        <FlexContainer className="font-bold">
          <p>Layi Ogunbambi Cl, Maryland 101233, Lagos,Nigeria</p>
          <p className="">08106003137</p>
          <p>185.00</p>
          <p>9,250</p>
        </FlexContainer>
      </SummaryTable>

      <Footer>
        <div className="">
          <p className="font-bold text-base text-secondary">
            PAYMENT INSTRUCTIONS
          </p>
          <p className="mt-3 mb-2  text-pkblack-100 text-sm">
            Pakam Technology
          </p>
          <div className="space-y-[4px] text-pkblack-100 text-sm">
            <p>Bank name: First Bank</p>
            <p>Account Name: Pakam Technology</p>
            <p>Account number: 00234567830</p>
          </div>
        </div>
        <p className="">
          For any questions please contact us at support@pakam.ng
        </p>
      </Footer>
    </section>
  );
};

export default Invoice;
