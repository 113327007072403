import { Col, DatePicker, Radio, Row, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const SpaceStyle = styled.div`
  margin-left: 8px;
  font-size: 20px;

  .group {
    ${tw`min-h-[40vh]`}
  }
`;

const ToStyle = styled.div`
  ${tw`ml-3`}
  font-size: 10px;
`;
const FromStyle = styled.div`
  font-size: 10px;
`;
const DateStyle = styled.div`
  ${tw`ml-3`}
`;

const dateFormat = "YYYY-MM-DD";

const FilterOptions = {
  all: {
    label: "All",
    date: { start: "2010-01-01", end: moment().format(dateFormat) },
  },
  yesterday: {
    label: "Yesterday",
    date: {
      start: moment().subtract(1, "days").format(dateFormat),
      end: moment().subtract(1, "days").format(dateFormat),
    },
  },
  lastWeek: {
    label: "Last Week",
    date: {
      start: moment().startOf("week").subtract(7, "days").format(dateFormat),
      end: moment().endOf("week").subtract(7, "days").format(dateFormat),
    },
  },
  lastMonth: {
    label: "Last Month",
    date: {
      start: moment().subtract(1, "months").date(1).format(dateFormat),
      end: moment().subtract(1, "months").endOf("month").format(dateFormat),
    },
  },
  thirtyDays: {
    label: "This Month",
    date: {
      start: moment().startOf("month").format(dateFormat),
      end: moment().endOf("month").format(dateFormat),
    },
  },
};






const RadioButton = ({ onFilter, rangeValue, isYear, isMonth }) => {


  const d = new Date();
  d.setDate(d.getDate());
  const year = d.getFullYear()
  const month = d.getMonth()

  const FilterMonthOptions = {
    // all: {
    //   label: "All",
    //   date: '',
    // },
    thisMonth: {
      label: "This Month",
      date: month+1,
    },
    lastMonth: {
      label: "Last Month",
      date: Number(month),
    },
    twoMonthsAgo: {
      label: "2 Months Ago",
      date:  Number(month) - 1,
    },
  };
  const FilterYearOptions = {
    thisYear: {
      label: "This Year",
      date: year,
    },
    lastYear: {
      label: "Last Year",
      date: Number(year) - 1,
    },
    twoYearsAgo: {
      label: "2 Years Ago",
      date:  Number(year) - 2,
    },
  };

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [value, setValue] = useState("");

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    const date = isYear? FilterYearOptions[selectedValue]?.date  : isMonth? FilterMonthOptions[selectedValue]?.date  : FilterOptions[selectedValue]?.date;

    if (selectedValue === "all"){
      setValue(selectedValue)
    }

    if (selectedValue === "dateRange") {
      setValue(selectedValue);
    } else {
      setValue(selectedValue);
      
      onFilter(date);
    }
  };

  const handleYear = (year)=>{
    onFilter(year);
  }

  const handleDateRange = (type, dateString) => {
    const formattedDate = moment(dateString).format(dateFormat);
    if (type === 'year' || type === 'month'){
      if (dateString?.includes('-')){
        onFilter(Number(dateString?.split('-')[1]))
      }
      else{
         onFilter(dateString)
      }
     
    }else{
      if (type === "start") {
        setFrom(formattedDate);
      } else {
        setTo(formattedDate);
      }
      if (!from || !to) return;
      else {
        onFilter({ start: from, end: to });
      }      
    }

  };

  useEffect(() => {
    handleDateRange("start", `${from}`);
  }, [from, to]);

  return (
    <div className="group">
      <Radio.Group onChange={handleRadioChange} value={value}>
        <SpaceStyle>
          <Space direction="vertical">
            {
              isYear ? 
              Object.keys(FilterYearOptions).map((key) => {
                const { label } = FilterYearOptions[key];
                return (
                  <Radio
                    key={label}
                    value={key}
                    className="capitalize mb-2 text-sm font-medium"
                  >
                    {label}
                  </Radio>
                );
              }):
              isMonth ? 
              Object.keys(FilterMonthOptions).map((key) => {
                const { label } = FilterMonthOptions[key];
                return (
                  <Radio
                    key={label}
                    value={key}
                    className="capitalize mb-2 text-sm font-medium"
                  >
                    {label}
                  </Radio>
                );
              })
              :
              Object.keys(FilterOptions).map((key) => {
                const { label } = FilterOptions[key];
                return (
                  <Radio
                    key={label}
                    value={key}
                    className="capitalize mb-2 text-sm font-medium"
                  >
                    {label}
                  </Radio>
                );
              })
            }




            {
              isYear?

              <Radio value="dateRange">
              Specific Year

              {value === "dateRange" && (
                <>
                  {/* <Row> */}
                    {/* <Col span={12}> */}
                      <Space direction="vertical" size={6}>
                        <FromStyle>From</FromStyle>

                        <DatePicker
                          // format={dateFormat}
                          picker="year"
                          onChange={(_, dateString) => {
                            handleDateRange("year", dateString);
                          }}
                        />
                      </Space>
                    {/* </Col> */}
                    {/* <Col span={12}>
                      <Space direction="vertical" size={6}>
                        <ToStyle>To</ToStyle>
                        <DateStyle>
                          <DatePicker
                            format={dateFormat}
                            onChange={(_, dateString) => {
                              handleDateRange("end", dateString);
                            }}
                          />
                        </DateStyle>
                      </Space>
                    </Col> */}
                  {/* </Row> */}
                </>
              )}
            </Radio>:
              isMonth?

              <Radio value="dateRange">
              Specific Month

              {value === "dateRange" && (
                <>
                  {/* <Row> */}
                    {/* <Col span={12}> */}
                      <Space direction="vertical" size={6}>
                        {/* <FromStyle>From</FromStyle> */}

                        <DatePicker
                          // format={dateFormat}
                          picker="month"
                          onChange={(_, dateString) => {
                            handleDateRange("month", dateString);
                          }}
                        />
                      </Space>
                    {/* </Col> */}
                    {/* <Col span={12}>
                      <Space direction="vertical" size={6}>
                        <ToStyle>To</ToStyle>
                        <DateStyle>
                          <DatePicker
                            format={dateFormat}
                            onChange={(_, dateString) => {
                              handleDateRange("end", dateString);
                            }}
                          />
                        </DateStyle>
                      </Space>
                    </Col> */}
                  {/* </Row> */}
                </>
              )}
            </Radio>

              :

              <Radio value="dateRange">
              Specific date range

              {value === "dateRange" && (
                <>
                  <Row>
                    <Col span={12}>
                      <Space direction="vertical" size={6}>
                        <FromStyle>From</FromStyle>

                        <DatePicker
                          format={dateFormat}
                          onChange={(_, dateString) => {
                            handleDateRange("start", dateString);
                          }}
                        />
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space direction="vertical" size={6}>
                        <ToStyle>To</ToStyle>
                        <DateStyle>
                          <DatePicker
                            format={dateFormat}
                            onChange={(_, dateString) => {
                              handleDateRange("end", dateString);
                            }}
                          />
                        </DateStyle>
                      </Space>
                    </Col>
                  </Row>
                </>
              )}
            </Radio>

              
            }


          </Space>
        </SpaceStyle>
      </Radio.Group>
    </div>
  );
};

export default RadioButton;


// import { Col, DatePicker, Radio, Row, Space } from "antd";
// import moment from "moment";
// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import tw from "twin.macro";

// const SpaceStyle = styled.div`
//   margin-left: 8px;
//   font-size: 20px;

//   .group {
//     ${tw`min-h-[40vh]`}
//   }
// `;

// const ToStyle = styled.div`
//   ${tw`ml-3`}
//   font-size: 10px;
// `;
// const FromStyle = styled.div`
//   font-size: 10px;
// `;
// const DateStyle = styled.div`
//   ${tw`ml-3`}
// `;

// const dateFormat = "YYYY-MM-DD";

// const FilterOptions = {
//   all: {
//     label: "All",
//     date: { start: "2010-01-01", end: moment().format(dateFormat) },
//   },
//   yesterday: {
//     label: "Yesterday",
//     date: {
//       start: moment().subtract(1, "days").format(dateFormat),
//       end: moment().subtract(1, "days").format(dateFormat),
//     },
//   },
//   lastWeek: {
//     label: "Last Week",
//     date: {
//       start: moment().startOf("week").subtract(7, "days").format(dateFormat),
//       end: moment().endOf("week").subtract(7, "days").format(dateFormat),
//     },
//   },
//   lastMonth: {
//     label: "Last Month",
//     date: {
//       start: moment().subtract(1, "months").date(1).format(dateFormat),
//       end: moment().subtract(1, "months").endOf("month").format(dateFormat),
//     },
//   },
//   thirtyDays: {
//     label: "This Month",
//     date: {
//       start: moment().startOf("month").format(dateFormat),
//       end: moment().endOf("month").format(dateFormat),
//     },
//   },
// };

// const RadioButton = ({ onFilter }) => {
//   // const [value, setValue] = useState("all");
//   // const [after, setAfter] = useState(null);
//   // const [before, setBefore] = useState(null);
//   // const [requestDate, setRequestDate] = useState();

//   // const onChange = (e) => {
//   //   setValue(e.target.value);
//   //   filterByDate(e.target.value);
//   // };

//   // function filterByDate(filterRequest) {
//   //   if (filterRequest === "all") {
//   //     let newdate = new Date();
//   //     const start = "2001-01-01";
//   //     // upperbound should be tomorrow
//   //     newdate.setDate(newdate.getDate());
//   //     const end = moment(newdate).format("YYYY-MM-DD");

//   //     setRequestDate({
//   //       start,
//   //       end,
//   //     });
//   //   }

//   //   if (filterRequest === "yesterday") {
//   //     // let newdate = new Date();
//   //     // newdate.setDate(newdate.getDate() - 1);

//   //     // requestDate.start = formatDate(newdate, 'yyyy-MM-dd', 'en-US');  // "2019-03-26",
//   //     const start = moment().subtract(1, "days"); // "2019-03-26",
//   //     const end = moment().subtract(1, "days"); // "2019-03-26",
//   //     // const end = formatDate(new Date(), 'yyyy-MM-dd', 'en-US'); // "2019-03-28",
//   //     setRequestDate({
//   //       start,
//   //       end,
//   //     });
//   //   }
//   //   if (filterRequest === "lastWeek") {
//   //     const start = moment().startOf("week").subtract(7, "days");
//   //     const end = moment().endOf("week").subtract(7, "days");
//   //     // let newdate = new Date();
//   //     // newdate.setDate(newdate.getDate() - 7);
//   //     // const start = moment(newdate).format("YYYY-MM-DD"); // "2019-03-26",
//   //     // const end = moment(new Date()).format("YYYY-MM-DD"); // "2019-03-28",
//   //     setRequestDate({
//   //       start,
//   //       end,
//   //     });
//   //   }
//   //   if (filterRequest === "thirtyDays") {
//   //     // let newdate = new Date();
//   //     // newdate.setDate(newdate.getDate() - 30);
//   //     // const start = moment(
//   //     //   new Date(date.getFullYear(), date.getMonth(), 1)
//   //     // ).format("YYYY-MM-DD"); // "2019-03-26",
//   //     // const end = moment(
//   //     //   new Date(date.getFullYear(), date.getMonth() + 1, 1)
//   //     // ).format("YYYY-MM-DD"); // "2019-03-28",
//   //     let thisMoment = moment();
//   //     // console.log("ThisMoment...", thisMoment);
//   //     // let newdate = new Date();
//   //     // newdate.setDate(newdate.getDate() - 30);
//   //     let start = moment(thisMoment).startOf("month");
//   //     let end = moment(thisMoment).endOf("month");

//   //     setRequestDate({
//   //       start,
//   //       end,
//   //     });
//   //   }
//   //   if (filterRequest === "lastMonth") {
//   //     // let newdate = new Date();
//   //     // newdate.setDate(newdate.getDate() - 30);
//   //     // const start = moment(newdate).format("YYYY-MM-DD"); // "2019-03-26",
//   //     // const end = moment(new Date()).format("YYYY-MM-DD"); // "2019-03-28",
//   //     let thisMoment = moment();
//   //     let start = new moment().subtract(1, "months").date(1);
//   //     let end = new moment().subtract(1, "months").date(1).endOf("month");
//   //     setRequestDate({
//   //       start,
//   //       end,
//   //     });
//   //   }

//   //   if (filterRequest === "dateRange") {
//   //     if (!before && !after) return;
//   //     const start = moment(before).format("YYYY-MM-DD");
//   //     const end = moment(before).format("YYYY-MM-DD");

//   //     if (before !== null && after !== null) {
//   //       setRequestDate({
//   //         // ...requestDate,
//   //         start,
//   //         end,
//   //       });
//   //     }
//   //   }
//   // }

//   // const dateFormat = "YYYY-MM-DD";

//   const [from, setFrom] = useState(null);
//   const [to, setTo] = useState(null);
//   const [value, setValue] = useState("all");

//   const handleRadioChange = (e) => {
//     const selectedValue = e.target.value;
//     const date = FilterOptions[selectedValue]?.date;

//     if (selectedValue === "dateRange") {
//       setValue(selectedValue);
//     } else {
//       setValue(selectedValue);
//       onFilter(date);
//     }
//   };

//   const handleDateRange = (type, dateString) => {
//     const formattedDate = moment(dateString).format(dateFormat);
//     if (type === "start") {
//       setFrom(formattedDate);
//     } else {
//       setTo(formattedDate);
//     }
//     if (!from || !to) return;
//     else {
//       onFilter({ start: from, end: to });
//     }
//   };

//   useEffect(() => {
//     handleDateRange("start", `${from}`);
//   }, [from, to]);

//   // useEffect(() => {
//   //   if (requestDate) onFilter(requestDate);
//   // }, [requestDate]);

//   // const [valueFrom, setValueFrom] = useState();
//   // const [valueTo, setValueTo] = useState();

//   // const handleDate = (rangeSide, { dateObj, dateStr }) => {
//   //   if (!["start", "end"].includes(rangeSide)) return;
//   //   rangeSide === "start" ? setBefore(dateObj) : setAfter(dateObj);
//   //   rangeSide === "start" ? setValueFrom(dateObj) : setValueTo(dateObj);
//   // };

//   return (
//     <div className="group">
//       <Radio.Group onChange={handleRadioChange} value={value}>
//         <SpaceStyle>
//           <Space direction="vertical">
//             {Object.keys(FilterOptions).map((key) => {
//               const { label } = FilterOptions[key];
//               return (
//                 <Radio
//                   key={label}
//                   value={key}
//                   className="capitalize mb-2 text-sm font-medium"
//                 >
//                   {label}
//                 </Radio>
//               );
//             })}

//             {/* <Radio value="all">All</Radio>
//             <Radio value="thirtyDays">This Month</Radio>
//             <Radio value="yesterday">Yesterday</Radio>
//             <Radio value="lastWeek">Last Week</Radio>
//             <Radio value="lastMonth">Last Month</Radio>

//             <Radio value="lastMonth">Last Month</Radio> */}
//             <Radio value="dateRange">
//               Specific date range
//               {/* {value === "dateRange" ? (
//             <RadioDate
//               setAfter={setAfter}
//               setBefore={setBefore}
//               changeHandler={() => {
//                 filterByDate("dateRange");
//               }}
//             />
//           ) : null} */}
//               {value === "dateRange" && (
//                 <>
//                   <Row>
//                     <Col span={12}>
//                       <Space direction="vertical" size={6}>
//                         <FromStyle>From</FromStyle>

//                         <DatePicker
//                           format={dateFormat}
//                           onChange={(_, dateString) => {
//                             handleDateRange("start", dateString);
//                           }}
//                         />
//                       </Space>
//                     </Col>
//                     <Col span={12}>
//                       <Space direction="vertical" size={6}>
//                         <ToStyle>To</ToStyle>
//                         <DateStyle>
//                           <DatePicker
//                             format={dateFormat}
//                             onChange={(_, dateString) => {
//                               handleDateRange("end", dateString);
//                             }}
//                           />
//                         </DateStyle>
//                       </Space>
//                     </Col>
//                   </Row>
//                 </>
//               )}
//             </Radio>
//           </Space>
//         </SpaceStyle>
//       </Radio.Group>
//     </div>
//   );
// };

// export default RadioButton;
