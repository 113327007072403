import { Menu } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DeleteModal from "../../components/common/DeleteModal";
import {
  deleteOrganisation,
  deleteSpaceOwner,
  getOrganisations,
} from "../../store/actions";
import { setModalOpening, setPayout } from "../../store/reducers/appSlice";
import { PayoutOptionModal } from "../../views/TotalUser/PayoutOptionModal";

const ModalBackground = styled.div`
  width: 240px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  right: 20px;
  margin-top: 0px;
  z-index: 999;
  border-radius: 20px;

  a:hover {
    color: green;
  }
  modalContainer {
    position: fixed;
  }
  h6 {
    margin-top: 15px;
  }
  .iconStyle {
    display: inline-block;
    margin-left: 190px;
  }
  hr {
    margin-top: 5px;
  }
`;

const OverlayBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  opacity: 0.7;
  z-index: 10;
`;

function OptionModal({
  setModalOpen,
  selectedOrganisation,
  optiondata,
  spaceOwner,
}) {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [, , setPostAction] = useState(false);
  const [pay, setPay] = useState([]);
  const [modaltoggler, setModalToggler] = useState(true);

  const date = new Date();
  const currentMonth = {
    start: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    ),
    end: moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format(
      "YYYY-MM-DD"
    ),
  };

  const dispatch = useDispatch();
  let history = useHistory();

  const deleteHandler = () => {
    dispatch(deleteOrganisation(selectedOrganisation._id))
      .unwrap()
      .then(() => {
        dispatch(getOrganisations(currentMonth));
        history.push("/admin/total_organizations");
      });
  };

  const deleteOwner = async () => {
    await dispatch(deleteSpaceOwner(selectedOrganisation._id));
    history.push("/admin/pakam-associates");
  };

  const handleModalToggler = () => {
    setModalToggler(!modaltoggler);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={spaceOwner ? deleteOwner : deleteHandler}
        type="account"
        title={spaceOwner?"Space Owner" : "Organisation"}
        name={selectedOrganisation?.companyName}
      />

      <div className="modalContainer">
        <ModalBackground>
          <Menu style={{ borderRadius: "3px" }}>
            {optiondata?.map(
              (
                { pathname, title, deleteflag, handler, payoutroutes },
                index
              ) => {
                return (
                  <>
                    <Menu.Item key={index}>
                      <Link
                        style={{
                          color: `${deleteflag && "red"}`,
                        }}
                        to={{
                          pathname: `${
                            pathname
                              ? `${pathname}${
                                  selectedOrganisation?._id ||
                                  selectedOrganisation
                                }`
                              : `${
                                  selectedOrganisation?._id ||
                                  selectedOrganisation
                                }`
                          }`,
                          state: { selectedOrganisation },
                        }}
                        onClick={() => {
                          if (deleteflag) {
                            setDeleteModal(true);
                            // setPostAction(true);
                          } else if (payoutroutes) {
                            handleModalToggler();
                            setPay(payoutroutes);
                            dispatch(setPayout(payoutroutes));
                            dispatch(setModalOpening(modaltoggler));
                          } else if (handler) {
                            handler();
                            setPostAction(true);
                          } else;
                        }}
                      >
                        {title}
                      </Link>
                    </Menu.Item>
                    <Menu.Divider />
                  </>
                );
              }
            )}
          </Menu>
        </ModalBackground>
        <OverlayBackground
          onClick={() => {
            setModalOpen(false);
            dispatch(setModalOpening(false));
          }}
        ></OverlayBackground>

        <PayoutOptionModal
          pay={pay}
          selectedOrganisation={selectedOrganisation}
        />
      </div>
    </>
  );
}

export default OptionModal;
