import React from "react";
import Tabcontent from "../../components/UI/TabContent";
import WastePicker from "../WastePicker/WastePicker";
import SpaceOwner from "./space/spaceOwner";

const PakamAssociates = () => {
  const onSwitch = () => {};
  const data = [
    {
      title: "Waste Pickers",
      noTable: true,
      children: <WastePicker />,
    },

    {
      title: "Space Owners",
      noTable: true,
      children: <SpaceOwner />,
    },
  ];

  return (
    <div>
      <Tabcontent data={data} associate onSwitch={onSwitch} />
    </div>
  );
};

export default PakamAssociates;
