import { Popover, Space, Tag } from "antd";
import { UserTransactionBreak, userDetail } from "../../store/actions/userAction";
import Button from "../../components/UI/button";
import DataTable from "../../components/UI/Table";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { truncate } from "../../utils/constants";
import tw from "twin.macro";
import styled from "styled-components";
import BreadCrumb from "../../components/UI/breadCrumbs";

export const UserContainer = styled.div`
  //   display: grid;
  //   grid-template-coloumns: auto 1fr;
  margin-bottom: 20px;
  display: grid;
`;

export const NavBarLeft = styled.div`
  ${tw`flex justify-between`}

  .text {
    font-size: 15px;
    color: "#0e0e0e";
  }
`;


const UserTransactions = ({ match }) => {
  const [bodyData, setBodyData] = useState();
  const [userData, setUserData] = useState();
  const [paginationData, setPaginationData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [rowInfo, setRowInfo] = useState([]);

  const {
    params: { id },
  } = match;


  const dispatch = useDispatch();

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };


  const fetchuser = async () => {
    const res = await dispatch(userDetail(id));
    if (!res.error) {
      const { data } = res.payload;
      // console.log(data)
      setUserData(data);
    }
  };

  useEffect(() => {
    fetchuser();
  }, []);

  const fetchAll = async (page = 1) => {
    const res = await dispatch(
      UserTransactionBreak({
        currentMonth: payload,
        page,
        id,
      })
    );

    if (!res.error) {
      console.log(res.payload.data)
      const { transaction, ...paginationData } = res.payload.data;
      setBodyData(transaction);
      setPaginationData({ ...paginationData, date: payload });
    }
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      UserTransactionBreak({
        currentMonth: date,
        page,
        id,
      })
    );
    if (!res.error) {
      const { transaction, ...paginationData } = res.payload.data;
      setBodyData(transaction);

      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      UserTransactionBreak({
        key: key || "",
        page,
        id,
      })
    );

    if (!res.error) {
      const { transaction, ...paginationData } = res.payload.data;
      setBodyData(transaction);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onRefresh = () => {
    fetchAll();
  };

  useEffect(() => {
    onRefresh();
  }, []);



  const columns = [
    {
      title: "Schedule Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{text.split('T')[0]}</p>,
    },
    {
      title: "Schedule Type",
      dataIndex: "type",
      key: "type",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Waste Category",
      dataIndex: "categories",
      key: "categories",
      render: (categories) => (
        <span>
          {(categories.slice(0, 3) || []).map((waste) => {
            return (
              <Tag key={waste}>
                <Popover content={waste?.name || waste}>
                  {truncate(waste?.name, 10)}
                </Popover>
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Waste Quantity (KG)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Amount",
      dataIndex: "amountTobePaid",
      key: "amountTobePaid",
    },

  ]

  // useEffect(()=>{
  //   setActiveCol(col_data.find((i)=> i.type === viewtype))
    
  // }, [])



  const pages = [
    { name: "Mobile Users", link: "/admin/total_users" },
    { name: userData?.firstName, link: `/admin/mobile_user_details/${id}` }
  
  ];

  return (
    <div>

      <UserContainer>
        <NavBarLeft>
          <BreadCrumb
            pages={pages}
            current="Transaction Break down"
            // previous={pages}
          />
        </NavBarLeft>
      </UserContainer>


      <DataTable
        data={bodyData}
        columns={columns}
        header
        onFilter={onFilter}
        onSearch={onSearch}
        onRefresh={onRefresh}
        // setCurrentPage={setCurrentPage}
        totalPages={paginationData?.totalPages}
        paginationData={paginationData}
        onFetch={fetchAll}
      />
    </div>
  );
};

export default UserTransactions;
