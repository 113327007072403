import { Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import Tabcontent from "../../components/UI/TabContent";
import BreadCrumb from "../../components/UI/breadCrumbs";
import StyledButton from "../../components/UI/btn";
import InvoiceModal from "../../components/invoice/InvoiceModal";
import {
  filterCompletedDropffSchedules,
  filterCompletedPickupSchedules,
  getCompletedPickupSchedules,
  getCompletedSchedules,
  searchCompletedDropffSchedules,
  searchCompletedPickupSchedules,
} from "../../store/actions";
import { truncate } from "../../utils/constants";

const FlexContainer = styled.div`
  ${tw` flex justify-between items-center`}
`;

const TotalCompleted = styled.div`
  display: grid;
  grid-template-coloumns: auto 1fr;
  gap: 20px;
`;

const GenerateInvoice = styled.div`
  margin-left: -10px !important;
  color: red;
`;

const TotalCompletedSchedule = ({ match }) => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const id = match?.params?.id;
  const dispatch = useDispatch();

  const [paginationData, setPaginationData] = useState();
  const [bodyData, setBodyData] = useState([]);
  const [dropoffData, setDropoffData] = useState([]);
  const [paginationData1, setPaginationData1] = useState();
  const [totalPages, setTotalPages] = useState(1);

  const d = new Date();
  d.setDate(d.getDate());

  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const fetchDropoffSchedules = async () => {
    const res = await dispatch(
      getCompletedSchedules({ id: id, currentMonth: payload })
    );
    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setDropoffData(data);
      setPaginationData({ ...paginationData, date: payload });
    }
  };

  const fetchPickupSchedules = async (page) => {
    const res = await dispatch(
      getCompletedPickupSchedules({ id: id, currentMonth: payload, page: 1 })
    );
    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setBodyData(data);
      setPaginationData1({ ...paginationData, date: payload });
      setTotalPages(paginationData.totalPages);
    }
  };

  const searchDropoffSchedules = async (key, page = 1) => {
    const res = await dispatch(
      searchCompletedDropffSchedules({
        id: id,
        key: key || "",
        page,
      })
    );
    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setDropoffData(data);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const searchPickupSchedules = async (key, page = 1) => {
    const res = await dispatch(
      searchCompletedPickupSchedules({
        id: id,
        key: key || "",
        page,
      })
    );

    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setBodyData(data);
      setPaginationData1({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const filterDropoffSchedules = async (date, page = 1) => {
    const res = await dispatch(
      filterCompletedDropffSchedules({
        id: id,
        currentMonth: date,
        page,
      })
    );

    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setDropoffData(data);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const filterPickupSchedules = async (date, page = 1) => {
    const res = await dispatch(
      filterCompletedPickupSchedules({
        id: id,
        currentMonth: date,
        page,
      })
    );

    if (!res.error) {
      const { data, ...paginationData } = res.payload;
      setBodyData(data);
      setPaginationData1({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onRefresh = () => {
    fetchDropoffSchedules();
    fetchPickupSchedules();
  };

  useEffect(() => {
    onRefresh();
  }, []);

  const data = [
    {
      title: "Pickup",
      link: "Pickup",
      data: bodyData?.map((data) => ({
        key: data._id,
        date: moment(data.dropOffDate).format("YYYY-MM-DD"),
        ref_id: data.ref_id,
        coin: data.coin,
        recycler: data.recycler,
        address: data.address,
        fullname: data.fullname,
        state: data.state,
        waste: data.categories.map((item) => item.name),
        quantity: data.categories.map((item) => item.quantity),
        weight: data.weight,
      })),

      paginationData: paginationData1,
      filterHandler: filterPickupSchedules,
      searchHandler: searchPickupSchedules,
      fetch: fetchPickupSchedules,
      totalPages: paginationData1?.totalPages,

      columns: [
        {
          title: "Completion Date",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
        },

        {
          title: "Transaction ID",
          dataIndex: "ref_id",
          key: "ref_id",
        },

        {
          title: "Customer Name",
          dataIndex: "fullname",
          key: "fullname",
        },

        {
          title: "Location",
          dataIndex: "address",
          key: "address",
          render: (text) => <p>{truncate(text, 30)}</p>,
        },

        // {
        //   title: <span>Recycler </span>,
        //   dataIndex: "recycler",
        //   key: "recycler",
        // },

        {
          title: "Waste Categories",
          dataIndex: "waste",
          key: "waste",
          render: (waste) => (
            <span>
              {(waste.slice(0, 5) || []).map((waste) => {
                return <Tag key={waste}>{waste?.name || waste}</Tag>;
              })}
            </span>
          ),
        },

        // {
        //   // title: "Amount (&#8358;)",
        //   title: <span> Amount (&#8358;) </span>,
        //   dataIndex: "coin",
        //   key: "coin",
        // },

        // {
        //   title: <span>Weight (Kg)</span>,
        //   dataIndex: "weight",
        //   key: "weight",
        // },
      ],
    },

    {
      title: "Drop off",
      link: "Drop off",
      data: dropoffData?.map((data) => ({
        key: data._id,
        date: moment(data.dropOffDate).format("YYYY MM DD"),
        recycler: data.recycler,
        ref_id: data.ref_id,
        coin: data.coin,
        address: data.address,
        fullname: data.fullname,
        state: data.state,
        waste: data.categories.map((item) => item.name),
        quantity: data.categories.map((item) => item.quantity),
        weight: data.weight,
      })),

      paginationData: paginationData,
      searchHandler: searchDropoffSchedules,
      filterHandler: filterDropoffSchedules,
      fetch: fetchDropoffSchedules,
      totalPages: paginationData?.totalPages,
      columns: [
        {
          title: "Completion Date",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
        },

        {
          title: "Transaction ID",
          dataIndex: "ref_id",
          key: "ref_id",
        },

        {
          title: "Customer Name",
          dataIndex: "fullname",
          key: "fullname",
        },

        {
          title: "Location",
          dataIndex: "address",
          key: "address",
          render: (text) => <p>{truncate(text, 30)}</p>,
        },

        {
          title: "Waste Categories",
          dataIndex: "waste",
          key: "waste",
          render: (waste) => (
            <span>
              {(waste.slice(0, 3) || []).map((waste) => {
                return <Tag key={waste}>{waste?.name || waste}</Tag>;
              })}
            </span>
          ),
        },

        // {
        //   title: <span> Amount (&#8358;)</span>,
        //   dataIndex: "coin",
        //   key: "coin",
        // },

        // {
        //   title: <span>Weight (Kg)</span>,
        //   dataIndex: "weight",
        //   key: "weight",
        // },
      ],
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const pages = [
    { name: "Profile Details", link: "/admin/profile_details/${id}" },
  ];

  return (
    <>
      {showModal && (
        <InvoiceModal
          showModal={showModal}
          setShowModal={setShowModal}
          id={`sch-${id}`}
        />
      )}
      <TotalCompleted>
        <FlexContainer>
          <BreadCrumb pages={pages} current="completed-schedules" />

          {
            user_claims?.create && user_claims?.edit && user_claims?.delete &&
          <GenerateInvoice>
            <StyledButton
              buttonStyle="btn----outline"
              buttonSize="btn--medium"
              className="flex justify-between items-center"
              onClick={() => setShowModal(true)}
            >
              Generate Invoice
            </StyledButton>
          </GenerateInvoice>           
          }


        </FlexContainer>
        <Tabcontent data={data} onRefresh={onRefresh} totalPages={totalPages} />
      </TotalCompleted>
    </>
  );
};

export default TotalCompletedSchedule;
