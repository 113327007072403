import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { createSubCategory, getCategory } from "../../store/actions/wasteCategoryAction";
import StyledButton from "../UI/btn";
import modal from "../UI/modal";
import FormInput from "../auth/FormInput";
import { FlexContainer, TitleText } from "../styledElements";

const Modal = styled(modal)`
  ${tw``};
`;

const AddSubCategoryModal = ({ showModal, setShowModal, name, id, sub }) => {
  const [subcategories, setSubcategories] = useState([]);
  const [showPostModal, setPostModal] = useState(false);
  const [catName, setCatName] = useState('')

  useEffect(() => {
    setSubcategories(sub);
  }, [sub]);

  const {
    app: { error },
  } = useSelector((state) => state);
  
  const dispatch = useDispatch();

  const updateCategoryHandler = async () => {
    setShowModal(false);
    // const categoryData = {
    //   name: name,
    //   subCategory: subcategories?.map((item)=>item.name),
    // };
    const categoryData = {
      name: catName,
      catId: id
    };

    // const payload = {
    //   id,
    //   categoryData,
    // };

    const res = await dispatch(createSubCategory(categoryData));
    if (!res.error) {
      dispatch(getCategory());
      setPostModal(true);
      // Add your logic for success message here
    }
  };

  
  const handleChangeSubcategory = (name) =>{
      setCatName(name)
  }

  return (
    <>
      <Modal
        color={error ? "red" : "#005700"}
        type="postAction"
        show={showPostModal} // Handle the post modal logic as needed
        close={() => setPostModal(false)} // Handle the post modal close logic as needed
      >
        {!error ? "Waste Category updated successfully" : error}
      </Modal>
      <Modal show={showModal} close={() => setShowModal(false)} width="30rem">
        <FlexContainer className="justify-between mb-4">
          <TitleText>Add waste category to {name}</TitleText>
          <StyledButton
            buttonSize="btn--medium"
            onClick={() => setShowModal(false)}
          >
            close
          </StyledButton>
        </FlexContainer>
        <div className="flex flex-col">
          {/* <div className="border-b w-full">
            <h3>Main Waste Category: {name}</h3>
          </div> */}
          
          {/* <p>Name:</p> */}
              <FormInput
                placeholder="Type Subcategory Name"
                label={'Name:'}
                height="3.5rem"
                changeHandler={(e) => handleChangeSubcategory(e.target.value)}
                value={catName}
                // key={`name-${index}`} 
              />           
          
          {/* {subcategories.map((subcategory, index) => (
            <div className='flex justify-between items-center gap-8' key={index}>
             

            </div>
          ))} */}


          <StyledButton
            buttonSize="btn--medium"
            buttonStyle="btn--primary"
            onClick={updateCategoryHandler}
            disabled={catName === '' ? true : false}
          >
            Update
          </StyledButton>
        </div>
      </Modal>
    </>
  );
};

export default AddSubCategoryModal;
