import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../components/UI/btn";
import ResourcesCard from "../../components/UI/Resources/ResourcesCard";
import { getResources } from "../../store/actions";
import UploadResourceModal from "../../components/UI/Resources/UploadResourceModal";
// import PaginationBars from "../../components/UI/Paginationbars";
import { PaginationBars } from "../../components/UI/Resources/Pagination";
import DataTable from "../../components/UI/Table";
import moment from "moment";
import AreaFilter from "../../components/UI/AreaFilter";
import { IoChevronDownSharp } from "react-icons/io5";
import Filter from "../../components/UI/Filter";
import DateRangeFilter from "../../components/UI/DateRangeFilter";
import DropDownFilter from "../../components/UI/DropDownFilter";
import { Popover, Tag } from "antd";
import { truncate } from "../../utils/constants";
import BarChart from "../../components/UI/BarChart";
import ExportFilter from "../../components/UI/ExportFilter";
import { fetchResourceArea, fetchResourceByArea, fetchResourceByStatus, getAllWasteDetails } from "../../store/actions/resourceActions";
import { formatSelectOptions } from "../../utils";
import NewBarChart from "../../components/UI/NewBarChart";
import { colors } from "./WasteCollected";

const ResourceSummaryContainer = styled.div`
    ${tw`bg-white rounded-sm grid grid-cols-2 md:grid-cols-4`}
  // display: grid;
  // gap: 20px;
`;
const ResourceHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;
const ResourceList = styled.div`
  ${tw`bg-white`}
`;
const BarChartHeader = styled.div`
  ${tw`flex items-center justify-between py-3`}
  border-bottom: 1px solid rgba(194, 194, 194, 0.4);
`;
const BarChartTitle = styled.h3`
  ${tw`text-xl font-extrabold`}
  letter-spacing:2;
`;
const BarChartContainer = styled.div`
  ${tw`bg-white `}
  padding: 3rem 1rem 0;
`;
const StatsBody = styled.div`
  ${tw`bg-white text-center text-secondary text-lg`}
  padding: 4rem 1rem;
`;

const PerLocation = () => {
  const dispatch = useDispatch()

  const [bodyData, setBodyData] = useState();
  const [activeBtn, setActiveBtn] = useState('location');
  const [tablePagination, setTablePagination] = useState();

  const [org, setOrg] = useState()
  const [status, setStatus] = useState('completed')
  const [organisations, setOrganisatons] = useState([])
  const [catList, setCatList] = useState([])
  const [subcatList, setSubCatList] = useState([])
  const [statsBodyData, setStats] = useState([]);
  const [chartData, setChartData] = useState({
    title: '',
    data: []
  });
  // const current = moment(new Date()).format("MMMM");
  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2024-01-01",
    end: d,
  };
  const year = d.getFullYear()
  const [currentYear, setCurrentYear] = useState(year);

  const onActivityFilter =() =>{

  }


  const fetchWasteDetails = async ( page = 1) => {

		const res = await dispatch(
			getAllWasteDetails({
        ...payload,
        page
			})
		);
    if (!res.error) {
			const { results, ...paginationData  } = res.payload.data;
      setBodyData(results)      
			setTablePagination({ ...paginationData, date: payload });
    }
    
	};
  const filterWasteDetails = async ( date, page = 1) => {

		const res = await dispatch(
			getAllWasteDetails({
        ...date,
        page
			})
		);
    if (!res.error) {
			const { results, ...paginationData  } = res.payload.data;
      setBodyData(results)      
			setTablePagination({ ...paginationData, date: payload });

    }
    
	};

  const filterByYear = async (date) => {
    setCurrentYear(date)
    setChartData({
      title: '',
      data: []
    })
		const res = await dispatch(
			fetchResourceByArea({
				area:org,
        year:date,
        status
			})
		);
		if (!res.error) {
      const {payload} = res
      setStats(payload)
      const q = Object.values(payload)
      setChartData({
        title: org,
        data:q,
        barColor: colors[0]
      })


		}
	};
  const filterByStatus = async (stat, page = 1) => {

    setStatus(stat)
    setChartData({
      title: '',
      data: []
    })
		const res = await dispatch(
			fetchResourceByStatus({
				area:org,
        year,
        status:stat
			})
		);
		if (!res.error) {
      console.log(res)
      const {payload} = res
      setStats(payload)
      const q = Object.values(payload)
      setChartData({
        title: org,
        data:q,
        barColor: colors[0]
      })


		}
	};

  const filterByArea = async (area, page = 1) => {

    setOrg(area)
    setChartData({
      title: '',
      data: []
    })
		const res = await dispatch(
			fetchResourceByArea({
				area,
        year,
        status
			})
		);
		if (!res.error) {
      console.log(res)
      const {payload} = res
      setStats(payload)
      const q = Object.values(payload)
      setChartData({
        title: area,
        data:q,
        barColor: colors[0]
      })


		}
	};

    // const fetchOrgs = async () => {
    //   const res = await dispatch(getAllOrganisations());
    //   if (!res.error) {
    //     console.log(res)
    //     const orgOptions = formatSelectOptions(
    //       res.payload.data.organisations,
    //       "companyName",
    //       "_id"
    //     );
    //     console.log(orgOptions)
    //     setOrganisatons(orgOptions);
    //   }
    // };

    const fetchAreas = async () => {
      const res = await dispatch(fetchResourceArea());
      if (!res.error) {
        // const orgOptions = formatSelectOptions(
        //   res.payload,
        //   "slug",
        //   "slug"
        // );

        const orgOptions = res.payload?.map((i)=>{
          return({
            text: i.lga,
            value: i.slug
          })
        })

        setCatList(orgOptions);
      }
    };

  useEffect(() => {
    // fetchOrgs()
    fetchWasteDetails()
    fetchAreas()
  }, []);


  const handleSetChartData = (q, color) =>{
    const data = statsBodyData[q]
    const d = Object.values(data)
    setChartData({
      title: q,
      data:d,
      barColor: color
    })
    
  }
  const columns = [
    {
      title: "LGA",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Total Recycled",
      dataIndex: "quantity",
      key: "quantity",
    },
    // {
    //   title: "Category",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (wastes) => (
    //     <span>
    //       {(wastes.slice(0, 3) || []).map((waste) => {
    //         return <Tag key={waste}>{waste?.name || waste}</Tag>;
    //       })}
    //     </span>
    //   ),
    // },
  
    {
      title: "Number of Users",
      dataIndex: "users",
      key: "users",

    },
  
    // {
    //   title: "Recycler",
    //   dataIndex: "recycler",
    //   key: "recycler",
    // },
    // {
    //   title: "Organization",
    //   dataIndex: "organisation",
    //   key: "organisation",
    // },
  ];

  return (
    <div className="space-y-6">

      <BarChartContainer>
        <BarChartHeader>
          <BarChartTitle>User’s Impact Per Location</BarChartTitle>
          {/* <RangePicker
            dateRender={(current) => {
              const style = {};
              if (current.date() === 1) {
                style.border = "1px solid #1890ff";
                style.borderRadius = "50%";
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          /> */}

          <div>
            <div className="flex gap-2">
              
              {
                org && (
                  <>
                  <Filter onFilter={filterByYear} isYear={true}  title={'Date Range'}/>
                  <Filter comp={<DropDownFilter list ={catList} onFilter={filterByArea} />}  icon={<IoChevronDownSharp/>} compTitle={"Area"} title={'Area'} />                       
                  <Filter comp={<DropDownFilter list ={statData} onFilter={filterByStatus} />}  icon={<IoChevronDownSharp/>} compTitle={"Status"} title={'Status'} />                       
                  </>
             
                )

              }
              
              {/* <Filter comp={<ExportFilter onFilter={onActivityFilter} />} icon={<IoChevronDownSharp/>} compTitle={"Export As"} title={'Export'} /> */}

            </div> 

            <div className="flex justify-end pr-6">
               <span className="text-[12px]">{org} - {currentYear} - {status}</span>  
            </div>    

                
          </div>


        </BarChartHeader>
        <hr className="py-4" />
        { !org ? 
        (
          <StatsBody>
          Select an Area to Get Started
          <div className="w-full flex justify-center">
          <Filter comp={<DropDownFilter list ={catList} onFilter={filterByArea} />}  icon={<IoChevronDownSharp/>} compTitle={"Area"} title={'Area'} />
          </div>
        </StatsBody>
        )
        :
        (Math.max(...chartData?.data) < 1) ? (
          <StatsBody>
            No Statistics Available for <span className="font-bold">{org}</span> in the year of {currentYear}
          </StatsBody>
        ) : (
          <NewBarChart data1={chartData}/>  
        )}
      </BarChartContainer>

      <DataTable
        data={bodyData || []}
        date="welcome"
        // setCurrentPage={setCurrentPage}
        // onSearch={onSearch}
        onFilter={filterWasteDetails}
        onRefresh={fetchWasteDetails}
        columns={columns}
        // refreshUrl="drop-off"
        header
        paginationData={tablePagination}
        totalPages={tablePagination?.totalPages}
      />

    </div>
  )


  
};

export default PerLocation;


const statData = [
  {
    text: 'Completed',
    value: 'completed'
  },
  {
    text: 'Pending',
    value: 'pending'
  },
  {
    text: 'Missed',
    value: 'missed'
  },
]