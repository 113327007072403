import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import Button from "../../../../components/UI/button";
import Modal from "../../../../components/UI/modal";
import MultiSelect from "../../../../components/UI/multiSelect";
import Select from "../../../../components/UI/select";
import FormInput from "../../../../components/auth/FormInput";
import { FormContainer } from "../../../../components/styledElements";
import useForm from "../../../../hooks/useForm";
import {
  createSpaceOwner,
  getBank,
  validateAccount,
} from "../../../../store/actions";
import { formatSelectOptions } from "../../../../utils";
import AddressForm from "./AddressForm";

const SpaceOwnerContainer = styled.div`
  ${tw`grid items-center gap-4 w-full`}
`;
const InfoContainer = styled.div`
  ${tw`bg-white pt-4 pb-1 px-10`}
`;
const FormTitle = styled.h1`
  ${tw`text-2xl font-extrabold tracking-wide `}
`;

const SpaceOwnerItemImg = styled.img`
  ${tw`cursor-pointer`}
`;

const bioFormEntries = {
  name: {
    label: "Name",
    value: "",
    placeholder: "Organisation Name",
    rules: [(v) => !!v || "Company Name is required"],
  },
  phone: {
    label: "Phone Number",
    value: "",
    placeholder: "Phone Number",
    rules: [
      (v) => !!v || "Phone Number is required",
      (v) => /^\d{11}$/.test(v) || "contact line is invalid",
    ],
  },
  email: {
    label: "Email Address",
    value: "",
    placeholder: "Email Address",
    rules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "▲ E-mail must be valid",
    ],
  },
  address: {
    label: "Address",
    value: "",
    placeholder: "Address",
    rules: [(v) => !!v || "Address is required"],
  },
};

const bankFormEntries = {
  bank: {
    label: "Bank",
    optionIdxs: null,
    type: "select",
    options: [],
    rules: [(v) => !!v || "select a bank"],
  },
  accountNumber: {
    label: "Account Number",
    value: "",
    placeholder: "Account Number",
    // laxRules: true,
    rules: [
      (v) => {
        if (!v) return true;
        else
          return (
            (v.length > 4 && v.length < 18) || "Enter a valid account number"
          );
      },
    ],
  },
  accountName: {
    label: "Account Name",
    value: "",
    placeholder: "",
    rules: [(v) => !!v || "Account data not found"],
    disabled: true,
  },
};

const pages = [{ name: "Space Owner", link: "/admin/pakam-associates" }];

const SpaceOwnerFormModal = () => {
  const {
    app: { error },
  } = useSelector((state) => state);
  const { allBanks } = useSelector((state) => state.pickers);
  const [address, setAddress] = useState("");
  const [geoLocation, setGeoLocation] = useState({
    lat: "",
    lng: "",
  });
  const [bankData, setBankData] = useState();
  const dispatch = useDispatch();
  let bankName;
  const [names, setNames] = useState([{ address: "" }]);
  const [message, setMessage] = useState("");
  const [showPostAction, setPostAction] = useState(false);
  const [toActive, setToActive] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!allBanks) return;
    const banks = formatSelectOptions(allBanks, "name", "value");
    bankFormEntries.bank.options = banks;
  }, [allBanks]);
  const { setValue, errorMsgs, formValues, isValid, clearForm } =
    useForm(bioFormEntries);

  const {
    setValue: setBankValue,
    errorMsgs: setErrMsg,
    formValues: formBankValue,
    isValid: bankValidity,
    clearForm: clearBankInfo,
  } = useForm(bankFormEntries);

  useEffect(() => {
    const resolveAccount = async (accountNumber) => {
      const bank_code = formBankValue.bank;
      bankName = allBanks.find((bank) => bank.value === formBankValue.bank);

      const data = {
        accountNumber,
        bank_code,
      };
      const res = await dispatch(validateAccount(data));

      if (res.payload.account_name) {
        setBankValue("accountName", res.payload.account_name);

        const accountInfo = {
          accountName: res.payload.account_name,
          accountNo: res.payload.account_number,
          bankName: bankName.name,
          sortCode: formBankValue.bank,
        };

        setBankData(accountInfo);
      } else setBankValue("accountName", "");
    };
    const accountNum = formBankValue.accountNumber;

    if (accountNum.length === 10) {
      resolveAccount(accountNum);
    } else if (accountNum === "") setBankValue("accountName", "none");
  }, [formBankValue.accountNumber]);

  useEffect(() => {
    if (!allBanks) dispatch(getBank());
  }, []);

  const handleNameChange = (index, newName) => {
    const updatedNames = [...names];
    updatedNames[index].address = newName;
    setNames(updatedNames);
  };

  const handleAddName = () => {
    setNames([...names, { address: "" }]);
  };

  const handleRemoveName = (index) => {
    const updatedNames = [...names];
    updatedNames.splice(index, 1);
    setNames(updatedNames);
  };

  const _clearForm = () => {
    const defaultEntries = {};
    Object.entries(bioFormEntries).forEach(([key, val]) => {
      if (val.optionIdx) val.optionIdx = null;
      defaultEntries[key] = { ...val, value: "" };
    });

    // Object.entries(bankFormEntries).forEach(([key, val]) => {
    //   if (val.optionIdx) val.optionIdx = null;
    //   defaultEntries[key] = { ...val, value: "" };
    // });

    bioFormEntries = defaultEntries;
    // bankFormEntries = defaultEntries;
    clearForm();
  };

  const handleSubmit = async () => {
    const spaceOwnerData = {
      fullname: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      address: formValues.address,
      accountName: bankData?.accountName && bankData?.accountName,
      accountNumber: bankData?.accountNo && bankData?.accountNo,
      bankName: bankData?.bank && bankData?.bank,
      setToActive: true,
      locations: [
        {
          address: address,
          lat: geoLocation.lat.toString(),
          long: geoLocation.lng.toString(),
        },
      ],
    };
    const res = await dispatch(createSpaceOwner(spaceOwnerData));
    if (!res.error) {
      setMessage(res?.payload?.message);
      setPostAction(true);
      setTimeout(() => {
        history.push("/admin/pakam-associates");
      }, 2000);
    }
    if (res.error) {
      setPostAction(true);
    }
  };
  const textClass = " text-[#464F54] text-[24px] font-bold opacity-50 pt-4";

  return (
    <>
      <BreadCrumb pages={pages} current="Add Space Owners" />

      <Modal
        color={error ? "red" : "#005700"}
        show={showPostAction}
        close={() => {
          setPostAction(false);
        }}
        type="postAction"
      >
        <p>{!error ? message : error}</p>
      </Modal>

      <SpaceOwnerContainer>
        <InfoContainer>
          <FormTitle className={textClass}>Bio</FormTitle>
          <FormContainer>
            {Object.entries(bioFormEntries).map(([key, input]) => (
              <div className="w-full md:w-[45%] md:gap-x-10">
                <FormInput
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.label}
                  key={key}
                  changeHandler={(e) => setValue(key, e.target.value)}
                  errorMsg={errorMsgs[key]}
                  value={formValues[key]}
                  disabled={input.disabled}
                />
              </div>
            ))}
          </FormContainer>
        </InfoContainer>

        <InfoContainer>
          <FormTitle className={textClass}>Bank & Account Details</FormTitle>
          <FormContainer>
            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
              {Object.entries(bankFormEntries).map(([key, input]) => {
                switch (input.type) {
                  case "select":
                    return (
                      <Select
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.label}
                        changeHandler={(v) => setBankValue(key, v)}
                        optionIdx={input.optionIdx !== null && input.optionIdx}
                        disabled={input.disabled}
                        checkbox={input.checkbox}
                      />
                    );
                  case "multiselect":
                    return (
                      <MultiSelect
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.title || input.label}
                        changeHandler={(v) => setBankValue(key, v)}
                        optionIdxs={input.optionIdxs || null}
                        disabled={input.disabled}
                      />
                    );
                  default:
                    return (
                      <FormInput
                        placeholder={input.placeholder}
                        type={input.type}
                        label={input.label}
                        key={key}
                        changeHandler={(e) => setBankValue(key, e.target.value)}
                        errorMsg={setErrMsg[key]}
                        value={formBankValue[key]}
                        disabled={input.disabled}
                      />
                    );
                }
              })}
            </div>
          </FormContainer>
        </InfoContainer>

        <InfoContainer className="!pb-3">
          <div className="flex justify-between items-center">
            <FormTitle className={textClass}>Drop-Off Locations</FormTitle>
            {/* <StyledButton
              buttonStyle="btn--primary--outline"
              buttonSize="btn--medium"
              className="flex justify-between items-center !text-4xl"
              //   onClick={() => {
              //     setModalMode("create");
              //     setShowModal(true);
              //   }}
              onClick={handleAddName}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Add Location
            </StyledButton> */}
          </div>
          {/* <div className="w-full mt-10">
            {names.length > 0 &&
              names?.map((nameObj, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="w-[65%]">
                    <FormInput
                      type="text"
                      placeholder="Enter a name"
                      value={nameObj.address}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                      height="3.5rem"
                      label="Drop-off location"
                      className="w-full"
                    />
                  </div>
                  <span className="-mt-6 text-red-500 block">
                    {index > 0 && (
                      <SpaceOwnerItemImg
                        src="/assets/images/redbin.svg"
                        alt="delete-icon"
                        onClick={() => handleRemoveName(index)}
                      />
                    )}
                  </span>
                </div>
              ))}
          </div> */}
          {/* <Checkbox onChange={onChange}>Set land owners to active ?</Checkbox> */}

          <div className="w-full md:w-[45%] md:gap-x-10">
            <AddressForm
              setAddress={setAddress}
              setGeoLocation={setGeoLocation}
              label="Dropoff Location"
              scroll={false}
            />
          </div>
        </InfoContainer>

        <div className="mt-5 mb-3">
          <Button
            width=""
            submit
            onClick={() => handleSubmit()}
            disabled={!isValid}
          >
            Add Space Owner
          </Button>
        </div>
      </SpaceOwnerContainer>
    </>
  );
};

export default SpaceOwnerFormModal;
// handleSubmit()
