import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { deleteDropOffLocation } from "../../store/actions";
import NewSpaceModal from "../../views/PakamAssociates/space/spaceOwner/NewSpaceModal";
import StyledButton from "../UI/btn";
import DeleteModal from "../common/DeleteModal";

const SpaceOwnerItem = styled.div`
  ${tw`flex items-center justify-between border-b-2 border-gray-200 px-4 py-4`}
`;
const SpaceOwnerItemTitle = styled.div`
  ${tw`flex items-center gap-2`}
`;
const SpaceOwnerItemTitleBullet = styled.div`
  ${tw`bg-secondary h-5 w-5 rounded-sm`}
`;
const SpaceOwnerItemTitleText = styled.div`
  ${tw`capitalize text-base`}
`;
const SpaceOwnerItemImg = styled.img`
  ${tw`cursor-pointer`}
`;

const WasteCategoryButtons = styled.div`
  ${tw`flex items-center gap-2`}
`;

const SpaceOwnerCard = ({
  name = "",
  id = "",
  matchId,
  showDelete = true,
  phone,
  value,
}) => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, ,] = useState("update");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const deleteHandler = async () => {
    const data = {
      locationId: id,
      partnerId: location.pathname.split("/")[3],
    };
    const res = await dispatch(deleteDropOffLocation(data));
    if (!res.error) {
      setTimeout(() => {
        history.push(`/admin/space_details/${id}`);
      }, 2000);
    }
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={deleteHandler}
        type="dropoff"
        title="DropOff"
        redirectAfterDelete
        locationId={matchId}
      />
      <NewSpaceModal
        mode={modalMode}
        showModal={showModal}
        setShowModal={setShowModal}
        currentUpdateValue={name}
        id={id}
        matchId={matchId}
        phone={phone}
        currentAddress={value}
      />
      <SpaceOwnerItem>
        <SpaceOwnerItemTitle>
          <SpaceOwnerItemTitleBullet />
          <SpaceOwnerItemTitleText>{name}</SpaceOwnerItemTitleText>
        </SpaceOwnerItemTitle>
        <WasteCategoryButtons>
          {
            user_claims?.edit &&
            <StyledButton
              buttonStyle="btn--primary--outline"
              buttonSize="btn--small"
              onClick={() => setShowModal(true)}
            >
              Edit
            </StyledButton>            
          }

          {showDelete && (
            <SpaceOwnerItemImg
              src="/assets/images/redbin.svg"
              alt="delete-icon"
              onClick={() => {
                setDeleteModal(true);
              }}
            />
          )}
        </WasteCategoryButtons>
      </SpaceOwnerItem>
    </>
  );
};

export default SpaceOwnerCard;
