import React, { useState } from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import { FaAngleRight } from "react-icons/fa";
import { companySearchDropoff } from '../../store/actions/dropOffAction';

const ModalBackground = styled.div`
  width: 255px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  border-radius: 10px;
  padding-bottom: 10px;
  z-index: 99999;
  left: 100%;
  top: 10px;

  modalContainer {
    position: relative;
  }
`;
const ModalHeaderTitle = styled.h3`
  ${tw`text-base mb-0`}
`;
const ModalHeaderImg = styled.img``;
const OverlayBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  opacity: 0.7;
  z-index: 10;
`;

const InvoiceStatusFilter = ({onFilter}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);



    const handleValueChange = (status, type) =>{
      onFilter(status, type)
    }


  return (
    <div>

        <div className='relative w-full h-[40px]' >
            <div className='w-full cursor-pointer user-text h-[40px] text-left flex justify-between items-center  px-6' onMouseEnter={()=>{
                handleValueChange('paid')
                
                }}>
                  <span>
                    Paid
                  </span>
            </div>
            
        </div>
        <div className='relative w-full h-[40px]' >
            <div className='w-full cursor-pointer user-text h-[40px] text-left flex justify-between items-center  px-6' onMouseEnter={()=>{
                handleValueChange('partial payment')
                
                }}>
                  <span>
                    Partial Payment
                  </span>
            </div>
            
        </div>
        <div className='relative w-full h-[40px]' >
            <div className='w-full cursor-pointer user-text h-[40px] text-left flex justify-between items-center  px-6' onMouseEnter={()=>{
                handleValueChange('pending')
                
                }}>
                  <span>
                    Pending
                  </span>
            </div>
            
        </div>
      
    </div>
  )
}

export default InvoiceStatusFilter
