import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../../../components/UI/btn";
import modal from "../../../../components/UI/modal";
import FormInput from "../../../../components/auth/FormInput";
import {
  FlexContainer,
  TitleText,
} from "../../../../components/styledElements";
import useForm from "../../../../hooks/useForm";
import { createLand, editDropOffLocation } from "../../../../store/actions";

const Modal = styled(modal)`
  ${tw``}
`;

const TextInput = styled.input`
  ${tw`h-14 w-full px-3 border outline-none rounded-lg text-lg text-body focus:border-secondary`}
  border-color: #bdbdbd;
  ::placeholder {
    font-size: 16px;
  }
  &:disabled {
    background-color: rgba(229, 231, 235);
  }
  height: ${(props) => (props.height ? props.height : "56px")};
`;

const Label = styled.label`
  ${tw`text-body text-sm font-medium`}
`;

const data = {
  phone: {
    label: "Contact Line",
    value: "",
    placeholder: "contact",
    rules: [
      (v) =>
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v) ||
        "contact line is invalid",
    ],
  },
  location: {
    label: "Drop-off Location",
    value: "Seun Seun",
    placeholder: "address",
    type: "autocomplete",
  },
};

const NewSpaceModal = ({
  showModal = false,
  setShowModal = {},
  mode,
  id,
  phone,
  currentAddress,
}) => {
  const texts = {
    title: { create: "Add Location", update: "Update DropOff" }[mode],
    buttonText: { create: "Save Location ", update: "Update" }[mode],
  };
  const [query, setQuery] = useState();
  useEffect(() => {
    if (mode === "update") {
      data.phone.value = phone;
      setQuery(currentAddress);
    } else {
      data.phone.value = "";
      data.location.value = "";
    }
  }, []);

  const [showPostModal, setPostModal] = useState(false);
  const autoCompleteRef = useRef(null);

  const { setValue, formValues, errorMsgs } = useForm(data);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.app);
  const { pathname } = useLocation();
  const locationId = pathname.split("/")[3];
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState();

  const [geoLocation, setGeoLocation] = useState({
    lat: "",
    lng: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const updateDropOffHandler = async (geocoder) => {
    setShowModal(false);
    const result = await geocoder.geocode({ address: query });
    const results = result.results;

    const lat = results[0].geometry.location.lat();
    const long = results[0].geometry.location.lng();

    const editData = {
      id: id,
      updateData: {
        address: query,
        contactPhone: formValues.phone,
        long: long.toString(),
        lat: lat.toString(),
      },
    };
    const res = await dispatch(editDropOffLocation(editData));
    if (!res.error) {
      setPostModal(true);
      setMessage(res?.payload?.message);
      setTimeout(() => {
        history.push(`/admin/space_details/${locationId}`);
        // window.location.reload();
      }, 2000);
    }
  };

  const addDropOffLocations = async (geocoder) => {
    const result = await geocoder.geocode({ address: query });
    const results = result.results;

    const lat = results[0].geometry.location.lat();
    const long = results[0].geometry.location.lng();

    const data = {
      id: locationId,
      landDetails: {
        address: query,
        contactPhone: formValues.phone,
        setToActive: true,
        long: long.toString(),
        lat: lat.toString(),
      },
    };
    const res = await dispatch(createLand(data));
    if (res.error) {
      setPostModal(true);
      setErrorMessage(res?.error?.message);
    }
    if (!res.error) {
      setPostModal(true);
      setMessage(res?.payload?.data);
      setTimeout(() => {
        history.push(`/admin/space_details/${locationId}`);
      }, 2000);
    }
  };

  const handleSubmit = async () => {
    const geocoder = new window.google.maps.Geocoder();

    switch (mode) {
      case "create":
        return addDropOffLocations(geocoder);

      case "update":
        return updateDropOffHandler(geocoder);

      default:
        return;
    }
  };

  let autoComplete;

  const handleScriptLoad = async (searchKey, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "ng" },
      }
    );

    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(searchKey)
    );
  };

  const handlePlaceSelect = (updateQuery) => {
    const addressObject = autoComplete?.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
  };

  if (!window.google) {
    setTimeout(() => {
      handleScriptLoad(setQuery, autoCompleteRef);
    }, 2000);
  }
  handleScriptLoad(setQuery, autoCompleteRef);

  return (
    <>
      <Modal
        color={errorMessage ? "red" : "#005700"}
        type="postAction"
        show={showPostModal}
        close={() => setPostModal(false)}
      >
        {!errorMessage ? message : errorMessage}
      </Modal>

      <Modal show={showModal} close={() => setShowModal(false)} width="30rem">
        <FlexContainer className="justify-between">
          <TitleText className="">{texts.title}</TitleText>
          <StyledButton
            buttonSize="btn--medium"
            onClick={() => setShowModal(false)}
          >
            close
          </StyledButton>
        </FlexContainer>
        <div className="flex flex-col">
          {/* {Object.entries(data).map(([key, input]) => {
            return (
              <div className="mt-4">
                <Label htmlFor={input.label}>{input.label}</Label>
                <TextInput
                  ref={autoCompleteRef}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setValue(key, event.target.value);
                  }}
                  value={query}
                  placeholder={input.placeholder}
                />
                {input?.type !== "autoComplete" && (
                  <>
                    <FormInput
                      placeholder={input.placeholder}
                      type={input.type}
                      label={input.label}
                      key={input.label}
                      height="3.5rem"
                      changeHandler={(e) => setValue(key, e.target.value)}
                      errorMsg={errorMsgs[key]}
                      value={formValues[key]}
                      disabled={input.disabled}
                    />
                  </>
                )} */}

          {/* <div className="-mt-3">
                  <AddressForm
                    setAddress={setAddress}
                    address={address}
                    setGeoLocation={setGeoLocation}
                    label="Dropoff Location"
                    scroll={true}
                    mode={mode}
                  />
                </div> */}
          {/* </div> */}
          {/* ); */}
          {/* })} */}

          {Object.entries(data).map(([key, input]) => {
            switch (input.type) {
              case "autocomplete":
                return (
                  <div>
                    <Label htmlFor={input.label}>{input.label}</Label>
                    <TextInput
                      ref={autoCompleteRef}
                      onChange={(event) => {
                        setQuery(event.target.value);
                        setValue(key, event.target.value);
                      }}
                      value={query}
                      placeholder={input.placeholder}
                    />
                  </div>
                );

              default:
                return (
                  <FormInput
                    placeholder={input.placeholder}
                    type={input.type}
                    label={input.label}
                    key={input.label}
                    height="3.5rem"
                    changeHandler={(e) => setValue(key, e.target.value)}
                    errorMsg={errorMsgs[key]}
                    value={formValues[key]}
                    disabled={input.disabled}
                  />
                );
            }
          })}

          <div className="max-w-content mt-4">
            <StyledButton
              buttonSize="btn--medium"
              buttonStyle="btn--primary--outline"
              // disabled={
              //   mode !== "update"
              //     ? !formValues.phone || !address
              //     : !formValues.phone && !address
              // }
              onClick={() => {
                handleSubmit();
                setShowModal(false);
              }}
            >
              {texts.buttonText}
            </StyledButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewSpaceModal;
