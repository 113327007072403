// import React, { useState } from "react";
// import { FiPlusCircle } from "react-icons/fi";
// import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
// import tw from "twin.macro";
// import useForm from "../../hooks/useForm";
// import { createCategory, getCategory } from "../../store/actions";
// import StyledButton from "../UI/btn";
// import modal from "../UI/modal";
// import FormInput from "../auth/FormInput";
// import { FlexContainer, TitleText } from "../styledElements";

// const Modal = styled(modal)`
//   ${tw``};
//   //   input {
//   //     transform: scaleY(0.7);
//   //   }
// `;

// const initData = {
//   name: {
//     label: "Category",
//     value: "",
//     placeholder: "Type Waste Category",
//     rules: [(v) => !!v || "Waste Category is required"],
//   },

//   children: [
//     {
//       subcategory: {
//         label: "Waste Category Name",
//         value: "",
//         placeholder: "Type Waste Subcategory",
//         rules: [(v) => !!v || "Waste Category is required"],
//       },
//       wastepicker: {
//         label: "Waste Picker Price",
//         value: "",
//         placeholder: "Type Waste Picker",
//         rules: [(v) => !!v || "Waste Picker Price is required"],
//       },      
//     }
//   ]

// };

// const NewWasteCategoryModal = ({
//   data = initData,
//   showModal = false,
//   setShowModal = {},
// }) => {
//   const {
//     app: { error },
//   } = useSelector((state) => state);
//   const { setValue, formValues, errorMsgs } = useForm(initData);
//   const [showPostModal, setPostModal] = useState(false);
//   const dispatch = useDispatch();

//   const createCategoryHandler = async () => {
//     setShowModal(false);
//     const data = {
//       name: formValues.name,
//       wastepicker: formValues.wastepicker,
//     };
//     const res = await dispatch(createCategory(data));
//     if (!res.error) dispatch(getCategory());
//     setPostModal(true);
//   };

//   return (
//     <>
//       <Modal
//         color={error ? "red" : "#005700"}
//         type="postAction"
//         show={showPostModal}
//         close={() => setPostModal(false)}
//       >
//         {!error ? "Waste Category updated successfully" : error}
//       </Modal>
//       <Modal show={showModal} close={() => setShowModal(false)} width="30rem">
//         <FlexContainer className="justify-between mb-4">
//           <TitleText>Create Waste Category</TitleText>
//           <StyledButton
//             buttonSize="btn--medium"
//             onClick={() => setShowModal(false)}
//           >
//             close
//           </StyledButton>
//         </FlexContainer>
//         <div className="flex flex-col">
//           <FormInput
//               placeholder={data.name.placeholder}
//               type={data.name.type}
//               label={data.name.label}
//               key={data.name.label}
//               height="3.5rem"
//               changeHandler={(e) => setValue('name', e.target.value)}
//               errorMsg={errorMsgs['name']}
//               value={formValues['name']}
//               disabled={data.name.disabled}
//             />

//           {
//             data?.children.map((item, i)=>{
//               <div key={i}>
//                 {Object.entries(item).map(([key, input]) => (
//                   <FormInput
//                     placeholder={input.placeholder}
//                     type={input.type}
//                     label={input.label}
//                     key={input.label}
//                     height="3.5rem"
//                     changeHandler={(e) => setValue(key, e.target.value)}
//                     errorMsg={errorMsgs[key]}
//                     value={formValues[key]}
//                     disabled={input.disabled}
//                   />
//                 ))}                
//               </div>


//               // <div>
//               //   <FormInput
//               //   placeholder={input.placeholder}
//               //   type={input.type}
//               //   label={input.label}
//               //   key={input.label}
//               //   height="3.5rem"
//               //   changeHandler={(e) => setValue(key, e.target.value)}
//               //   errorMsg={errorMsgs[key]}
//               //   value={formValues[key]}
//               //   disabled={input.disabled}
//               // />                
//               // </div>

//             })
//           }
          
//           {/* {Object.entries(data).map(([key, input]) => (
//             <FormInput
//               placeholder={input.placeholder}
//               type={input.type}
//               label={input.label}
//               key={input.label}
//               height="3.5rem"
//               changeHandler={(e) => setValue(key, e.target.value)}
//               errorMsg={errorMsgs[key]}
//               value={formValues[key]}
//               disabled={input.disabled}
//             />
//           ))} */}




//           <div className="max-w-content">
//             <StyledButton
//               buttonSize="btn--medium"
//               buttonStyle="btn--primary--outline"
//               onClick={createCategoryHandler}
//               disabled={formValues.name.length === 0}
//             >
//               <FiPlusCircle size={"20"} />
//               Add
//             </StyledButton>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default NewWasteCategoryModal;



import React, { useState, useEffect } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import useForm from "../../hooks/useForm";
import { createCategory, getCategory } from "../../store/actions";
import StyledButton from "../UI/btn";
import modal from "../UI/modal";
import FormInput from "../auth/FormInput";
import { FlexContainer, TitleText } from "../styledElements";

const Modal = styled(modal)`
  ${tw``};
`;

const WasteCategoryItemImg = styled.img`
  ${tw`cursor-pointer`}
`;

const initData = {
  name: {
    label: "Category",
    value: "",
    placeholder: "Type Waste Category",
    type: "text",
    rules: [(v) => !!v || "Waste Category is required"],
  },
  subcategories: [
    {
      name: {
        label: "Subcategory Name",
        value: "",
        placeholder: "Type Subcategory Name",
        type: "text",
        rules: [(v) => !!v || "Subcategory Name is required"],
      },
      wastepicker: {
        label: "Waste Picker Price",
        value: "",
        placeholder: "Type Waste Picker Price",
        type: "number", // You can set the type as needed
        rules: [(v) => !!v || "Waste Picker Price is required"],
      },
    },
  ],
};


const NewWasteCategoryModal = ({
  data = initData,
  showModal = false,
  setShowModal = {},
}) => {
  const {
    app: { error },
  } = useSelector((state) => state);
  const { setValue, formValues, errorMsgs } = useForm(data);
  const [showPostModal, setPostModal] = useState(false);
  const dispatch = useDispatch();

  const createCategoryHandler = async () => {
    setShowModal(false);
    const categoryData = {
      name: formValues.name,
      subCategory: formValues.subcategories?.map((item)=>item.name),
    };

    const res = await dispatch(createCategory(categoryData));
    if (!res.error) dispatch(getCategory());
    setPostModal(true);
  };


  useEffect(()=>{
    if (!showModal){
      setValue('name', '')
      setValue('subcategories', []);
    }
  }, [showModal])


  const handleAddSubcategory = () => {
    setValue('subcategories', [...formValues.subcategories, { name: '', wastepicker: '' }]);
  };

  const handleRemoveSubcategory = (indexToRemove) => {
    const updatedSubcategories = formValues.subcategories.filter((_, index) => index !== indexToRemove);
    setValue('subcategories', updatedSubcategories);
  };

  const handleChangeSubcategory = (index, key, value) => {
    const updatedSubcategories = [...formValues.subcategories];
    updatedSubcategories[index][key] = value;
    setValue('subcategories', updatedSubcategories);
  };

  return (
    <>
      <Modal
        color={error ? "red" : "#005700"}
        type="postAction"
        show={showPostModal}
        close={() => setPostModal(false)}
      >
        {!error ? "Waste Category created successfully" : error}
      </Modal>
      <Modal show={showModal} close={() => setShowModal(false)} width="40rem">
        <FlexContainer className="justify-between mb-4">
          <TitleText>Create Waste Category</TitleText>
          <StyledButton
            buttonSize="btn--medium"
            onClick={() => setShowModal(false)}
          >
            close
          </StyledButton>
        </FlexContainer>
        <div className="flex flex-col">
          <FormInput
            placeholder={data.name.placeholder}
            type={data.name.type}
            label={data.name.label}
            key={data.name.label}
            height="3.5rem"
            changeHandler={(e) => setValue('name', e.target.value)}
            errorMsg={errorMsgs['name']}
            value={formValues['name']}
            disabled={data.name.disabled}
          />

          <p className="font-bold ">Subcategories</p>
          <div className="w-full mt-2 grid grid-cols-1 md:grid-cols-2">
            
            {formValues.subcategories.map((subcategory, index) => (

              <div key={index} className="flex items-center gap-4">
                <FormInput
                  placeholder={data.subcategories[0].name.placeholder}
                  type={data.subcategories[0].name.type}
                  label={''}
                  key={`name-${index}`}
                  height="3.5rem"
                  changeHandler={(e) => handleChangeSubcategory(index, 'name', e.target.value)}
                  errorMsg={errorMsgs['name']}
                  value={subcategory.name}
                />
                {/* <div className="flex items-center gap-3">
                  <FormInput
                    placeholder={data.subcategories[0].wastepicker.placeholder}
                    type={data.subcategories[0].wastepicker.type}
                    label={data.subcategories[0].wastepicker.label}
                    key={`wastepicker-${index}`}
                    height="3.5rem"
                    changeHandler={(e) => handleChangeSubcategory(index, 'wastepicker', e.target.value)}
                    errorMsg={errorMsgs['wastepicker']}
                    value={subcategory.wastepicker}
                  /> */}
                  <WasteCategoryItemImg
                    src="/assets/images/redbin.svg"
                    alt="delete-icon"
                    onClick={() => {
                      handleRemoveSubcategory(index);
                    }}
                  />                
                {/* </div> */}


              </div>
            ))}
          </div>



          <div className="max-w-content space-y-6">
            <StyledButton
              buttonSize="btn--medium"
              buttonStyle="btn--primary--outline"
              onClick={handleAddSubcategory}
            >
              <FiPlusCircle size={"20"} />
              Add Subcategory
            </StyledButton>
            <StyledButton
              buttonSize="btn--medium"
              buttonStyle="btn--primary"
              onClick={createCategoryHandler}
              disabled={formValues.name.length === 0 || formValues.subcategories.length < 1}
            >
              Done
            </StyledButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewWasteCategoryModal;
