import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from ".";
import SpaceOwnerService from "../../services/spaceOwnerService";
import { startLoad, stopLoad } from "../reducers/appSlice";

export const getSpaceOwnerStat = createAsyncThunk(
  "get/space-stats",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.getSpaceOwnerStats();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const getSpaceOwnersDataTable = createAsyncThunk(
  "get/space-data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.getSpaceOwnersTableData(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchSpaceOwnersDataTable = createAsyncThunk(
  "search/space-data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.searchSpaceOwnersTableData(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const filterSpaceOwnersDataTable = createAsyncThunk(
  "filter/space-data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.filterSpaceOwnersTableData(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const disableSpaceOwner = createAsyncThunk(
  "disable/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.DisabledSpaceOwner(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const enableSpaceOwner = createAsyncThunk(
  "disable/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.enabledSpaceOwner(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const deleteSpaceOwner = createAsyncThunk(
  "delete/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.DeleteSpaceOwner(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const dropOffSpaceLocations = createAsyncThunk(
  "get/dropoff-locations",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.DropOffSpaceLocation(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchDropOffSpaceLocations = createAsyncThunk(
  "search/dropoff-locations",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.searchDropOffSpaceLocation(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const filterDropOffSpaceLocations = createAsyncThunk(
  "filter/dropoff-locations",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.filterDropOffSpaceLocation(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getOwnerDetails = createAsyncThunk(
  "get/space-owner-details",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.getOwnerDetail(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAcceptedMaterials = createAsyncThunk(
  "get/acceptedMaterials",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.getAcceptedMaterial(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchAcceptedMaterials = createAsyncThunk(
  "search/acceptedMaterials",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.searchAcceptedMaterial(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const filterAcceptedMaterials = createAsyncThunk(
  "filter/acceptedMaterials",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.filterAcceptedMaterial(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getCredits = createAsyncThunk(
  "get/credit",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.getCredit(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchCredits = createAsyncThunk(
  "search/credit",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.searchCredit(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const filterCredits = createAsyncThunk(
  "filter/credit",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.filterCredit(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getWithdrawals = createAsyncThunk(
  "get/withdrawal",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.getWithdrawal(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchWithdrawals = createAsyncThunk(
  "search/withdrawal",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.searchWithdrawal(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const filterWithdrawals = createAsyncThunk(
  "filter/withdrawal",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await SpaceOwnerService.filterWithdrawal(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const createSpaceOwner = createAsyncThunk(
  "create/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.createSpaceOwners(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const editSpaceOwner = createAsyncThunk(
  "edit/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.editSpaceOwners(
        data.id,
        data.updateData
      );
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const deleteDropOffLocation = createAsyncThunk(
  "delete/drop-off",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.deleteDropOff(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const editDropOffLocation = createAsyncThunk(
  "edit/space-owner",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.editDropOffLocations(
        data.id,
        data.updateData
      );
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const createLand = createAsyncThunk(
  "create/land",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await SpaceOwnerService.createLands(data);
      return res;
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
