import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { deleteCategory, getCategory } from "../../store/actions";
import DeleteModal from "../common/DeleteModal";
import UpdateSubCategoryModal from "./UpdateSubCategoryModal";
import StyledButton from "../UI/btn";
import Edit from '../../assets/images/pencil-icon.svg'

const WasteCategoryItem = styled.div`
  ${tw`flex items-center justify-between px-4 py-4`}
`;
const WasteCategoryItemTitle = styled.div`
  ${tw`flex items-center gap-2`}
`;
export const WasteCategoryItemTitleBullet = styled.div`
  ${tw`bg-secondary h-5 w-5 rounded-sm`}
`;
const WasteCategoryItemTitleText = styled.div`
  ${tw`capitalize text-base`}
`;
const WasteCategoryItemImg = styled.img`
  ${tw`cursor-pointer`}
`;
const WasteCategoryButtons = styled.div`
  ${tw`flex items-center gap-2`}
`;

const WasteSubCategoryCard = ({
  name = "",
  id = "",
  wastepicker = "",
  showDelete = true,
}) => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const deleteHandler = async () => {
    const res = await dispatch(deleteCategory(id));
    if (!res.error) dispatch(getCategory());
  };



  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={deleteHandler}
        title={name}
        type="category"
      />
      <UpdateSubCategoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        name={name}
        wastepicker={wastepicker}
        id={id}
      />
      
      <WasteCategoryItem>
        <WasteCategoryItemTitle>
          <WasteCategoryItemTitleText>{name}</WasteCategoryItemTitleText>
        </WasteCategoryItemTitle>
        <WasteCategoryButtons>
          {/* <StyledButton
            buttonStyle="btn--primary--outline"
            buttonSize="btn--small"
            onClick={() => setShowModal(true)}
          >
            Edit
          </StyledButton> */}

          {
            user_claims?.edit && 
            <WasteCategoryItemImg
                src={Edit}
                alt="edit-icon"
                onClick={() => {
                  setShowModal(true);
                }}
              />            
          }

          {showDelete && user_claims?.delete &&(
            <WasteCategoryItemImg
              src="/assets/images/redbin.svg"
              alt="delete-icon"
              onClick={() => {
                setDeleteModal(true);
              }}
            />
          )}
        </WasteCategoryButtons>
      </WasteCategoryItem>
    </>
  );
};

export default WasteSubCategoryCard;
