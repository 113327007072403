import { Popover, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Tabcontent from "../../../../components/UI/TabContent";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import {
  filterCredits,
  filterWithdrawals,
  getCredits,
  getWithdrawals,
  searchCredits,
  searchWithdrawals,
} from "../../../../store/actions";
import { truncate } from "../../../../utils/constants";

const WalletHistory = ({ match }) => {
  const [, , setSelectedKey] = useState("0");
  const [, , setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [tableDataCredit, setTableDataCredit] = useState([]);
  const [tableDataWithdrawal, setTableDataWithdrawal] = useState([]);
  const [creditPaginationData, setCreditPaginationData] = useState();
  const [withdrawalPaginationData, setWithdrawalPaginationData] = useState();

  const dispatch = useDispatch();
  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onSwitch = (key) => {
    setSelectedKey(key);
  };

  const onFetchCredit = async (page = 1) => {
    const payloads = {
      id: match?.params?.id,
      payload,
      page,
    };
    const res = await dispatch(getCredits(payloads));
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataCredit(walletHistory);
      setCreditPaginationData({ ...paginationData, date: payload });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onSearchCredit = async (key, page = 1) => {
    const res = await dispatch(
      searchCredits({
        id: match?.params?.id,
        key,
        page,
      })
    );
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataCredit(walletHistory);
      setCreditPaginationData({ ...paginationData, key });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onFilterCredit = async (date, page) => {
    const res = await dispatch(
      filterCredits({
        id: match?.params?.id,
        payload: date,
        page,
      })
    );
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataCredit(walletHistory);
      setCreditPaginationData({ ...paginationData, date });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onFetchWithdrawal = async (page = 1) => {
    const res = await dispatch(
      getWithdrawals({
        id: match?.params?.id,
        payload,
        page,
      })
    );
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataWithdrawal(walletHistory);
      setWithdrawalPaginationData({ ...paginationData, date: payload });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onSearchWithdrawal = async (key, page = 1) => {
    const res = await dispatch(
      searchWithdrawals({
        id: match?.params?.id,
        key,
        page,
      })
    );
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataWithdrawal(walletHistory);
      setWithdrawalPaginationData({ ...paginationData, key });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onFilterWithdrawal = async (date, page = 1) => {
    const res = await dispatch(
      filterWithdrawals({
        id: match?.params?.id,
        payload: date,
        page,
      })
    );
    if (!res.error) {
      const { walletHistory, ...paginationData } = res?.payload?.data;
      setTableDataWithdrawal(walletHistory);
      setWithdrawalPaginationData({ ...paginationData, date });
      setTotalPages(paginationData?.totalPages);
    }
  };

  useEffect(() => {
    onFetchCredit();
    onFetchWithdrawal();
  }, []);

  const statusColor = (status) => {
    const text = status.toLowerCase();
    let classStyle = "";
    if (text === "completed") {
      classStyle += "text-primary";
    } else if (text === "cancelled") {
      classStyle += "text-error";
    } else if (text === "pending") {
      classStyle += "text-orange-500";
    } else if (text === "processing") {
      classStyle += "text-blue-500";
    } else {
      classStyle += "text-red-500";
    }
    return classStyle;
  };

  function sumByKey(arr, key) {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      if (obj.hasOwnProperty(key) && typeof obj[key] === "number") {
        total += obj[key];
      }
    }
    return total;
  }

  const data = [
    {
      title: "Credit",
      link: "Credit",
      data: tableDataCredit,
      filterHandler: onFilterCredit,
      searchHandler: onSearchCredit,
      fetch: onFetchCredit,
      totalPages: creditPaginationData?.totalPages,
      paginationData: creditPaginationData,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
        },
        {
          title: "Collector's Name",
          dataIndex: "recyclerId",
          key: "recyclerId",
          render: (text) => <p>{text?.fullname}</p>,
        },
        {
          title: "Waste Category",
          dataIndex: "categories",
          key: "categories",
          render: (categories) => (
            <span>
              {(categories.slice(0, 3) || []).map((waste) => {
                return (
                  <Tag key={waste}>
                    <Popover content={waste?.name || waste}>
                      {truncate(waste?.name, 10)}
                    </Popover>
                  </Tag>
                );
              })}
            </span>
          ),
        },
        {
          title: "Weight",
          dataIndex: "categories",
          key: "categories",
          render: (categories) => (
            <span>
              <Tag>{sumByKey(categories, "quantity")}</Tag>
            </span>
          ),
        },
        {
          title: "Amount",
          dataIndex: "pointGained",
          key: "pointGained",
        },
      ],
    },
    {
      title: "Withdrawal",
      link: "Withdrawal",
      data: tableDataWithdrawal,
      totalPages: withdrawalPaginationData?.totalPages,
      paginationData: withdrawalPaginationData,
      filterHandler: onFilterWithdrawal,
      searchHandler: onSearchWithdrawal,
      fetch: onFetchWithdrawal,
      columns: [
        {
          title: "Request Date",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
        },
        {
          title: "Payment ID",
          dataIndex: "reference",
          key: "reference",
        },
        {
          title: "Amount",
          dataIndex: "requestedAmount",
          key: "requestedAmount",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text) => (
            <p className={`${statusColor(text)}`}>{text.toLowerCase()}</p>
          ),
        },
      ],
    },
  ];

  const onRefresh = () => {
    onFetchCredit();
    onFetchWithdrawal();
  };

  useEffect(() => {
    onRefresh();
  }, []);

  const pages = [{ name: "Space Owner", link: "/admin/pakam-associates" }];
  const previous = [
    {
      name: "Profile details",
      link: `/admin/space_details/${match?.params?.id}`,
    },
  ];

  return (
    <div>
      <BreadCrumb pages={pages} previous={previous} current="Wallet History" />
      <Tabcontent
        data={data}
        onSwitch={onSwitch}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        onRefresh={onRefresh}
      />
    </div>
  );
};

export default WalletHistory;
