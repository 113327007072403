
import React from 'react';
import Chart from 'react-apexcharts';

const NewBarChart = ({data1}) => {

  const options = {
    chart: {
      id: 'pakam-household-chart',
    },
    toolbar: {
        show: true,
        tools: {
          download: true, // Enable download option to see the toolbar
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        customIcons: [
          {
            icon: '<div style="font-size: 18px; cursor: pointer; display: flex; align-items: center;"> Export <img src="/assets/images/add-icon.svg" width="20" style="margin-left: 5px;"></div>',
            index: -1,
            title: 'Custom Icon',
            class: 'custom-icon',
            click: function(chart, options, e) {
              console.log('custom icon clicked');
              // Add your custom functionality here
            },
        }]
    },

    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + ' KG';
        }
      }
    },
    colors: [data1?.barColor], 
    plotOptions: {
        bar: {
          borderRadius:4,
          horizontal: false, 
          columnWidth: '40%',
          barHeight: '40%',
        }},
        series: {
            spacing: 20, 
          },
        dataLabels: {
            enabled: false,
          },
  };

  const series = [
    {
      name: data1.title,
      data: data1.data,
    },
  ];

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height='300px'
          />
        </div>
      </div>
    </div>
  );
};

export default NewBarChart;

