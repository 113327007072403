import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../reducers/appSlice";
import { handleError } from ".";
import ResourceService from "../../services/resourceService";


export const fetchResourceSummaryData = createAsyncThunk(
  "resource/summary-data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { monthIndex } = data;
      const res = await ResourceService.FetchResourceSummaryData(
        monthIndex
      );
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const fetchResourceRecycleMaterial = createAsyncThunk(
  "resource/recycle-material",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { monthIndex } = data;
      const res = await ResourceService.FetchResourceRecycleMaterial(
        monthIndex
      );
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const fetchResourceArea = createAsyncThunk(
  "resource/resource-area",
  async (_ , { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.FetchResourceArea();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);


export const fetchResourceByStatus = createAsyncThunk(
  "resource/recycle-area",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.FetchResourceByStatus(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const fetchResourceByArea = createAsyncThunk(
  "resource/recycle-area",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.FetchResourceByArea(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const fetchResourceByWasteCollected = createAsyncThunk(
  "resource/recycle-material",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.FetchResourceByWasteCollected(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);


export const getResources = createAsyncThunk(
  "resource/get",
  async (data, { dispatch }) => {
    const { page, currentMonth } = data;
    dispatch(startLoad());
    try {
      const res = await ResourceService.getResources(page, currentMonth);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const createResources = createAsyncThunk(
  "resource/add",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.createResources(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const updateResources = createAsyncThunk(
  "resource/update",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { id, resourceData } = data;
      const res = await ResourceService.updateResources(id, resourceData);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const deleteResources = createAsyncThunk(
  "resource/delete",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.deleteResources(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const getResourceOrganisations = createAsyncThunk(
  "resource/org",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.GetAllOrganisations();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const getAllWasteDetails = createAsyncThunk(
  "resource/waste-details",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.GetAllWasteDetails(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const getOrgWasteDetails = createAsyncThunk(
  "resource/org-waste-details",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await ResourceService.GetOrgWasteDetails(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
