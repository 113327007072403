import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "../../../../components/UI/Table";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import {
  dropOffSpaceLocations,
  filterDropOffSpaceLocations,
  searchDropOffSpaceLocations,
} from "../../../../store/actions";

const DropOffLocationDetails = ({ match }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onFetch = async (page = 1) => {
    const res = await dispatch(
      dropOffSpaceLocations({
        id: match?.params?.id,
        payload,
        page,
      })
    );

    if (!res.error) {
      const { dropoffLocations, ...paginationData } = res?.payload?.data;
      setTableData(dropoffLocations);
      setPaginationData({ ...paginationData, page });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      searchDropOffSpaceLocations({
        id: match?.params?.id,
        key,
        page,
      })
    );
    if (!res.error) {
      const { dropoffLocations, ...paginationData } = res?.payload?.data;
      setTableData(dropoffLocations);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      filterDropOffSpaceLocations({
        id: match?.params?.id,
        payload: date,
        page,
      })
    );
    if (!res.error) {
      const { dropoffLocations, ...paginationData } = res?.payload?.data;
      setTableData(dropoffLocations);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData?.totalPages);
    }
  };

  useEffect(() => {
    onFetch();
  }, []);

  const onRefresh = () => {
    onFetch();
  };

  const data = tableData?.map((data) => ({
    address: data?.address,
    phone: data?.contactPhone,
  }));

  console.log("data for all -- ", data);

  const columns = [
    {
      title: "Dropoff Location",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];
  const pages = [{ name: "Space Owner", link: "/admin/pakam-associates" }];
  const previous = [
    {
      name: "Profile details",
      link: `/admin/space_details/${match?.params?.id}`,
    },
  ];

  return (
    <div>
      <BreadCrumb
        pages={pages}
        previous={previous}
        current="Drop Off Locations"
      />
      <DataTable
        columns={columns}
        data={data}
        onFetch={onFetch}
        onSearch={onSearch}
        onFilter={onFilter}
        totalPages={paginationData?.totalPages}
        paginationData={paginationData}
        onRefresh={onRefresh}
        setCurrentPage={setCurrentPage}
        header
      />
    </div>
  );
};

export default DropOffLocationDetails;
