import React, { useEffect, useReducer, useState } from 'react';
import WasteSubCategoryCard, { WasteCategoryItemTitleBullet } from '../wasteCategory/wasteSubCategoryCard';
import AltMenu from '../../assets/images/menu-icon.svg'
import { DropdownContainer as DC, OptionTitle } from '../styledElements';
import styled from "styled-components";
import tw from "twin.macro";
import { use } from 'echarts';
import UpdateMainCategoryModal from '../wasteCategory/UpdateMainCategoryModal';
import { deleteCategory, getCategory } from '../../store/actions';
import { useDispatch } from 'react-redux';
import DeleteModal from '../common/DeleteModal';
import AddSubCategoryModal from '../wasteCategory/AddSubCategoryModal';

const DropdownContainer = styled(DC)`
  ${tw`right-[0] top-[2rem] py-5 max-h-[auto]`};
  max-height: 100vh;
`;
const DropButton = styled.button`
  ${tw`w-full py-2 border-b px-5 text-left hover:bg-[#FAFAFA] transition-all duration-300 ease-out `};
  // max-height: 100vh;
`;

const AccordionItem = ({ title, content, isOpen, toggle, permit, id, ...props }) => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  
  useEffect(()=>{
    if (!isOpen){
      setShow(false)
    }
  }, [isOpen])

  const deleteHandler = async () => {
    const res = await dispatch(deleteCategory(id));
    if (!res.error) dispatch(getCategory());
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={deleteHandler}
        title={title}
        type="category"
      />
      <UpdateMainCategoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        name={title}
        id={id}
        type="category"
      />
      <AddSubCategoryModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        name={title}
        sub = {content}
        // wastepicker={wastepicker}
        id={id}
        type="category"
      />
      
      <div className="border-b border-gray-200 rounded-md mb-2">
        <div
          className="w-full p-4 flex justify-between items-center focus:outline-none"
          
        >
          <div onClick={toggle} className='flex items-center gap-3 w-[90%] '>
              <WasteCategoryItemTitleBullet />
              <span className="font-semibold">{title}</span>
          </div>
          <div className='flex items-center gap-3 relative'>
              <svg
               onClick={toggle}
              className={`w-6 h-6 ml-auto ${isOpen ? 'transform rotate-180' : ''}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
              />
              </svg>  
              {
                (user_claims?.edit || user_claims?.create || user_claims?.delete) &&
                <button className='px-2' onClick={()=>setShow(!show)}>
                    <img src={AltMenu} alt="alt-menu" />
                  
                </button>                 
              }

              
              {show && (
                    <DropdownContainer width="12rem" {...props}>
                      {/* <span className="w-full flex flex justify-between items-center"> */}
                      {
                        user_claims?.edit &&
                        <DropButton onClick={()=>{
                          setShowModal(true)
                          setShow(false)
                        }}>Edit Category</DropButton>                        
                      }

                      {
                        user_claims?.create &&
                        <DropButton onClick={()=>{
                          setShowAddModal(true)
                          setShow(false)
                        }}>Add SubCategory</DropButton>                                           
                      }

                      {
                        user_claims?.delete &&
                        <DropButton onClick={()=>{
                          setDeleteModal(true)
                          setShow(false)
                          }}>Delete Category</DropButton>                     
                      }



                        {/* <OptionTitle>Waste Category</OptionTitle> */}
                      {/* </span> */}


                    </DropdownContainer>
                  )} 
          </div>

        </div>
        {isOpen && <div className="p-4 transition-all duration-500 pl-[6rem] ">

        {content?.map(({ name, _id, value, wastepicker }, i) => {
              return (
                <WasteSubCategoryCard
                  showDelete={permit?.delete}
                  name={name}
                  id={_id}
                  key={i}
                  value={value}
                  wastepicker={wastepicker}
                />
              );
            })}
          </div>}
      </div>    
    </>

  );
};

const Accordion = ({ items, permission }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {items?.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.name}
          content={item.items}
          isOpen={openIndex === index}
          toggle={() => toggleItem(index)}
          permit={permission}
          id ={item._id}
        />
      ))}
    </div>
  );
};

export default Accordion

// const App = () => {
  
//   return (
//     <div className="container mx-auto py-8">
//       <h1 className="text-2xl font-bold mb-4">Accordion Example</h1>
//       <Accordion items={accordionItems} />
//     </div>
//   );
// };

// export default App;
