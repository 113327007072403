import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import PakamLogo from "../../assets/images/logo.png";
import useForm from "../../hooks/useForm";
import { clearError } from "../../store/reducers/appSlice";
import Checkbox from "../UI/Checkbox";
import Loader from "../UI/loader";
import { MessageContainer } from "../styledElements";
import FormInput, { CheckInput } from "./FormInput";

const FormContainer = styled.div`
  /* ${tw`py-4 shadow-2xl bg-white max-w-full mx-6 lg:mx-0  lg:max-w-xl rounded-[20px] px-4 md:py-6 md:px-16`} */
  ${tw`md:py-4 md:shadow-2xl md:bg-white md:rounded-[20px] md:px-4 md:py-6 md:px-16 overflow-hidden`}

  > div {
    /* ${tw`mx-auto`} */
  }
`;
const FormTitle = styled.p`
  ${tw`font-bold text-title-active text-2xl text-center mb-6`}
`;

const PasswordLinktext = styled(Link)`
  ${tw`text-secondary text-sm font-bold ml-2`}
`;

const InstructionText = styled.p`
  ${tw`mt-6 font-medium  text-xs md:text-sm text-body mb-2 leading-[24px] md:w-[402px] w-[300px] md:mx-auto  `}
`;

const HeaderBody = styled.div`
  ${tw`md:w-[402px] w-full mx-auto py-4 flex justify-between items-center space-x-16 md:space-x-0`}
`;

const SubmitButton = styled.button`
  ${tw`disabled:cursor-not-allowed disabled:opacity-50 hover:text-secondary hover:border-2 hover:bg-white hover:border-secondary outline-none text-white bg-secondary rounded-lg text-xl py-3 w-full`}
  // height: 70px
  transition: .2s ease-in-out;
`;

const RecoveryCodeText = styled.p`
  ${tw`text-sm text-label text-center mt-3`}
  > span {
    color: #005700;
  }
`;

const InputWrapper = styled.div`
  ${tw`md:w-[402px] w-full`}
`;

const FadedText = styled.span`
  color: #e5e5e5;
`;

const LogoWrapper = styled.div`
  ${tw`flex items-center justify-around w-[120px] mx-auto my-4`}

  p {
    ${tw`font-medium text-[20px] text-secondary`}
  }
`;

const RecoveryCodeLink = () => (
  <RecoveryCodeText>
    Didn 't get code? <span>Resend</span>
  </RecoveryCodeText>
);

const PasswordSection = () => (
  <div className="text-center my-5 h-4">
    <p className="text-body font-normal text-sm">
      Forgot Password?
      <PasswordLinktext to="/auth/forgot-password">
        Retrieve now
      </PasswordLinktext>
    </p>
  </div>
);

const AuthForm = ({
  formEntries = {},
  title = "",
  type = "",
  instructionText = "",
  submitHandler = () => null,
}) => {
  const login_mode = localStorage.getItem("login_mode") || "super_admin";
  const [signRoute, setSignRoute] = useState(login_mode);
  const { setValue, errorMsgs, formValues, isValid } = useForm(formEntries);
  const { error, loading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [checked, setChecked] = useState(false);

  console.log(error)

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch, pathname]);


  const mainHandleSubmit = () => {
    submitHandler(formValues, signRoute);
  };

  const submitText = {
    login: "Log In",
    forgotPassword: "Retrieve Password",
    recoveryCode: "Confirm Code",
    "new-password": "Update New Password",
    "change-password": "Save Changes",
  }[type];

  return (
    <>
      {loading && <Loader />}
      <FormContainer>
        <LogoWrapper>
          <img src={PakamLogo} alt="pakam" />
          <p>Pakam</p>
        </LogoWrapper>

        <FormTitle> {title} </FormTitle>
        {["/auth/login", "/auth/forgot-password"].includes(pathname) ? (
          <HeaderBody className="">
            <Checkbox
              label="Pakam Admin"
              checked={signRoute === "super_admin" ? true : false}
              onClick={() => {
                localStorage.setItem("login_mode", "super_admin");
                setSignRoute("super_admin");
              }}
            />
            <Checkbox
              label="Company"
              checked={signRoute !== "super_admin" ? true : false}
              onClick={() => {
                localStorage.setItem("login_mode", "user_admin");
                setSignRoute("user_admin");
              }}
            />
          </HeaderBody>
        ) : null}
        {error && (
          <MessageContainer>
            <p> {error && 'Something went wrong, Please try again!'} </p>
          </MessageContainer>
        )}

        {instructionText && (
          <InstructionText>
            {instructionText}
            {type === "new-password" && <FadedText> e.g Davtg1kl </FadedText>}
          </InstructionText>
        )}

        <InputWrapper className="mx-auto pt-5">
          {Object.entries(formEntries).map(([title, value], idx) => (
            <>
              {value.type !== "checkbox" ? (
                <FormInput
                  key={idx}
                  placeholder={value.placeholder}
                  type={value.type}
                  label={value.label}
                  changeHandler={(e) => setValue(title, e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && mainHandleSubmit()}
                  errorMsg={errorMsgs[title]}
                  value={formValues[title]}
                />
              ) : (
                <CheckInput
                  key={idx}
                  type={value.type}
                  changeHandler={(e) => setValue(title, e.target.value)}
                  value={formValues[title]}
                  clickHandler={() => setChecked(!checked)}
                  checked={checked}
                />
              )}
            </>
          ))}
          {pathname === "/user/dashboard" ? (
            <SubmitButton
              onClick={mainHandleSubmit}
              disabled={!isValid || !checked}
            >
              {submitText}
            </SubmitButton>
          ) : pathname === "/admin/dashboard" ? (
            <SubmitButton
              onClick={mainHandleSubmit}
              disabled={!isValid || !checked}
            >
              {submitText}
            </SubmitButton>
          ) : (
            <SubmitButton onClick={mainHandleSubmit} disabled={!isValid}>
              {submitText}
            </SubmitButton>
          )}
          {type === "recoveryCode" && <RecoveryCodeLink />}{" "}
          {type === "login" && <PasswordSection />}
        </InputWrapper>
      </FormContainer>
    </>
  );
};

export default memo(AuthForm);
