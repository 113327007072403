// import axios from "../core/api/axios/axios";
import baseAxios from "../core/api/axios/baseAxios";
import axios from "axios";

export const newbaseURL = process.env.REACT_APP_NEW_TEST_URL;


class FinancialService {
  static async getFinancialSummary() {
    const res = await axios.get(`${newbaseURL}/invoice/summary`);
    return res.data || res;
  }

  static async getFinancialSummaryNew() {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organizations/summary`);
    return res.data || res;
  }
  static async getOrgFinancialSummaryNew(id) {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/details?organisationId=${id}`);
    console.log(res)
    return res.data || res;
  }
  static async FilterFinancialSummaryNew(start, end) {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organizations/summary?start=${start}&end=${end}`);
    console.log(res)
    return res.data || res;
  }
  static async FilterOrgFinancialSummaryNew(start, end, id) {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/details?organisationId=${id}&start=${start}&end=${end}`);
    console.log(res)
    return res.data || res;
  }

  static async getFinancialOrganizations(data) {
    const { start, end, key, page = 1 } = data;
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organizations`);
    return res.data || res;
  }
  static async getOrganizationsTransactions(data) {
    const {  page = 1, id } = data;
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/transaction?organisationID=${id}`);
    return res.data || res;
  }
  static async searchOrganizationsTransactions(data) {
    const { start, end, page = 1, id, key } = data;
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/transaction?organisationID=${id}&limit=20&page=${page}&start=${start}&end=${end}&search=${key}`);
    return res.data || res;
  }
  static async searchOrganizationsInvoice(data) {
    const { start, end, page = 1, id, key } = data;
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/invoice?organisationID=${id}&status=${key}`);
    return res.data || res;
  }
  static async DownloadAsPDF(data) {
    const {  id } = data;
    const res = await axios.get(`${newbaseURL}/v2/invoice/generate/invoicepdf/${id}`);
    return res.data || res;
  }
  static async getOrganizationsInvoices(data) {
    const {  page = 1, id } = data;
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/organization/invoice?organisationID=${id}`);
    return res.data || res;
  }
  static async deleteOrganizationsInvoices(data) {
    const {  invoiceNumber } = data;
    const res = await axios.delete(`${newbaseURL}/v2/invoice/delete/${invoiceNumber}`);
    return res.data || res;
  }


  static async getOutstandingFinancials(data) {
    const { start, end, key, page = 1 } = data;
    const url = !key
      ? `/invoice/outstanding/payment?start=${start}&end=${end}&page=${page}`
      : `/invoice/outstanding/payment?key=${key}&page=${page}`;
    const res = await axios.get(url);
    return res.data || res;
  }

  static async getFinancialsFilter(data) {
    const { start, end, key, page = 1 } = data;
    const url = `${newbaseURL}/v2/admin/finance/organizations?limit=20&page=${page}&start=${start}&end=${end}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/invoice/completed/payment`);
    return res.data || res;
  }
  static async getFinancialTransactionFilter(data) {
    const { start, end, id, page = 1 } = data;
    const url = `${newbaseURL}/v2/admin/finance/organization/transaction?organisationID=${id}&limit=20&page=${page}&start=${start}&end=${end}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/invoice/completed/payment`);
    return res.data || res;
  }
  static async getFinancialsInvoiceFilter(data) {
    const { start, end, id, page = 1 } = data;
    const url = `${newbaseURL}/v2/admin/finance/organization/invoice?organisationID=${id}&limit=20&page=${page}&start=${start}&end=${end}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/invoice/completed/payment`);
    return res.data || res;
  }


  static async getFinancialsSearch(data) {
    const { start, end, key, page = 1 } = data;
    const url = `${newbaseURL}/v2/admin/finance/organizations?limit=5&page=${page}&start=${start}&end=${end}&search=${key}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/invoice/completed/payment`);
    return res.data || res;
  }


  static async getCompletedFinancials(data) {
    const { start, end, key, page = 1 } = data;
    const url = !key
      ? `/invoice/completed/payment?start=${start}&end=${end}&page=${page}`
      : `/invoice/completed/payment?key=${key}&page=${page}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/invoice/completed/payment`);
    return res.data || res;
  }

  static async completePayment(invoiceNumber) {
    const res = await axios.get(`${newbaseURL}/invoice/${invoiceNumber}/markAsPaid`);
    return res.data || res;
  }

  static async getInvoice(invoiceNumber) {
    const res = await baseAxios.get(`/invoice/${invoiceNumber}`);
    return res.data || res;
  }
  static async downloadInvoice(invoiceNumber) {
    const res = await axios.get(`${newbaseURL}/generate/invoicepdf/${invoiceNumber}`);
    return res.data || res;
  }

  static async partners() {
    const res = axios.get("${newbaseURL}/v2/payment/partners");
    return res?.data || res;
  }

  static async searchPartners(key) {
    const res = axios.get(`${newbaseURL}/v2/payment/partners?key=${key}`);
    return res?.data || res;
  }

  static async partnerDetail(id, page) {
    const res = axios.get(
      `${newbaseURL}/v2/payment/partners/${id}/payouts?page=${page}`
    );
    return res?.data || res;
  }

  static async partnerSearch(id, page, key) {
    const res = axios.get(
      `${newbaseURL}/v2/payment/partners/${id}/payouts?page=${page}&key=${key}`
    );
    return res?.data || res;
  }

  static async partnerFilter(id, page, currentMonth) {
    const res = axios.get(
      `${newbaseURL}/v2/payment/partners/${id}/payouts?page=${page}&start=${currentMonth.start}&end=${currentMonth.end}`
    );
    return res?.data || res;
  }

  static async markAsPaid(id, data) {
    const res = axios.put(`/insurance/health/${id}/markPaid`, data);
    return res?.data || res;
  }

  static async FetchHouseholdChart(year, page) {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/household/chart?year=${year}`
    );
    return res?.data || res;
  }
  static async FetchHouseholdData(start, end, page) {
    const res = await axios.get(`${newbaseURL}/v2/admin/finance/household/balance?start=${start}&end=${end}`
    );
    return res?.data || res;
  }
    static async SetInvoiceStatus(status, id, amount, page) {
      console.log(id, amount)
    const res = await axios.put(`${newbaseURL}/v2/admin/finance/invoice/status?invoiceId=${id}&status=${status}&amount=${amount}`
    );
    return res?.data || res;
  }
}

export default FinancialService;

// https://pakam-staging.herokuapp.com/api${newbaseURL}/v2/payment/partners/6470a06f27d8b22ae46fbf76/payouts?page=1&key=MyCoverGenius FlexiCare Mini
