import React, { useEffect, } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { partners } from "../../../../store/actions";
import { setTableBodyData } from "../../../../store/reducers/appSlice";

const PartnerHeader = styled.header`
  ${tw`bg-white border-t-[1px] px-12 pt-3 pb-[1px] font-bold text-base`}
`;
const PartnerList = styled.div`
  ${tw`bg-white border-t-[1px] px-14 pt-6 pb-3 text-sm flex items-center justify-between cursor-pointer text-black`}
`;

const Partners = () => {
  const dispatch = useDispatch();

  const fetchPartners = async (page = 1) => {
    const res = await dispatch(partners());
    dispatch(setTableBodyData(res?.payload?.data));
  };
  const { tableBody } = useSelector((state) => state.app);

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <>
      <PartnerHeader>Organization Name</PartnerHeader>
      {tableBody?.map((el, i) => (
        <a key={i}>
          <Link
            to={{
              pathname: `financial_partners/${el._id}`,
              state: { name: el.name },
            }}
          >
            <PartnerList>
              <p>{el?.name}</p>
              <IoIosArrowForward className="text-label text-base" />

              {/* <Link
              to={{
                pathname: "/app/style/form",
                state: {
                  item: item,
                  styleList: styleList,
                },
              }}
            >
              Go Button
            </Link> */}
            </PartnerList>
          </Link>
        </a>
      ))}
    </>
  );
};

export default Partners;

//  /insurance/health/643fc58484314f091bd2002a/markPaid
//  /v2/payment/partners?key=ster
//  /v2/payment/partners/6470a06f27d8b22ae46fbf76/payouts?page=2&resultsPerPage=5&end=2023-06-01
