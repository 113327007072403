import { Popover, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import PickupModal from "../../components/UI/PickupModal";
import Tabcontent from "../../components/UI/TabContent";
import StyledButton from "../../components/UI/btn";
import Button from "../../components/UI/button";
import { AlertModal } from "../../components/common/AlertModal";
import { RejectModal } from "../../components/common/RejectModal";
import {
  approvedCompletedSchedues,
  filterCompanyApprovedCompletedSchedule,
  filterCompanyCompleted,
  filterCompanyRejectedCompletedSchedule,
  getCompanyApprovedCompletedSchedule,
  getCompanyCompletedSchedule,
  getCompanyRejectedCompletedSchedule,
  searchCompanyApprovedCompletedSchedule,
  searchCompanyCompleted,
  searchCompanyRejectedCompletedSchedule,
} from "../../store/actions";
import { truncate } from "../../utils/constants";

export const Vector = styled.div`
  ${tw`flex justify-between items-center px-4 py-3`}
`;

const CompletedSchedule = () => {
  /****************************
   *
   * states and hooks
   *
   ****************************/
  const [showModal, setShowModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  const [rowInfo, setRowInfo] = useState([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [bodyData, setBodyData] = useState();
  const [tableBody2, setTableBody2] = useState([]);
  const [tableBody3, setTableBody3] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [newUserPagination, setNewUserPagination] = useState();
  const [newAggregatorPagination, setNewAggregatorPagination] = useState();
  const [id, setID] = useState("");
  const { currentMonthCompletedSchedule } = useSelector(
    (state) => state.schedules
  );
  const date = new Date();
  const currentMonth = {
    start: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    ),
    end: moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format(
      "YYYY-MM-DD"
    ),
  };

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      searchCompanyCompleted({
        key,
        page,
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setBodyData(companySchedules);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };
  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      filterCompanyCompleted({
        page,
        currentMonth: date,
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setBodyData(companySchedules);
      setPaginationData({ ...paginationData, date });
    }
  };

  const fetchAll = async (page = 1) => {
    const res = await dispatch(
      getCompanyCompletedSchedule({
        currentMonth: payload,
        page,
        scheduleApproval: "pending",
      })
    );

    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setBodyData(companySchedules);
      setPaginationData({ ...paginationData });
    }
  };

  const fetchApprovedCollectors = async (page = 1) => {
    const res = await dispatch(
      getCompanyApprovedCompletedSchedule({
        currentMonth: payload,
        page,
        scheduleApproval: "true",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody2(companySchedules);
      setNewUserPagination({ ...paginationData });
    }
  };

  const searchApprovedCollectors = async (key, page = 1) => {
    const res = await dispatch(
      searchCompanyApprovedCompletedSchedule({
        key,
        page,
        scheduleApproval: "true",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody2(companySchedules);
      setNewUserPagination({ ...paginationData });
    }
  };

  const filterApprovedCollectors = async (date, page = 1) => {
    const res = await dispatch(
      filterCompanyApprovedCompletedSchedule({
        page,
        currentMonth: date,
        scheduleApproval: "true",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody2(companySchedules);
      setNewUserPagination({ ...paginationData });
    }
  };

  const fetchRejectedCollector = async (page = 1) => {
    const res = await dispatch(
      getCompanyRejectedCompletedSchedule({
        currentMonth: payload,
        page,
        scheduleApproval: "false",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody3(companySchedules);
      setNewAggregatorPagination({ ...paginationData });
    }
  };

  const filterRejectedCollectors = async (date, page = 1) => {
    const res = await dispatch(
      filterCompanyRejectedCompletedSchedule({
        page,
        currentMonth: date,
        scheduleApproval: "false",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody3(companySchedules);
      setNewAggregatorPagination({ ...paginationData });
    }
  };

  const searchRejectedCollectors = async (key, page = 1) => {
    const res = await dispatch(
      searchCompanyRejectedCompletedSchedule({
        key,
        page,
        scheduleApproval: "false",
      })
    );
    if (!res.error) {
      const { companySchedules, ...paginationData } = res.payload.data;
      setTableBody3(companySchedules);
      setNewAggregatorPagination({ ...paginationData });
    }
  };

  useEffect(() => {
    fetchApprovedCollectors();
    fetchRejectedCollector();
  }, []);

  const handleApprove = async (scheduleId) => {
    const res = await dispatch(
      approvedCompletedSchedues({ scheduleId: scheduleId })
    );
    onRefresh();
  };

  const deleteHandler = () => {
    setRejectModal(true);
  };

  const rejectHandler = async () => {
    deleteHandler();
  };

  const onRefresh = () => {
    fetchAll();
    fetchApprovedCollectors();
    fetchRejectedCollector();
  };

  useEffect(() => {
    onRefresh();
  }, []);

  // *** start of tab contents**///

  const data = [
    {
      title: "Awaiting Approval",
      link: "All",
      data: bodyData,
      totalPages: paginationData?.totalPages,
      paginationData: paginationData,
      filterHandler: onFilter,
      searchHandler: onSearch,
      fetch: fetchAll,
      columns: [
        {
          title: "Pickup Location",
          dataIndex: "address",
          key: "address",
          render: (text) => <p>{truncate(text, 30)}</p>,
        },
        {
          title: "Waste Quantity",
          dataIndex: "categories",
          key: "categories",
          render: (wastes) => (
            <span>
              {(wastes.slice(0, 3) || []).map((waste) => {
                return <Tag key={waste}>{waste?.name || waste}</Tag>;
              })}
            </span>
          ),
        },
        {
          title: "Customer Phone",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Completed By",
          dataIndex: "recycler",
          key: "recycler",
        },
        // {
        //   title: "Action",
        //   dataIndex: "action",
        //   key: "action",
        //   render: (text, record) => (
        //     <Space size="middle">
        //       <Button
        //         type=""
        //         onClick={() => {
        //           setRowInfo(record);
        //           setShowModal(true);
        //           // console.log(record.info);
        //         }}
        //       >
        //         See More
        //       </Button>
        //     </Space>
        //   ),
        // },

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Button
                  type=""
                  onClick={() => {
                    setRowInfo(record);
                    setShowModal(true);
                    // console.log(record.info);
                  }}
                >
                  See More
                </Button>

                <StyledButton
                  type=""
                  buttonStyle="btn--primary--outline"
                  buttonSize="btn--small"
                  onClick={() => {
                    setAlertModal(!alertModal);
                    setID(record?._id);
                  }}
                >
                  Approve
                </StyledButton>
                <Vector>
                  <StyledButton
                    type=""
                    buttonStyle="btn--danger--outline"
                    buttonSize="btn--small"
                    // onClick={() => rejectHandler(record?._id)}
                    onClick={() => {
                      rejectHandler(record?._id);
                      setID(record?._id);
                    }}
                  >
                    Reject
                  </StyledButton>
                </Vector>
              </Space>
            );
          },
        },
      ],
    },

    {
      title: "Approved",
      link: "Verfied",
      data: tableBody2,
      totalPages: newUserPagination?.totalPages,
      paginationData: newUserPagination,
      filterHandler: filterApprovedCollectors,
      searchHandler: searchApprovedCollectors,
      fetch: fetchApprovedCollectors,
      columns: [
        {
          title: <span>Schedule Creator </span>,
          dataIndex: "fullname",
          key: "fullname",
        },

        {
          title: "Pickup Location",
          dataIndex: "address",
          key: "address",
          render: (text) => <p>{truncate(text, 30)}</p>,
        },
        // {
        //   title: "Waste Category",
        //   dataIndex: "categories",
        //   key: "categories",
        //   render: (wastes) => (
        //     <span>
        //       {(wastes.slice(0, 3) || []).map((waste) => {
        //         // let color = waste.length > 5 ? "geekblue" : "green";
        //         // if (waste === "loser") {
        //         //   color = "volcano";
        //         // }
        //         return <Tag key={waste}>{waste?.name || waste}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },

        {
          title: "Waste Category",
          dataIndex: "categories",
          key: "categories",
          render: (categories) => (
            <span>
              {(categories.slice(0, 3) || []).map((waste) => {
                return (
                  <Tag key={waste}>
                    <Popover content={waste?.name || waste}>
                      {truncate(waste?.name, 10)}
                    </Popover>
                  </Tag>
                );
              })}
            </span>
          ),
        },

        {
          title: "Customer Phone",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Completed By",
          dataIndex: "recycler",
          key: "recycler",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type=""
                onClick={() => {
                  setRowInfo(record);
                  setShowModal(true);
                }}
              >
                See More
              </Button>
              {/* <StyledButton
                type=""
                buttonStyle="btn--danger--outline"
                buttonSize="btn--small"
                onClick={() => {
                  rejectHandler(record?._id);
                  setID(record?._id);
                }}
              >
                Reject
              </StyledButton> */}
            </Space>
          ),
        },
      ],
    },

    {
      title: "Rejected",
      link: "Rejected",
      // data: newAggregator?.collectors,
      data: tableBody3,
      totalPages: newAggregatorPagination?.totalPages,
      paginationData: newAggregatorPagination,
      filterHandler: filterRejectedCollectors,
      searchHandler: searchRejectedCollectors,
      fetch: fetchRejectedCollector,
      columns: [
        {
          title: <span>Schedule Creator </span>,
          dataIndex: "fullname",
          key: "fullname",
        },

        {
          title: "Pickup Location",
          dataIndex: "address",
          key: "address",
          render: (text) => <p>{truncate(text, 30)}</p>,
        },
        // {
        //   title: "Waste Category",
        //   dataIndex: "categories",
        //   key: "categories",
        //   render: (wastes) => (
        //     <span>
        //       {(wastes.slice(0, 3) || []).map((waste) => {
        //         // let color = waste.length > 5 ? "geekblue" : "green";
        //         // if (waste === "loser") {
        //         //   color = "volcano";
        //         // }
        //         return <Tag key={waste}>{waste?.name || waste}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },

        {
          title: "Waste Category",
          dataIndex: "categories",
          key: "categories",
          render: (categories) => (
            <span>
              {(categories.slice(0, 3) || []).map((waste) => {
                return (
                  <Tag key={waste}>
                    <Popover content={waste?.name || waste}>
                      {truncate(waste?.name, 10)}
                    </Popover>
                  </Tag>
                );
              })}
            </span>
          ),
        },
        {
          title: "Customer Phone",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Completed By",
          dataIndex: "recycler",
          key: "recycler",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type=""
                onClick={() => {
                  setRowInfo(record);
                  setShowModal(true);
                  // console.log(record.info);
                }}
              >
                See More
              </Button>

              {/* <StyledButton
                type=""
                buttonStyle="btn--primary--outline"
                buttonSize="btn--small"
                onClick={() => approveHandler(record?._id)}
              >
                Approve
              </StyledButton> */}
            </Space>
          ),
        },
      ],
    },
  ];

  // *** end of tab contents**///
  return (
    <>
      <AlertModal
        showModal={alertModal}
        setShowModal={setAlertModal}
        type="role"
        text="Approve"
        handler={handleApprove}
        id={id}
      />
      <PickupModal
        showModal={showModal}
        setShowModal={setShowModal}
        userData={rowInfo}
        completed
      />
      <RejectModal
        showModal={rejectModal}
        setShowModal={setRejectModal}
        type="reason"
        handleDelete={deleteHandler}
        id={id}
        fetchApprovedCollectors
        fetchAll
        fetchRejectedCollector
      />

      <div>
        {/* <DataTable
          data={bodyData}
          columns={columns}
          header
          onFilter={onFilter}
          onSearch={onSearch}
          setCurrentPage={setCurrentPage}
          onFetch={fetchAll}
          onRefresh={onRefresh}
          paginationData={paginationData}
          totalPages={paginationData?.totalPages}
        /> */}
        <Tabcontent
          data={data}
          // onSwitch={onSwitch}
          // setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          onRefresh={onRefresh}
        />
      </div>
    </>
  );
};

export default CompletedSchedule;
