// import baseAxios from "../core/api/axios/baseAxios";
import baseAxios from "../core/api/axios/spaceAxios";

export default class SpaceOwnerService {
  static async getSpaceOwnerStats() {
    const url = `/lands/total`;
    const res = await baseAxios.get(url);
    return res?.data || res;
  }

  static async getSpaceOwnersTableData(data) {
    const res = await baseAxios.get(
      `/lands/partners?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async searchSpaceOwnersTableData(data) {
    const res = await baseAxios.get(
      `/lands/partners?key=${data.key}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterSpaceOwnersTableData(data) {
    const res = await baseAxios.get(
      `/lands/partners?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async DisabledSpaceOwner(id) {
    const res = await baseAxios.put(`/lands/deactivate/${id}`);
    return res?.data || res;
  }
  static async enabledSpaceOwner(id) {
    const res = await baseAxios.put(`/lands/activate/${id}`);
    return res?.data || res;
  }

  static async DeleteSpaceOwner(id) {
    const res = await baseAxios.delete(`/auth/delete/${id}`);
    return res?.data || res;
  }

  static async DropOffSpaceLocation(data) {
    const res = await baseAxios.get(
      `/lands/partners/location/${data.id}?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async searchDropOffSpaceLocation(data) {
    const res = await baseAxios.get(
      `/lands/partners/location/${data.id}?key=${data.key}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterDropOffSpaceLocation(data) {
    const res = await baseAxios.get(
      `/lands/partners/location/${data.id}?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async getOwnerDetail(id) {
    const res = await baseAxios.get(`/lands/partner/${id}`);
    return res?.data || res;
  }

  static async getAcceptedMaterial(data) {
    const res = await baseAxios.get(
      `/lands/partners/landstock/${data.id}?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async searchAcceptedMaterial(data) {
    const res = await baseAxios.get(
      `/lands/partners/landstock/${data.id}?key=${data.key}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterAcceptedMaterial(data) {
    const res = await baseAxios.get(
      `/lands/partners/landstock/${data.id}?start=${data.payload.start}&end=${data.payload.end}&page=${data.page}`
    );
    return res?.data || res;
  }

  static async getCredit(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?start=${data.payload.start}&end=${data.payload.end}&txType=credit&page=${data.page}`
    );
    return res?.data || res;
  }

  static async searchCredit(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?key=${data.key}&txType=credit&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterCredit(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?start=${data.payload.start}&end=${data.payload.end}&txType=credit&page=${data.page}`
    );
    return res?.data || res;
  }

  static async getWithdrawal(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?start=${data.payload.start}&end=${data.payload.end}&txType=debit&page=${data.page}`
    );
    return res?.data || res;
  }
  static async searchWithdrawal(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?key=${data.key}&txType=debit&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterWithdrawal(data) {
    const res = await baseAxios.get(
      `/lands/partners/wallet/${data.id}?start=${data.payload.start}&end=${data.payload.end}&txType=debit&page=${data.page}`
    );
    return res?.data || res;
  }

  static async createSpaceOwners(data) {
    const res = await baseAxios.post(`/lands/onboarding/spaceowner`, data);
    return res?.data || res;
  }

  static async editSpaceOwners(id, data) {
    const res = await baseAxios.patch(`/lands/partners/${id}`, data);
    return res?.data || res;
  }

  static async deleteDropOff(data) {
    const dataToDelete = {
      partnerId: data.partnerId,
    };
    const res = await baseAxios.delete(
      `/lands/delete/land/${data.locationId}`,
      { data, dataToDelete }
    );
    return res?.data || res;
  }

  static async editDropOffLocations(id, data) {
    const res = await baseAxios.patch(`/lands/update/land/${id}`, data);
    return res?.data || res;
  }

  static async createLands(data) {
    const res = await baseAxios.post(
      `/lands/add/land/${data.id}`,
      data.landDetails
    );
    return res?.data || res;
  }
}
