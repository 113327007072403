import { useEffect } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import tw from "twin.macro";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const TextInput = styled.input`
  ${tw`h-14 w-full px-3 border outline-none rounded-lg text-lg text-body focus:border-secondary `}
  border-color: #bdbdbd;
  ::placeholder {
    font-size: 16px;
  }
  &:disabled {
    background-color: rgba(229, 231, 235);
  }
  height: ${(props) => (props.height ? props.height : "56px")};
`;

const AddressForm = (props) => {
  const { setAddress, setGeoLocation, label, scroll, address, mode } = props;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "",
    requestOptions: {
      componentRestrictions: {
        country: "ng",
      },
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setAddress(value);
  }, [value]);

  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      setAddress(value);
      clearSuggestions();

      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setGeoLocation({
          lat: lat,
          lng: lng,
        });
      });

      setTimeout(() => {
        setValue("");
      }, 5000);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className={`bg-white text-black p-3 hover:bg-gray-100 transition all ease-in-out duration-700 hover:cursor-pointer`}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref} className="relative">
      <label className="!pb-6">{label}</label>
      {scroll && <br />}
      <TextInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Address"
      />

      {status === "OK" && (
        <ul
          className={`${scroll && "mt-2 h-32 overflow-x-scroll"} 
          `}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default AddressForm;
