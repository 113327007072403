import { createSlice } from "@reduxjs/toolkit";
import {
  disableSpaceOwner,
  getOwnerDetails,
  getSpaceOwnerStat,
  getSpaceOwnersDataTable,
} from "../actions/spaceOwnersAction";

const initialState = {
  spaceStats: null,
  spaceOwnerTable: null,
  disableOwner: null,
  spaceOwnerDetails: [],
};

const spaceOwnersSlice = createSlice({
  name: "spaceSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getSpaceOwnerStat.fulfilled]: (state, { payload }) => {
      state.spaceStats = payload.data;
    },
    [getSpaceOwnersDataTable.fulfilled]: (state, { payload }) => {
      state.spaceOwnerTable = payload.data;
    },

    [disableSpaceOwner.fulfilled]: (state, { payload }) => {
      state.disableOwner = payload.data;
    },

    [getOwnerDetails.fulfilled]: (state, { payload }) => {
      state.spaceOwnerDetails = payload.data;
    },
  },
});

const { reducer } = spaceOwnersSlice;
export default reducer;
