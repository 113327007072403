import React from "react";
import Modal from "./modal";

export const DisplayModal = ({ showModal, setShowModal, message, error }) => {
  return (
    <>
      <Modal
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        type="postAction"
        color={error && "#F5000F"}
      >
        <p>{`${error ? error : message}`}</p>
      </Modal>
    </>
  );
};
