import { Tabs } from "antd";
import styled from "styled-components";
import tw from "twin.macro";
import DataTable from "./Table";

const { TabPane } = Tabs;

// const operations = <Button>Extra Action</Button>;
// function callback(key) {
//   // console.log(key);
//   const mode = e.target.value;
//     // this.setState({ mode });
//     console.log("mode", mode);
// }

const TabContainer = styled.div`
  ${tw`p-3 mt-4`}
  background-color: white!important;
`;
const Tabcontent = ({
  data,
  onRefresh,
  totalPages,
  setCurrentPage,
  onSwitch,
  noFilter,
  showFilter,
  showCSV,
  showTable,
  isPaginate,
  associate,
  ratedUsers,
  setRatedUsers,
  tname
}) => {
  const callback = (key) => {
    onSwitch(key);
    // setRatedUsers(false);
  };

  return (
    <TabContainer>
      <Tabs defaultActiveKey="0" onChange={callback}>
        {data.map((el, i) => {
          return (
            <TabPane tab={el.title} key={i}>
              {associate && <div className="mt-10"></div>}
              {!el.noTable && (
                <DataTable
                  data={el.data}
                  columns={el.columns}
                  refreshUrl={el.link}
                  onFilter={el.filterHandler}
                  onActivityFilter={el.activityFilterHandler}
                  onSearch={el.searchHandler}
                  onFetch={el.fetch}
                  header
                  onRefresh={onRefresh}
                  totalPages={el.totalPages || totalPages}
                  setCurrentPage={setCurrentPage}
                  noFilter={noFilter}
                  paginationData={el.paginationData}
                  hideFilter={el.hideFilter}
                  showCSV={showCSV}
                  showTable={el.showTable}
                  hideTable={el.hideTable}
                  node={el.node}
                  isPaginate={isPaginate}
                  ratedUsers={ratedUsers}
                  setRatedUsers={setRatedUsers}
                  tname={el.title}
                  dateRange={el.dateRange}
                />
              )}
              {el.children}
            </TabPane>
          );
        })}
      </Tabs>
    </TabContainer>
  );
};
export default Tabcontent;
