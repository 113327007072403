import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import { FaAngleRight } from "react-icons/fa";
import { companySearchDropoff } from '../../store/actions/dropOffAction';

const ModalBackground = styled.div`
  width: 255px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  border-radius: 10px;
  padding-bottom: 10px;
  z-index: 99999;
  left: 100%;
  top: 10px;

  modalContainer {
    position: relative;
  }
`;
const ModalHeaderTitle = styled.h3`
  ${tw`text-base mb-0`}
`;
const ModalHeaderImg = styled.img``;
const OverlayBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  opacity: 0.7;
  z-index: 10;
`;

const DropDownFilter = ({onFilter, list}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [q, setQuery] = useState('');
    const [filterList, setFilterList] = useState([]);


    const handleChange = (e) =>{
      e.preventDefault()

      
      const input = e.target.value
      if (input !== ''){
        const newList = list.filter((i) => 
          i.text && input && i.text.toLowerCase().includes(input.toLowerCase())
        );
        
        setQuery(input)
        setFilterList(newList)
      }else{
        setQuery('')
        setFilterList(list)
      }
      
    }

    useEffect(()=>{
      setFilterList(list)
    },[list])

    const handleValueChange = (key) =>{
      onFilter(key)
    }

  return (
    <div >

        <div className='w-full px-4 pt-3 '>
            <input className='outline-none bg-gray p-1 w-full' value={q} onChange={handleChange} placeholder='Search' type="text" />
        </div>

        <div className='max-h-[400px] overflow-y-auto'>
          { filterList?.map((i,id)=> 
              <div key={id} className='w-full cursor-pointer user-text h-[40px] text-left flex justify-between items-center  px-6' onClick={()=>{
                  handleValueChange(i?.value)
                  }}>
                    <span>
                      {i?.text}
                    </span>
              </div>
            )}          
        </div>


        </div>
      
    // </div>
  )
}

export default DropDownFilter
