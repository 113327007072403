import { Tag } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { FlexContainer, ModalTitle, TitleText } from "../styledElements";
import StyledButton from "./btn";
import Modal from "./modal";

const InfoWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-12 w-11/12`}
`;

const InfoUserWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-16 w-11/12`}
`;

const InfoItem = styled.div`
  ${tw`flex flex-col space-y-2`}
`;

const InfoTitle = styled.p`
  ${tw`font-semibold text-sm leading-[22px] text-secondary`}
`;
const InfoValue = styled.p`
  ${tw`font-bold text-base leading-[28px]`};
  color: ${(props) => (props.color ? props.color : "#464F54")};
  font-size: ${(props) => (props.userAgencies ? "smaller" : "")};
`;

const initData = [
  {
    title: "Aggregator's ID",
    value: "17288",
  },
  {
    title: "Name",
    value: "John Doe",
  },
  {
    title: "Phone Number",
    value: "01-26634622",
  },
  // {
  //   title: "Gender",
  //   value: "Male",
  // },
  {
    title: "Waste Category",
    value: ["Can", "Pet bottles", "Carton"],
  },
  {
    title: "Preferred Organization",
    value: "Plastic Solutions Ng",
  },
  {
    title: "LGA/LCDA",
    value: "Alimosho",
  },
  {
    title: "Date",
    value: "Jan 15, 2022",
  },
  {
    title: "Status",
    value: "Pending",
  },
  {
    title: "Accepted Pickup:",
    value: "12",
  },
  {
    title: "Missed Pickup",
    value: "2",
  },
  {
    title: "No. of Trips Completed",
    value: "0",
  },
  {
    title: "Recycler No",
    value: "01-26634622",
  },
];

const Pointer = styled.div`
  ${tw`h-[5px] w-[5px] rounded-full bg-red-500 mr-2`};
  box-shadow: ${(props) =>
    props.color ? props.color : "0 0 0 3px rgb(217 144 144)"};
  background-color: ${(props) => (props.color ? props.color : "white")};
`;

const PickupModal = ({
  data = initData,
  showModal = false,
  setShowModal = {},
  userData,
  missed,
  cancelled,
  completed,
  accepted,
  pending,
  dashboard,
  dropOff,
  totalusercompleted,
  acceptedMaterials,
  userdropoff,
  partners,
}) => {
  const renderStatus = (text) => {
    let className = "";
    if (text === "successful") {
      className = "text-secondary rounded-sm bg-secondary";
    } else if (text === "failed") {
      className = "text-red-400 bg-red-200";
    } else {
      className = "text-blue-400 bg-blue-200";
    }
    return (
      <p className={`${className} font-bold py-[3px] w-fit px-2 text-sm`}>
        <span className="flex items-center">
          <Pointer
            color={
              text === "successful"
                ? "green"
                : text === "failed"
                ? "red"
                : "blue"
            }
          />{" "}
          {text}
        </span>
      </p>
    );
  };

  const capitalize = (text) =>
    text?.substring(0, 1).toUpperCase() + text?.slice(1).toLowerCase();

  return (
    <Modal show={showModal} close={() => setShowModal(false)} width="48rem">
      <ModalTitle>
        <TitleText className="">More Details</TitleText>
        <StyledButton
          buttonSize="btn--medium"
          onClick={() => setShowModal(false)}
        >
          Close
        </StyledButton>
      </ModalTitle>

      {!userData ? (
        <InfoWrapper>
          {data.map(({ title, value }) => {
            let color = "";
            if (["status", "missed pickup"].includes(title.toLowerCase()))
              color = "red";
            else if (
              title.toLowerCase() === "status" &&
              value.toLowerCase() !== "pending"
            )
              color = "#005700";

            return (
              <InfoItem key={title}>
                <InfoTitle>{title}:</InfoTitle>
                {typeof value !== "object" ? (
                  title.toLowerCase() !== "status" ? (
                    <InfoValue color={color}>{value}</InfoValue>
                  ) : (
                    <FlexContainer>
                      <Pointer />
                      <InfoValue color={color}>{value}</InfoValue>:
                    </FlexContainer>
                  )
                ) : (
                  <FlexContainer>
                    {value.map((v) => (
                      <Tag key={v}>{v?.name || v}</Tag>
                    ))}
                  </FlexContainer>
                )}
              </InfoItem>
            );
          })}
        </InfoWrapper>
      ) : (
        <InfoUserWrapper>
          {!partners && !acceptedMaterials && userData?.collectorStatus !== "accept"  ? (
            <>
              <InfoItem>
                <InfoTitle>Schedule ID:</InfoTitle>
                <InfoValue>{userData._id?.slice(0, 6)}</InfoValue>
              </InfoItem>
            </>
          ) : null}

        {accepted && userData?.collectorStatus === "accept" && (
            <InfoItem>
              <InfoTitle>Pickup Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.schedule?.pickUpDate).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}

          {/* partners details */}

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Date:</InfoTitle>
                <InfoValue>
                  {moment(userData?.createdAt).format("YYYY-MM-DD")}
                </InfoValue>
              </InfoItem>
            </>
          )}

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Customer Name:</InfoTitle>
                <InfoValue>{userData?.beneName}</InfoValue>
              </InfoItem>
            </>
          )}

          <InfoItem>
            <InfoTitle>Phone Number:</InfoTitle>
            <InfoValue>{userData?.phone}</InfoValue>
          </InfoItem>

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Account Number:</InfoTitle>
                <InfoValue>{userData?.destinationAccount}</InfoValue>
              </InfoItem>
            </>
          )}

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Account Name:</InfoTitle>
                <InfoValue>{userData?.bankName}</InfoValue>
              </InfoItem>
            </>
          )}

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Bank:</InfoTitle>
                <InfoValue>{userData?.bankName}</InfoValue>
              </InfoItem>
            </>
          )}

          {partners && (
            <>
              <InfoItem>
                <InfoTitle>Amount:</InfoTitle>
                <InfoValue>
                  {userData?.withdrawalAmount}(
                  {capitalize(userData?.currency || "Ngn")})
                </InfoValue>
              </InfoItem>
            </>
          )}

          {partners && !completed && (
            <>
              <InfoItem>
                <InfoTitle>status:</InfoTitle>
                <InfoValue>{renderStatus(userData?.status)}</InfoValue>
              </InfoItem>
            </>
          )}

          {completed && (
            <>
              <InfoItem>
                <InfoTitle>status:</InfoTitle>
                <InfoValue>
                  {renderStatus(userData?.schedule?.completionStatus)}
                </InfoValue>
              </InfoItem>
            </>
          )}

          {!partners && !totalusercompleted && (
            <>
              <InfoItem>
                <InfoTitle>Waste Category:</InfoTitle>
                <FlexContainer>
                  {userData?.categories?.map((v) => (
                    <Tag>
                      {v?.name || v} {v?.quantity}{" "}
                      {acceptedMaterials && v?.quantity}
                    </Tag>
                  ))}
                </FlexContainer>
              </InfoItem>
            </>
          )}

          {totalusercompleted && (
            <>
              <InfoItem>
                <InfoTitle>Waste Category:</InfoTitle>
                <FlexContainer>
                  {userData?.categoriesQuantity?.map((v) => (
                    <Tag>
                      {v?.name || v} {v?.quantity}{" "}
                      {acceptedMaterials && v?.quantity}
                    </Tag>
                  ))}
                </FlexContainer>
              </InfoItem>
            </>
          )}


        {!completed && (
            <>
              {pending ||
              accepted ||
              missed ||
              cancelled ||
              totalusercompleted ? (
                <InfoItem>
                  <InfoTitle>Channel:</InfoTitle>
                  {userData?.channel}
                </InfoItem>
              ) : (
                ""
              )}
            </>
          )}


          {!completed && (
            <>
              {!partners && !acceptedMaterials && (
                <>
                  {dashboard || totalusercompleted ? (
                    <InfoItem>
                      <InfoTitle>Waste Quantity:</InfoTitle>
                      <InfoValue>
                        {userData?.quantity || userData?.weight} Kg
                      </InfoValue>
                    </InfoItem>
                  ) : (
                    <InfoItem>
                      <InfoTitle>Waste Quantity:</InfoTitle>
                      <InfoValue>{userData?.quantity} bags</InfoValue>
                    </InfoItem>
                  )}
                </>
              )}
            </>
          )}
          {completed && (
            <>
              {completed ? (
                <InfoItem>
                  <InfoTitle>Waste Quantity:</InfoTitle>
                  <InfoValue>{userData?.weight} Kg</InfoValue>
                </InfoItem>
              ) : (
                <InfoItem>
                  <InfoTitle>Waste Quantity:</InfoTitle>
                  <InfoValue>{userData?.quantity} bags</InfoValue>
                </InfoItem>
              )}
            </>
          )}

          {!partners && !acceptedMaterials && missed ? (
            <>
              <InfoItem>
                <InfoTitle>Pickup Date:</InfoTitle>
                <InfoValue>
                  {moment(userData?.pickUpDate).format("YYYY-MM-DD")}
                </InfoValue>
              </InfoItem>
            </>
          ) : null}

          {!dropOff && !partners && !acceptedMaterials && (
            <InfoItem>
              <InfoTitle>Pickup Location:</InfoTitle>
              <InfoValue>{userData?.address}</InfoValue>
            </InfoItem>
          )}

          {completed && (
            <InfoItem>
              <InfoTitle>Pickup Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.schedule?.pickUpDate).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}

          {completed || cancelled || missed || pending 
          ? (
            <InfoItem>
              <InfoTitle>Lcd:</InfoTitle>
              <InfoValue>{userData?.schedule?.lcd ?? userData?.lcd}</InfoValue>
            </InfoItem>
          ) : null} 

          {!dropOff && !partners && !acceptedMaterials && !completed && (
            <InfoItem>
              <InfoTitle>Status:</InfoTitle>

              {userData?.completionStatus === "pending" &&
                userData?.collectorStatus === "accept" && (
                  <FlexContainer userAgencies color="rgba(0, 154, 0, 1)">
                    <Pointer color="#fff" />

                    <InfoValue userAgencies color="#fff">
                      Accepted
                    </InfoValue>
                  </FlexContainer>
                )}

              {userData?.completionStatus === "pending" &&
                userData?.collectorStatus === "decline" && (
                  <FlexContainer userAgencies color="rgba(254, 1, 16, 0.7)">
                    <Pointer color="#fff" />
                    <InfoValue userAgencies color="#fff">
                      Pending
                    </InfoValue>
                  </FlexContainer>
                )}

              {userData?.completionStatus === "completed" &&
                userData?.collectorStatus === "accept" && (
                  <FlexContainer userAgencies color="rgba(50, 68, 168, 1)">
                    <Pointer color="#fff" />
                    <InfoValue userAgencies color="#fff">
                      Completed
                    </InfoValue>
                  </FlexContainer>
                )}

              {userData?.completionStatus === "cancelled" &&
                userData?.collectorStatus === "accept" && (
                  <FlexContainer userAgencies color="rgba(254, 1, 16, 0.7)">
                    <Pointer color="#fff" />

                    <InfoValue userAgencies color="#fff">
                      Cancelled
                    </InfoValue>
                  </FlexContainer>
                )}

              {userData?.completionStatus === "cancelled" &&
                userData?.collectorStatus === "decline" && (
                  <FlexContainer userAgencies color="rgba(254, 1, 16, 0.7)">
                    <Pointer color="#fff" />

                    <InfoValue userAgencies color="#fff">
                      Cancelled
                    </InfoValue>
                  </FlexContainer>
                )}

              {userData?.completionStatus === "missed" &&
                userData?.collectorStatus === "accept" && (
                  <FlexContainer userAgencies color="rgba(254, 1, 16, 0.7)">
                    <Pointer color="#fff" />

                    <InfoValue userAgencies color="#fff">
                      Missed
                    </InfoValue>
                  </FlexContainer>
                )}
            </InfoItem>
          )}

          {missed && !cancelled && (
            <InfoItem>
              <InfoTitle>Scheduled Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.createdAt).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}

          {!completed || !dropOff || (
            <InfoItem>
              <InfoTitle>Accepted By:</InfoTitle>
              <InfoValue>{userData?.recycler}</InfoValue>
            </InfoItem>
          )}

          {accepted ||
            (totalusercompleted && (
              <InfoItem>
                <InfoTitle>Accepted By:</InfoTitle>
                <InfoValue>{userData?.recycler}</InfoValue>
              </InfoItem>
            ))}

          {completed && (
            <InfoItem>
              <InfoTitle>Completed By:</InfoTitle>
              <InfoValue>{userData?.recycler}</InfoValue>
            </InfoItem>
          )}
          {missed && (
            <InfoItem>
              <InfoTitle>Missed By:</InfoTitle>
              <InfoValue>{userData?.recycler}</InfoValue>
            </InfoItem>
          )}

          {!missed &&
            !cancelled &&
            !pending &&
            !partners &&
            !acceptedMaterials && (
              <InfoItem>
                <InfoTitle>Accepted by:</InfoTitle>
                <InfoValue>{userData?.scheduleCreator}</InfoValue>
              </InfoItem>
            )}
          {!missed &&
            !cancelled &&
            !pending &&
            !partners &&
            !acceptedMaterials && (
              <InfoItem>
                <InfoTitle>Collector's Number:</InfoTitle>
                <InfoValue>{userData?.collectedPhone}</InfoValue>
              </InfoItem>
            )}

          {/* {userData.acceptedDate ? (
            <InfoItem>
              <InfoTitle>Accepted Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.acceptedDate).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          ) : (
            ""
          )} */}

          {!completed &&
            !pending &&
            !accepted &&
            !cancelled &&
            !missed &&
            !partners &&
            !dropOff &&
            !acceptedMaterials && (
              <InfoItem>
                <InfoTitle>Completed Date:</InfoTitle>
                <InfoValue>
                  {moment(userData?.completionDate).format("YYYY-MM-DD")}
                </InfoValue>
              </InfoItem>
            )}
          {/* {!missed ||
            (!cancelled && (
              <InfoItem>
                <InfoTitle>Waste Collector:</InfoTitle>
                <InfoValue>{userData?.recycler}</InfoValue>
              </InfoItem>
            ))} */}
          {!completed &&
            !accepted &&
            !pending &&
            !dashboard &&
            !missed &&
            !dropOff &&
            !partners &&
            !totalusercompleted &&
            !acceptedMaterials && (
              <InfoItem>
                <InfoTitle>Cancelled By:</InfoTitle>
                <InfoValue>{userData?.scheduleCreator}</InfoValue>
              </InfoItem>
            )}
          {!completed &&
            !accepted &&
            !pending &&
            !dashboard &&
            !missed &&
            !dropOff &&
            !partners &&
            !totalusercompleted &&
            !acceptedMaterials && (
              <InfoItem>
                <InfoTitle>Cancelled Reason:</InfoTitle>
                <InfoValue>
                  {!userData?.cancelReason ? "N/A" : userData?.cancelReason}
                </InfoValue>
              </InfoItem>
            )}

          {cancelled && (
            <InfoItem>
              <InfoTitle>Cancelled Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.createdAt).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}



          {completed && (
            <InfoItem>
              <InfoTitle>Channel:</InfoTitle>
              {userData?.schedule?.channel}
            </InfoItem>
          )}

          {pending && !completed && (
            <InfoItem>
              <InfoTitle>Schedule Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.completionDate).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}

          {completed && (
            <InfoItem>
              <InfoTitle>Schedule Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.schedule?.createdAt).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}

          {missed && (
            <InfoItem>
              <InfoTitle>Reason:</InfoTitle>
              <InfoValue>
                {!userData?.cancelReason ? "N/A" : userData?.cancelReason}
              </InfoValue>
            </InfoItem>
          )}

          {totalusercompleted && (
            <InfoItem>
              <InfoTitle>Previous Balance:</InfoTitle>
              <InfoValue> {userData?.previousBalance}</InfoValue>
            </InfoItem>
          )}

          {totalusercompleted && (
            <InfoItem>
              <InfoTitle>New Balance:</InfoTitle>
              <InfoValue> {userData?.newBalance}</InfoValue>
            </InfoItem>
          )}

          {totalusercompleted && (
            <InfoItem>
              <InfoTitle>Amount Paid:</InfoTitle>
            </InfoItem>
          )}

          {totalusercompleted && (
            <InfoItem>
              <InfoTitle>Payment Date:</InfoTitle>
              <InfoValue>
                {moment(userData?.paymentDate).format("YYYY-MM-DD")}
              </InfoValue>
            </InfoItem>
          )}
        </InfoUserWrapper>
      )}
    </Modal>
  );
};

export default PickupModal;
