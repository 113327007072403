import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { truncate } from "../../utils/constants";
import CustomButton from "../UI/button";
import Modal from "../UI/modal";
import { ModalText, PageTitle } from "../styledElements";

const DeleteModal = ({
  showModal,
  setShowModal,
  title,
  type = "",
  name = "",
  redirectAfterDelete,
  locationId,
  handleDelete = () => null,
}) => {
  const deleteText = {
    role: "Are you sure you want to delete this role?",
    drop: "Are you sure you want to delete this drop-off location? You'll permanently lose all the data ",
    category: "Are you sure you want to delete this waste category",
    account: `Are you sure you want to delete ${name}?`,
    aggregator: "Are you sure you want to decline this approval",
    resource: "Are you sure you want delete this Resource",
    enable: "Are you sure you want to delete this aggregator",
    invoice: "Are you sure you want to delete this invoice",
    dropoff: "Are you sure you want to delete this drop-off location?",
  }[type];

  const [showPostAction, setPostAction] = useState(false);
  const history = useHistory();

  const deleteHandler = async () => {
    setShowModal(false);
    await handleDelete();
    setPostAction(true);
    if (redirectAfterDelete) {
      // history.push(`/admin/space_details/${locationId}`);
      setTimeout(() => {
        history.push(`/admin/space_details/${locationId}`);
      }, 1000);
    }
  };

  const { error } = useSelector((state) => state.app);

  return (
    <>
      <Modal
        show={showPostAction}
        close={() => {
          setPostAction(false);
        }}
        type="postAction"
        color={error && "#F5000F"}
      >
        <p>{!error ? `You have successfully deleted this ${type}` : error}</p>
      </Modal>

      <Modal show={showModal} close={() => setShowModal(false)}>
        <PageTitle>{` ${title === "" ? "Delete" : `Delete ${title}?`}`}</PageTitle>
        <ModalText>{deleteText}</ModalText>
        <div className="flex space-x-8 mt-4">
          <CustomButton
            text="Cancel"
            type="outlined"
            onClick={() => setShowModal(false)}
          />
          <CustomButton
            // text={` ${title === "" ? "Delete" : title} ${type}`}
            text={` Delete ${truncate(title, 9)} `}
            color="red"
            onClick={deleteHandler}
          />
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
