import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "./btn";
import FilterModal from "./FilterModal";


const FilterButton = styled.div`
  // ${tw`flex items-end justify-end`}
  // width: 100px;
  height: 40px;
  float: right;
  ${tw`mr-4`}
  // margin-bottom: 20px;

  button {
    font-size: 14px;
    padding: 3px 25px;
    transform: translateY(5px);
    font-weight: bold;
  }
  .iconStyle1 {
    display: inline-block;
    margin-left: 10px;
  }
`;

function Filter({ onFilter, title, icon, compTitle,comp, rangeValue, isYear, isMonth }) {
  const ref = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [filterTitle, setTitle] = useState(title)


  return (
    <FilterButton ref={ref}>
      <StyledButton
        buttonStyle="btn--gray--outline"
        buttonSize="btn--small"
        className="flex items-center gap-6 text-base relative"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {title? <span className="ml-[-8px]">{title}</span>  :'Filter'}
        {
          icon? 
          <span className="absolute right-1 ">{icon}</span>
           :
          <img
          src="/assets/images/sliders.svg"
          alt="filter-icon"
          className="iconStyle1"
        />
        }

      </StyledButton>

      {modalOpen && (
        <div className="relative">
          <div className="absolute right-0">
            <FilterModal isMonth={isMonth} isYear={isYear} comp={comp} title={compTitle} setModalOpen={setModalOpen} onFilter={onFilter} rangeValue={rangeValue} />
          </div>
        </div>
        
      )}
    </FilterButton>
  );
}

export default Filter;
