import React, { useState } from 'react'
import BankPayout from './BankPayout'
import CharityPayout from './CharityPayout'
import InsurancePurchase from './InsurancePurchase'

const PayoutView = ({match}) => {

    const items = [
        {
          key:'1',
          label:'Payout to Bank',
          children: <BankPayout match={match}/>
        },
        {
          key:'2',
          label:'Payout to Charity',
          children: <CharityPayout match={match}/>
        },
        {
          key:'3',
          label:'Insurance Purchase',
          children: <InsurancePurchase match={match}/>
        },
    ]

    const [activeTab, setActiveTab] = useState(items[0]);
  return (
    <>
        <div className="w-full flex items-center overflow-x-scroll lg:overflow-x-scroll pb-3 gap-4">
            {
            items?.map((i)=><button className={`transition-all ease-in-out duration-500 font-Rale font-[600] text-[16px]  px-3 rounded-lg py-[6px] border bg-[#F0F2F5] border-[#D0D5DD] text-[#7A828F] ${i.key === activeTab.key? 'text-[#005A00] border bg-[#D9E6D9] border-[#B0CCB0] ' : ''}`} onClick={()=>setActiveTab(i)} key={i.key}>{i.label}</button>)
            }
            
        </div>    

        <div>
            {activeTab?.children}
        </div>
    </>



  )
}

export default PayoutView
