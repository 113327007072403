import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ContentCard from "../../../../components/UI/ContentCard";
import Filter from "../../../../components/UI/Filter";
import Tabcontent from "../../../../components/UI/TabContent";
import StyledButton from "../../../../components/UI/btn";
import Modal from "../../../../components/UI/modal";
import Invoice from "../../../../components/adminFinancials/Invoice";
import PromptModal from "../../../../components/common/PromptModal";
import { FlexBetween } from "../../../../components/styledElements";
import {
	completeFinancialPayment,
	fetchFinancialCompleted,
	fetchFinancialOutstandings,
	fetchFinancialSummary,
} from "../../../../store/actions";
import { formatValue } from "../../../../utils";
import { truncate } from "../../../../utils/constants";
import { FinancialsCards, colors } from "../../../../utils/data";
import { LinkText } from "../../../TotalUser/TotalUser";
import { Link } from "react-router-dom";
import { fetchFinancialOrganizations, fetchFinancialSearch, fetchFinancialSummaryNew, fetchFinancialfilter } from "../../../../store/actions/financialsActions";

const WastePickerContainer = styled.div`
	display: grid;
	grid-template-coloumns: auto 1fr;
	gap: 20px;
`;

const PakamFinancials = () => {
	/****************************
	 *
	 * states and hooks
	 *
	 ****************************/
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableBody, setTableBody] = useState([]);
	const [outstandingPagination, setOustandingPagination] = useState();
	const [showPostModal, setPostModal] = useState(false);
	const [showPrompt, setPrompt] = useState(false);
	const [summaryData, setSummaryData] = useState();
	const [showReceiptModal, setReceiptModal] = useState();
	const [currentEntry, setCurrentEntry] = useState();

	const date = new Date();

	const dispatch = useDispatch();
	const {
		area: { allAreas },
		app: { error },
		auth: {
			userInfo: { claims },
		},
		location: { locations },
		financials: { completedPayments, outstandingPayments },
	} = useSelector((state) => state);
	const invoiceRef = useRef();

	/****************************
	 *
	 * handlers and utils functions
	 *
	 ****************************/

	const totalSummaryCards = FinancialsCards.map((el) => {
		return { ...el };
	});

	totalSummaryCards[0].amount = summaryData?.totalPayment || 0;
	totalSummaryCards[1].amount = summaryData?.totalOutStanding || 0;
	totalSummaryCards[2].amount = summaryData?.totalCompleted || 0;
	totalSummaryCards[3].amount = summaryData?.totalMaintanceFee || 0;

	// search and filter handler

	const d = new Date();
	d.setDate(d.getDate());
	const payload = {
		start: "2010-01-01",
		end: d,
	};

	const fetchOrganizations = async (page = 1) => {
		const res = await dispatch(
			fetchFinancialOrganizations({
				...payload,
				page,
			})
		);
		if (!res.error) {
			console.log(res)
			const { organizations, ...paginationData } = res.payload;
			setTableBody(organizations);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};


	const handleFilter = async (date, page = 1) => {
		const res = await dispatch(
			fetchFinancialfilter({
				...date,
				page,
			})
		);
		if (!res.error) {
			console.log(res)
			const { organizations, ...paginationData } = res.payload;
			setTableBody(organizations);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};
	const handleSearch = async (key, page = 1) => {
		console.log(key)
		const res = await dispatch(
			fetchFinancialSearch({
				...payload,
				page,
				key
			})
		);
		if (!res.error) {
			console.log(res)
			const { organizations, ...paginationData } = res.payload;
			setTableBody(organizations);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};

	// const handleCompletedSearch = async (key, page = 1) => {
	// 	const res = await dispatch(
	// 		fetchFinancialCompleted({
	// 			key,
	// 			page,
	// 		})
	// 	);
	// 	if (!res.error) {
	// 		const { invoices, ...paginationData } = res.payload.data;
	// 		setTableBody2(invoices);
	// 		setCompletedPagination({ ...paginationData, date });
	// 		setTotalPages(paginationData.totalPages);
	// 	}
	// };

	const fetchCardData = async () => {
		const data = await dispatch(fetchFinancialSummaryNew(payload));
		setSummaryData(data.payload);
	};

	// const completePayment = async (invoiceNumber) => {
	// 	const res = await dispatch(completeFinancialPayment(invoiceNumber));
	// 	if (!res.payload.error) onRefresh();
	// };


	const data = [
		{
			title: "",
			link: "Organizations",
			data: tableBody.map((entry) => ({
				...entry,
				organisation: entry.company?.companyName,
			})),
			filterHandler: handleFilter,
			searchHandler: handleSearch,
			fetch: fetchOrganizations,
			totalPages: outstandingPagination?.totalPages,
			paginationData: outstandingPagination,
			columns: [
				// {
				// 	title: "Invoice ID",
				// 	dataIndex: "invoiceNumber",
				// 	key: "invoiceNumber",
				// 	render: (text) => <p>{truncate(text, 30)}</p>,
				// },
				{
					title: "Organisation",
					dataIndex: "companyName",
					key: "companyName",
					render: (text, record) => <Link     
					to={{
					//   pathname: `/admin/financials/transaction-history/`,
					  pathname: `/admin/financials/transaction-history/${record._id}`,
					  state: { renewal: false },
					  // id: record.key
					}}
				  ><LinkText title={text}/></Link>
				},
				{
					title: <span>Income from Organization</span>,
					dataIndex: "organisationIncome",
					key: "organisationIncome",
				},
				{
					title: <span>Transaction Point</span>,
					dataIndex: "transactionPoint",
					key: "transactionPoint",
				},
				{
					title: <span>Pakam's Commission</span>,
					dataIndex: "totalPercentage",
					key: "totalPercentage",
				},
				// {
				// 	title: "Invoice",
				// 	dataIndex: "action",
				// 	key: "action",
				// 	render: (text, record) => {
				// 		return (
				// 			<Space size="middle">
				// 				<StyledButton
				// 					buttonStyle="btn--primary--outline"
				// 					buttonSize="btn--small"
				// 					onClick={() => {
				// 						setCurrentEntry(record);
				// 						setReceiptModal(true);
				// 					}}
				// 				>
				// 					View
				// 				</StyledButton>
				// 			</Space>
				// 		);
				// 	},
				// },
				// {
				// 	title: "Mark as paid",
				// 	dataIndex: "paid",
				// 	key: "action",
				// 	render: (text, record) => {
				// 		return (
				// 			<>
				// 				<Space size="middle">
				// 					<StyledButton
				// 						type=""
				// 						buttonSize="btn--small"
				// 						onClick={() => {
				// 							setCurrentEntry(record);
				// 							setPrompt(true);
				// 						}}
				// 					>
				// 						Mark
				// 					</StyledButton>
				// 				</Space>
				// 			</>
				// 		);
				// 	},
				// },
			],
		},
	];

	/****************************
	 *
	 * lifecycle hooks
	 *
	 ****************************/

	useEffect(() => {
		onRefresh();
	}, []);


	const onRefresh = () => {
		fetchCardData();
		fetchOrganizations()
	};

	return (
		<>
			{/* <PromptModal
				handler={() => completePayment(currentEntry.invoiceNumber)}
				showModal={showPrompt}
				setShowModal={setPrompt}
				successMessage="Invoice has been marked as paid!"
				promptMessage="Are you sure you want to mark this invoice as paid?"
				buttonText="Mark"
				title={"Mark as paid"}
			/> */}
			<Modal
				show={showReceiptModal}
				close={() => setReceiptModal(false)}
				width="85em"
			>
				<FlexBetween className="mb-7">
					<p className="text-xl font-semibold">Completed Schedules Invoices</p>

					<StyledButton
						raffle
						buttonStyle="btn--primary--outline"
						buttonSize="btn--small"
						className="text-black"
					>
						<a
							className="text-black"
							href={`https://api-v2.pakam.ng/api/v2/generate/invoicepdf/${currentEntry?.invoiceNumber}`}

						>
							Capture as PDF
						</a>
					</StyledButton>
				</FlexBetween>
				<Invoice invoiceId={currentEntry?._id} ref={invoiceRef} />
			</Modal>

			<Modal
				color={error ? "red" : "#005700"}
				type="postAction"
				show={showPostModal}
				close={() => setPostModal(false)}
			>
				{!error ? "Waste Picker assigned successfully" : error}
			</Modal>




			<div className="flex flex-col gap-3">


				<WastePickerContainer>


					<Tabcontent
						data={data}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						onRefresh={onRefresh}
					/>
				</WastePickerContainer>
			</div>
		</>
	);
};

export default PakamFinancials;


