import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
// import DataTable from "../../components/UI/Table";
import { Tag, Space, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  filterAdminWaste,
  getAdminWaste,
  getAdminWasteStats,
  searchAdminWaste,
} from "../../../../store/actions";
import moment from "moment";
import BarChart from "../../../../components/UI/BarChart";
import { formatAmount, formatValue } from "../../../../utils";
import Histogram from "../../../../components/UI/Histogram";
import { fetchHouseholdChart, fetchHouseholdChartFilter, fetchHouseholdData } from "../../../../store/actions/financialsActions";
import { PointCard } from "../..";
import Filter from "../../../../components/UI/Filter";
import { data } from "autoprefixer";

const TotalWasteContainer = styled.div`
  ${tw`flex flex-col space-y-6 `}
`;
const BarChartHeader = styled.div`
  ${tw`flex items-center justify-between py-3`}
  border-bottom: 1px solid rgba(194, 194, 194, 0.4);
`;
const BarChartTitle = styled.h3`
  ${tw`text-xl font-extrabold`}
  letter-spacing:2;
`;
const BarChartContainer = styled.div`
  ${tw`bg-white `}
  padding: 3rem 1rem 0;
`;
const StatsBody = styled.div`
  ${tw`bg-white text-center text-secondary text-lg`}
  padding: 4rem 1rem;
`;

const HouseHold = () => {
  const [paginationData, setPaginationData] = useState();
  const [bodyData, setBodyData] = useState();
  const date = new Date();
  const [summaryData, setSummaryData] = useState();
  const dispatch = useDispatch()
  

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2024-01-01",
    end: d,
  };

  const[year, setYear] = useState(d.getFullYear())
  

  const getChartData = async ( page = 1) => {
    const res = await dispatch(
      fetchHouseholdChart({
        year,
        page,
      })
    );
    if (!res.error) {
      const {combinedData, houseHoldSummary} = res.payload
      setBodyData(combinedData);
    }
  };
  const getChartFilter = async (selectedyear, page = 1) => {
    setYear(selectedyear)
    const res = await dispatch(
      fetchHouseholdChart({
        year: selectedyear,
        page,
      })
    );
    if (!res.error) {
      const {combinedData, houseHoldSummary} = res.payload
      setBodyData(combinedData);
    }
  };

  const fetchHoseholdSummaryData = async (date, page = 1) => {

		const res = await dispatch(
			fetchHouseholdData({
				...payload,
				page,
			})
		);
		if (!res.error) {
      const {payload} = res
      console.log(payload)
      setSummaryData(payload)
		}
	};

  const handleChartDataFilter = async (date, page = 1) => {

		const res = await dispatch(
			fetchHouseholdData({
				...date,
				page,
			})
		);
		if (!res.error) {
      console.log(res)
      const {payload} = res
      setSummaryData(payload)
		}
	};

  useEffect(()=>{
    getChartData()
    fetchHoseholdSummaryData()
  }, [])


  const cardData = [
		{
		  title:"Users’ Wallet Balance",
		  amount: summaryData?.walletBalance || 0,
		  icon:'/assets/images/f1.svg'
		},	
		{
		  title:'Amount Withdrawn to Bank',
		  amount:summaryData?.disbursementBalance || 0,
		  icon:'/assets/images/f2.svg'
		},	
		{
		  title:'Amount donated to Charity',
		  amount:summaryData?.charityBalance || 0,
		  icon:'/assets/images/f3.svg'
		},	
		{
		  title:"Pakam’s Revenue for Charity",
		  amount:summaryData?.charityRevenue || 0, 
		  icon:'/assets/images/f4.svg'
		  
		},	
	  
	  ]

    const bankTranx = bodyData?.map((i)=>i.disbursement)
    const charityTranx = bodyData?.map((i)=>i.charity)

    const bankData = {
      title: "Bank",
      data: bankTranx
    }
    const charityData = {
      title: "Charity",
      data: charityTranx
    }

  return (
    <TotalWasteContainer>

      <div className="flex justify-between w-full">
      
        <div>
          <h1 className=" font-Int text-[24px] font-[600]">Household Users</h1>
          <p className="text-[#667185] font-Int text-[14px] mt-[-20px]">Manage all household financial activities on Pakam</p>
        </div>

        <div className="">
          <Filter onFilter={handleChartDataFilter} />
        </div>

      </div>

      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container mt-2 mb-2">

        {
          cardData.map((i, id)=>{
            return(
            <PointCard key={id} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
            )
          })
        }
      </div>


      <BarChartContainer>
        <BarChartHeader>
          <BarChartTitle>Total User's Withdrawal</BarChartTitle>
          <div className="">
            <Filter isYear={true} onFilter={getChartFilter} />
          </div>
          
          {/* <RangePicker
            dateRender={(current) => {
              const style = {};
              if (current.date() === 1) {
                style.border = "1px solid #1890ff";
                style.borderRadius = "50%";
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          /> */}
        </BarChartHeader>
        <hr className="py-4" />
        {!bodyData || (Math.max(...bankData?.data) < 1 &&  Math.max(...charityData?.data) < 1) ? (
          <StatsBody>
            No Statistics Available for the year of {year}
          </StatsBody>
        ) : (
          <Histogram data1={bankData} data2={charityData}/>
        )}
      </BarChartContainer>

      {/* <DataTable
        data={bodyData || []}
        columns={columns}
        header
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        onSearch={onSearch}
        onFilter={onFilter}
        onFetch={fetchAll}
        onRefresh={onRefresh}
        paginationData={paginationData}
      /> */}
    </TotalWasteContainer>
  );
};

export default HouseHold;


