import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import TransationTable from "./Table";
import { UserContainer, NavBarLeft } from "../UserDetails";
import BreadCrumb from "../../../components/UI/breadCrumbs";
import { userDetail } from "../../../store/actions";
import { useDispatch } from "react-redux";


export const PayoutBreakdown = () => {
  const location = useLocation();
  const state = location;
  const currentPage = state.state.requestType;
  const id = location.pathname.split("/")[3];
  const [userData, setUserData] = useState();

  const pages = [{ name: "Total Users", link: "/admin/total_users" }];
  const previous = [
    { name: userData?.firstName, link: `/admin/mobile_user_details/${id}` },
  ];

  const dispatch = useDispatch();
  const fetchuser = async () => {
    const res = await dispatch(userDetail(id));
    if (!res.error) {
      const { data } = res.payload;
      // console.log(data)
      setUserData(data);
    }
  };
  
  useEffect(() => {
    fetchuser();
  }, []);

  return (
    <div>
      <UserContainer>
        <NavBarLeft>
          <BreadCrumb pages={pages} current={currentPage} previous={previous} />
        </NavBarLeft>
      </UserContainer>
      <TransationTable state={state} />
    </div>
  );
};
