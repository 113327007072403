import React, { useEffect, useReducer, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import styled from "styled-components";
import tw from "twin.macro";
import { DropdownContainer as DC, OptionTitle as OT } from "../styledElements";
import Checkbox from "./Checkbox";
import StyledButton from "./btn";
import Button from "./button";
import SearchInput from "./searchInput";
import Modal from "./modal";
import SetupAccordion from "./SetupAccordion";

const DropdownContainer = styled(DC)`
  ${tw`left-[-90%] space-y-6 p-5 max-h-[auto]`};
  max-height: 100vh;
`;

const OptionTitle = styled(OT)`
  ${tw`font-bold text-black leading-[24px]`}
`;

const Search = styled(SearchInput)`
  ${tw`w-full`}
`;

export const OptionItem = styled.span`
  ${tw`text-sm text-body cursor-pointer py-1 pl-2`}
`;

const initOptions = [];

function CategorySelect({
  existingCategories = [],
  options = initOptions,
  categories = [],
  optionIdxs = null,
  label,
  setCategories = () => null,
  ...props
}) {
  const [currentVal, setCurrentVal] = useState(categories);
  const [currentIndex, setCurrentIndex] = useState([]);
  const [showDrop, toggle] = useReducer((init, state) => !state, false);
  const [availOptions, setOptions] = useState(options);
  const [loading, setLoading] =useState(false)
  const catList = options.map((i)=>i.items)
  const subcatList =catList.flat()
  const [show, setShow] = useState(false)


  // console.log(existingCategories)


  // useEffect(() => {
  //   if (existingCategories) {
  //     const keys = Object.keys(existingCategories);
  //     const category = subcatList.filter((i)=> keys.includes(i.value))
  //     const existingCatList = Object.values(existingCategories)
  //     const updatedCategory = category.map(obj => ({ ...obj, price: existingCatList.find((i)=>i.label === obj.value)?.value  }));
  //     setCurrentVal(updatedCategory)
  //   }
  // }, [existingCategories]); 

  useEffect(() => {
    function handleFetchCat() {
      if (existingCategories && Object.keys(existingCategories).length > 0) {
        const keys = Object.keys(existingCategories);

        let category
        if (keys[0].includes('-')){
          category = subcatList.filter((i)=> keys.includes(i.value))
        }else{
          category = subcatList.filter((i)=> keys.includes(i.name))
        }

        const existingCatList = Object.values(existingCategories)
        const updatedCategory = category.map(obj => ({ ...obj, price: existingCatList.find((i)=>i.label === obj.value)?.value  }));

        setCurrentVal(updatedCategory)
      }
    }
  
    const timeoutId = setTimeout(handleFetchCat, 5000);
  
    return () => clearTimeout(timeoutId);
  }, [existingCategories]);
  
  useEffect(() => {
    function handleLoading() {
      setLoading(true);
    }
    const timeoutId = setTimeout(handleLoading, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  

  useEffect(()=>{
    if(currentVal && currentVal.length > 0){
      const idx = currentVal.map((i)=>i._id)

      setCurrentIndex(idx)
    }
  },[currentVal])

  useEffect(() => {
    setOptions(options);
  }, [options]);

  useEffect(() => {
    if (optionIdxs)
      setCurrentVal(
        optionIdxs?.filter((v) => v > -1).map((idx) => subcatList[idx]) || []
      );
  }, [optionIdxs]); 

  const changeValue = (name, id, value, wastepicker) => {
    const existingIndex = currentVal.findIndex((item) => item.value === value)
    // setCurrentVal([...currentVal, { name, value }]);

    if (existingIndex !== -1) {
      // If the item already exists in currentVal, remove it
      
      setCurrentVal(currentVal.filter((item) => item._id !== id));
      setCurrentIndex(currentIndex.filter((item)=>item._id !== id))
      // setCurrentIndex(availOptions?.item.filter((item) => item._id !== id));
    } else {
      // If the item doesn't exist in currentVal, add it
      
      const newItem = subcatList.find((i)=>i._id === id)

      setCurrentVal([...currentVal, newItem]);
    }
  };
  

  return (
    <div className="">
      <Button type="outline" height="40px" 
      // onClick={() => toggle(showDrop)}
      onClick={() => setShow(true)}
      >
        <FiPlusCircle size="22" className="mr-2" />
        Add Category
      </Button>

      <Modal show={show} close={()=>setShow(false)} >
          <div className="w-full flex justify-between items-center">
            <OptionTitle>Select Waste Category</OptionTitle>

            <Search
              placeholder="Search for categories"
              onChange={(e) => {
                const ops = options.filter(
                  (op) =>
                    op.text.toUpperCase().indexOf(e.target.value.toUpperCase()) >
                    -1
                );
                setOptions(ops);
              }}
            />            
          </div>

          {
            loading && <SetupAccordion indexes={currentIndex} onItemClick={changeValue} items={availOptions}/>
          }

          

          {/* {availOptions.map(({ value, text, id }) => (
            <OptionItem key={id || value} value={value}>
              <Checkbox
                key={id}
                label={text}
                textType="light"
                onChange={(e) => changeValue(e, { value, text })}
                defaultChecked={
                  currentVal.findIndex((v) => v.value === value) > -1
                }
              />
            </OptionItem>
          ))} */}
          <div className="flex justify-center w-full mt-4">
            <StyledButton
              onClick={() => {
                setCategories(currentVal);
                toggle(showDrop);
                setShow(false)
              }}
              buttonSize={"btn--medium"}
              btnClass={'w-[80%]'}
            >
              Confirm
            </StyledButton>            
          </div>

      </Modal>

      {/* {showDrop && (
        <DropdownContainer width="20rem" {...props}>
          <span className="w-full flex justify-between items-center">
            <OptionTitle>Waste Category</OptionTitle>
          </span>

          {availOptions.map(({ value, text, id }) => (
            <OptionItem key={id || value} value={value}>
              <Checkbox
                key={id}
                label={text}
                textType="light"
                onChange={(e) => changeValue(e, { value, text })}
                defaultChecked={
                  currentVal.findIndex((v) => v.value === value) > -1
                }
              />
            </OptionItem>
          ))}
          <StyledButton
            onClick={() => {
              setCategories(currentVal);
              toggle(showDrop);
            }}
            buttonSize={"btn--medium"}
          >
            confirm
          </StyledButton>
        </DropdownContainer>
      )} */}
    </div>
  );
}

export default CategorySelect;
