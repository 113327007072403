import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../components/UI/btn";
import ResourcesCard from "../../components/UI/Resources/ResourcesCard";
import {  getCategory, getResources } from "../../store/actions";
import UploadResourceModal from "../../components/UI/Resources/UploadResourceModal";
// import PaginationBars from "../../components/UI/Paginationbars";
import { PaginationBars } from "../../components/UI/Resources/Pagination";
import DataTable from "../../components/UI/Table";
import moment from "moment";
import AreaFilter from "../../components/UI/AreaFilter";
import { IoChevronDownSharp } from "react-icons/io5";
import Filter from "../../components/UI/Filter";
import DateRangeFilter from "../../components/UI/DateRangeFilter";
import DropDownFilter from "../../components/UI/DropDownFilter";
import { Popover, Tag } from "antd";
import { truncate } from "../../utils/constants";
import BarChart from "../../components/UI/BarChart";
import ExportFilter from "../../components/UI/ExportFilter";
import { fetchResourceByWasteCollected, getAllWasteDetails, getOrgWasteDetails, getResourceOrganisations } from "../../store/actions/resourceActions";
import { formatSelectOptions } from "../../utils";
import NewBarChart from "../../components/UI/NewBarChart";

const ResourceSummaryContainer = styled.div`
    ${tw`bg-white rounded-sm grid grid-cols-2 md:grid-cols-4`}
  // display: grid;
  // gap: 20px;
`;
const ResourceHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;
const ResourceList = styled.div`
  ${tw`bg-white`}
`;
const BarChartHeader = styled.div`
  ${tw`flex  justify-between py-3`}
  border-bottom: 1px solid rgba(194, 194, 194, 0.4);
`;
const BarChartTitle = styled.h3`
  ${tw`text-xl font-extrabold`}
  letter-spacing:2;
`;
const BarChartContainer = styled.div`
  ${tw`bg-white `}
  padding: 3rem 1rem 0;
`;
const StatsBody = styled.div`
  ${tw`bg-white text-center text-secondary text-lg`}
  padding: 4rem 1rem;
`;

const WasteCollected = ({match}) => {
  const dispatch = useDispatch()

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2024-01-01",
    end: d,
  };
  const year = d.getFullYear()
  const [currentYear, setCurrentYear] = useState(year);
  const [tablePagination, setTablePagination] = useState();
  const [org, setOrg] = useState()
  const [cat, setCat] = useState()
  const [bodyData, setBodyData] = useState();
  const [activeBtn, setActiveBtn] = useState('location');
  const [organisations, setOrganisatons] = useState([])
  const [catList, setCatList] = useState([])
  const [subcatList, setSubCatList] = useState([])
  const [statsBodyData, setStats] = useState([]);
  const [chartData, setChartData] = useState({
    title: '',
    data: []
  });
  const current = moment(new Date()).format("MMMM");
  


  const onActivityFilter =() =>{

  }

  const populate = (i)=>{
		if (!i.error) {
      const {payload} = i
      setStats(payload)
      const q = Object.keys(payload)
      const data = payload[q[0]]
      const d = Object.values(data)
      setChartData({
        title: q,
        data:d,
        barColor: colors[0]
      })


		}
  }


  const filterByYear = async (dyear, page = 1) => {

    setCurrentYear(dyear)
    setChartData({
      title: '',
      data: []
    })
		const res = await dispatch(
			fetchResourceByWasteCollected({
				category: cat,
        id : org,
        year: dyear
			})
		);
    populate(res)
	};


  const filterByWasteCollected = async (category, page = 1) => {

    setCat(category)
    setChartData({
      title: '',
      data: []
    })
		const res = await dispatch(
			fetchResourceByWasteCollected({
				category,
        id : org,
        year:currentYear
			})
		);
    populate(res)
	};

  const fetchWasteDetails = async ( page = 1) => {

		const res = await dispatch(
			getOrgWasteDetails({
        ...payload,
        page
			})
		);
    if (!res.error) {
			const { results, ...paginationData  } = res.payload.data;
      setBodyData(results)      
			setTablePagination({ ...paginationData, date: payload });
    }
    
	};

  const filterWasteDetails = async ( date, page = 1) => {

		const res = await dispatch(
			getOrgWasteDetails({
        ...date,
        page
			})
		);
    if (!res.error) {
      console.log(res)
			const { results, ...paginationData  } = res.payload.data;
      setBodyData(results)    
      console.log(paginationData)  
			setTablePagination({ ...paginationData, date: payload });

    }
    
	};

  const filterByOrganization = async (orgId, page = 1) => {
    console.log(orgId)
    setOrg(orgId)
    if(cat){
      setChartData({
        title: '',
        data: []
      })
      const res = await dispatch(
        fetchResourceByWasteCollected({
          category : cat,
          id:orgId,
          year:currentYear
        })
      );
      populate(res)    
    }

	};

    const fetchOrgs = async () => {
      const res = await dispatch(getResourceOrganisations());
      if (!res.error) {
        const orgOptions = formatSelectOptions(
          res.payload,
          "companyName",
          "_id"
        );
        setOrganisatons(orgOptions);
      }
    };

    const fetchCategories = async () => {
      const res = await dispatch(getCategory());
      if (!res.error) {

        const orgOptions = formatSelectOptions(
          res.payload.data,
          "name",
          "name"
        );
        setCatList(orgOptions);
      }
    };

  useEffect(() => {
    fetchOrgs()
    fetchCategories()
    fetchWasteDetails()
  }, []);


  const handleSetChartData = (q, color) =>{
    const data = statsBodyData[q]
    const d = Object.values(data)
    setChartData({
      title: q,
      data:d,
      barColor: color
    })
    
  }


  const columns = [
    {
      title: "Organization",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Total Waste collected (KG)",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
    },

  ];


  return (
    <div className="space-y-6">

      <BarChartContainer>
        <BarChartHeader>
          <div>
            <BarChartTitle>Waste collected by Organizations</BarChartTitle>
            <BarChartTitle>{currentYear}</BarChartTitle>
          </div>
          
          {/* <RangePicker
            dateRender={(current) => {
              const style = {};
              if (current.date() === 1) {
                style.border = "1px solid #1890ff";
                style.borderRadius = "50%";
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          /> */}

          <div>
            <div className="flex">
              {/* <Filter comp={<DateRangeFilter onFilter={onActivityFilter} />} icon={<IoChevronDownSharp/>} compTitle={"User's Activity"} title={'Date Range'} /> */}
              
              
              {
                cat && org && <Filter isYear={true} onFilter={filterByYear}  title={'Date Range'}/>
              }
              {
                cat && <Filter comp={<DropDownFilter list ={catList} onFilter={filterByWasteCollected} />}  icon={<IoChevronDownSharp/>} compTitle={"Waste Category"} title={'Categories'} />
              }
                            {
                org && <Filter comp={<DropDownFilter list={organisations} onFilter={filterByOrganization} />}  icon={<IoChevronDownSharp/>} compTitle={"Select Organizations"} title={'Organizations'} />
              }
              
              
              {/* <Filter comp={<ExportFilter onFilter={onActivityFilter} />} icon={<IoChevronDownSharp/>} compTitle={"Export As"} title={'Export'} /> */}

            </div>

            <div className="flex justify-end pr-6">
               <span className="text-[12px]">{(organisations?.find((i)=> i.value === org))?.text} - {cat} - {currentYear}</span>  
            </div>    

                
          </div>


        </BarChartHeader>
        <hr className="py-4" />
        {
        !org ? 
        (
          <StatsBody>
          Select an Organization to Get Started
          <div className="w-full flex justify-center">
          <Filter comp={<DropDownFilter list={organisations} onFilter={filterByOrganization} />}  icon={<IoChevronDownSharp/>} compTitle={"Select Organizations"} title={'Organizations'} />
          </div>
        </StatsBody>
        )
        :
        !cat ? 
        (
          <StatsBody>
          Select a Category
          <div className="w-full flex justify-center">
          <Filter comp={<DropDownFilter list ={catList} onFilter={filterByWasteCollected} />}  icon={<IoChevronDownSharp/>} compTitle={"Waste Category"} title={'Categories'} />
          </div>
        </StatsBody>
        )
        :

        <div>
            <div className="flex gap-3 mb-20">
              {
                Object.keys(statsBodyData)?.map((i,id)=>
                  <div key={id} className="flex items-center gap-1" onClick={()=>handleSetChartData(i, colors[id])}> <div style={{backgroundColor:colors[id]}} className="w-[30px] h-[20px] rounded-sm inline-flex"></div> <span>{i}</span></div>
                )
            }
            </div>

            {
              (Math.max(...chartData?.data) < 1) ? (
                <StatsBody>
                  No Statistics Available for the year of {currentYear}
                </StatsBody>
              ) : (
                <NewBarChart data1={chartData}/> 

              )              
            }
              

        </div>

}
      </BarChartContainer>

      <DataTable
        data={bodyData || []}
        date="welcome"
        // setCurrentPage={setCurrentPage}
        // onSearch={onSearch}
        onFilter={filterWasteDetails}
        onRefresh={fetchWasteDetails}
        columns={columns}
        // refreshUrl="drop-off"
        header
        paginationData={tablePagination}
        totalPages={tablePagination?.totalPages}
      />

    </div>
  )
};

export default WasteCollected;

export const colors =['#0F973D', '#E784D7', '#BBC83B', '#A79AC3', '#51A184', '#3B82F6', '#F97316']