import baseAxios from "../core/api/axios/baseAxios";

export default class WasteCategoryService {
  /****************************
   *
   * getter services
   *
   ****************************/
  static async getCategory() {
    const res = await baseAxios.get("/category/all");
    return res?.data || res;
  }

  /****************************
   *
   * creator services
   *
   ****************************/
  static async createCategory(data) {
    const res = await baseAxios.post("/category/add", data);
    return res?.data || res;
  }
  /****************************
   *
   * creator services
   *
   ****************************/
  static async createSubCategory(data) {

    const res = await baseAxios.post("/category/main", data);
    return res?.data || res;
  }

  /****************************
   *
   * UPDATE CATERGORY
   *
   ****************************/
  static async updateCategory(catId, data) {
    const res = await baseAxios.put(`/category/${catId}`, data);
    return res?.data || res;
  }

  static async updateSubCategory(catId, data) {
    const res = await baseAxios.put(`/category/${catId}`, data);
    return res?.data || res;
  }

  /****************************
   *
   * delete services
   *
   ****************************/
  static async deleteCategory(categoryId) {
    const res = await baseAxios.delete(`/category/${categoryId}`);
    return res?.data || res;
  }
  static async deleteSubCategory(categoryId) {
    const res = await baseAxios.delete(`/category/${categoryId}`);
    return res?.data || res;
  }
}
