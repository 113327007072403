import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ContentCard from "../../../../components/UI/ContentCard";
import { DisplayModal } from "../../../../components/UI/DisplayModal";
import PickupModal from "../../../../components/UI/PickupModal";
import DataTable from "../../../../components/UI/Table";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import StyledButton from "../../../../components/UI/btn";
import Button from "../../../../components/UI/button";
import Modal from "../../../../components/UI/modal";
import { FlexBetween } from "../../../../components/styledElements";
import {
	markPaid,
	partnersDetails,
	partnersFilter,
	partnersSearch,
} from "../../../../store/actions";
import { PartnersCards, colors } from "../../../../utils/data";
import { NavBarLeft, UserContainer } from "../../../TotalUser/UserDetails";

const PartnersDetailsContainer = styled.div`
	display: grid;
	grid-template-coloumns: auto 1fr;
	gap: 20px;
`;

const PartnersDetails = ({ match }) => {
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [showReceiptModal, setReceiptModal] = useState();
	const [currentEntry, setCurrentEntry] = useState();
	const [tablebody, setTableBody] = useState();
	const [paginationData, setPaginationData] = useState();
	const [showModal, setShowModal] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [message, setMessage] = useState();
	const [userData, setUserData] = useState([]);

	const dispatch = useDispatch();
	const {
		params: { id },
	} = match;

	const {
		state: { name },
	} = useLocation();

	const totalSummaryCards = PartnersCards.map((el) => {
		return { ...el };
	});

	const renderCardValue = (key, index) => {
		return (
			tablebody?.summations && Object.entries(tablebody?.summations)[key][index]
		);
	};

	totalSummaryCards[0].amount = renderCardValue(0, 1);
	totalSummaryCards[1].amount = renderCardValue(1, 1);

	const d = new Date();
	d.setDate(d.getDate());
	const payload = {
		start: "2010-01-01",
		end: d,
	};

	const pages = [{ name: "Partners", link: "/admin/financials" }];

	const fetchAll = async (page = 1) => {
		const res = await dispatch(partnersDetails({ id: id, page: page }));
		if (!res.error) {
			const { data } = res.payload.data;
			const { ...paginationData } = res.payload.data.data;
			setTableBody(data);
			setPaginationData({ ...paginationData, date: payload });
			setTotalPages(paginationData?.totalPages);
		}
	};

	const onSearch = async (key) => {
		const res = await dispatch(partnersSearch({ id: id, page: 1, key: key }));
		if (!res.error) {
			const { data, ...paginationData } = res.payload.data;
			setTableBody(data);
			setPaginationData({ ...paginationData, date: payload });
			setTotalPages(paginationData?.totalPages);
		}
	};

	const onFilter = async (date, page) => {
		const res = await dispatch(
			partnersFilter({
				id: id,
				page,
				currentMonth: date,
			})
		);

		if (!res.error) {
			const { data, ...paginationData } = res.payload.data.data;
			setTableBody(res.payload.data.data);
			setPaginationData({ ...paginationData, date });
			setTotalPages(paginationData?.totalPages);
		}
	};

	useEffect(() => {
		fetchAll();
	}, []);

	const onRefresh = () => {
		fetchAll();
	};
	useEffect(() => {
		onRefresh();
	}, []);

	const excludedFields = ["_id", "bankName", "isPaid"];

	const renderCreatedAt = (text) => {
		return <p>{moment(text).format("YYYY-MM-DD")}</p>;
	};

	const renderStatus = (text) => {
		let className = "";
		if (text === "successful") {
			className = "text-white bg-secondary rounded-sm p-2";
		} else if (text === "failed" ||  text === "cancelled") {
			className = "text-red-400 bg-red-200";
		} else {
			className = "text-blue-400 bg-blue-200";
		}
		return (
			<p className={`${className} font-bold rounded-lg px-4 py-[2px] w-fit`}>
				{text}
			</p>
		);
	};

	const renderButton = (text, id, isPaid, record) => {
		let handler;

		if (isPaid === true) {
			text = "Paid";
		}
		if (isPaid === false) {
			text = "Mark As Paid";
			handler = async () => {
				const res = await dispatch(markPaid({ id: id }));
				if (!res.error) {
					setMessage(res?.payload.data.message);
					setDisplayModal(true);
					fetchAll();
				}
			};
		} else {
			handler = () => {
				setUserData(record);
				setShowModal(true);
			};
		}
		return (
			<Button type="" onClick={handler} disabled={isPaid}>
				{text}
			</Button>
		);
	};

	const columns =
		tablebody?.tableColumns &&
		Object.entries(tablebody?.tableColumns)
			?.filter(([dataIndex]) => !excludedFields.includes(dataIndex))
			?.map(([dataIndex, title]) => {
				let renderFunction = null;

				if (dataIndex === "createdAt") {
					renderFunction = renderCreatedAt;
				} else if (dataIndex === "status") {
					renderFunction = renderStatus;
				}

				return {
					title: title,
					dataIndex: dataIndex,
					key: dataIndex,
					render: renderFunction,
				};
			});

	const actionColumn = tablebody?.actions?.map((data, index) => {
		return {
			title: "Action",
			dataIndex: data,
			key: data,
			render: (text, record) => {
				return renderButton(data, record?._id, record?.isPaid, record);
			},
		};
	});
	const tableColumn = columns?.concat(actionColumn);

	return (
		<>
			<UserContainer>
				<NavBarLeft>
					<BreadCrumb pages={pages} current={name} />
				</NavBarLeft>
			</UserContainer>

			<PickupModal
				showModal={showModal}
				setShowModal={setShowModal}
				userData={userData}
				partners
				// dashboard
			/>
			<DisplayModal
				showModal={displayModal}
				setShowModal={setDisplayModal}
				message={message}
			/>

			<Modal
				show={showReceiptModal}
				close={() => setReceiptModal(false)}
				width="85em"
			>
				<FlexBetween className="mb-7">
					<StyledButton
						raffle
						buttonStyle="btn--primary--outline"
						buttonSize="btn--small"
						className="text-black"
					>
						<a
							className="text-black"
							href={`https://api-v2.pakam.ng/api/v2/invoice/generate/invoicepdf/${currentEntry?.invoiceNumber}`}
						>
							Capture as PDF
						</a>
					</StyledButton>
				</FlexBetween>
			</Modal>
      <Modal
        show={showReceiptModal}
        close={() => setReceiptModal(false)}
        width="85em"
      >
        <FlexBetween className="mb-7">
          <StyledButton
            raffle
            buttonStyle="btn--primary--outline"
            buttonSize="btn--small"
            className="text-black"
          >
            <a
              className="text-black"
              href={`https://api-v2.pakam.ng/api/v2/invoice/generate/invoicepdf/${currentEntry?.invoiceNumber}`}
            >
              Capture as PDF
            </a>
          </StyledButton>
        </FlexBetween>
      </Modal>

			<div className="flex flex-col gap-3">
				<div className="grid grid-cols-2 gap-4 container mt-5 mb-5 w-full lg:w-1/2">
					{totalSummaryCards?.map((el, i) => {
						return (
							<ContentCard
								ImgUrl={el.icon}
								title={el.title}
								amount={<span>&#8358;{el.amount}</span>}
								style={{ color: colors[i] }}
								key={i}
							/>
						);
					})}
				</div>

				<PartnersDetailsContainer>
					<DataTable
						data={(tablebody && tablebody.data) || []}
						columns={tableColumn}
						refreshUrl="drop-off"
						header
						onSearch={onSearch}
						onFilter={onFilter}
						onRefresh={onRefresh}
						setCurrentPage={setCurrentPage}
						paginationData={paginationData}
						totalPages={paginationData?.totalPages}
						onFetch={fetchAll}
					/>
				</PartnersDetailsContainer>
			</div>
		</>
	);
};

export default PartnersDetails;
