import baseAxios from "../core/api/axios/baseAxios";

export default class PayoutService {
  /****************************
   *
   * payout services
   * "/v2/getuser-disbursement?start=2010-01-01&end=Tue May 07 2024 10:42:10 GMT+0100 (West Africa Standard Time)&page=1&resultsPerPage=10&userId=65115863136af400215ea402&status=failed"

   *
   ****************************/

  static async payoutPending(currentMonth, id, page, status) {
    const res = await baseAxios.get(
      `v2/getuser-disbursement?start=${currentMonth.start}&end=${currentMonth.start}&page=${page}&resultsPerPage=10&userId=${id}&status=${status}`
      // `/user/payout_requests/?start=&end=&page=&status=`
    );
    return res?.data || res;
  }

  static async AllPayoutSearch(id, key, page, status) {
    const res = await baseAxios.get(
      `/user/payout_requests/${id}?key=${key}&page=${page}&status=${status}`
    );
    return res?.data || res;
  }

  static async payoutCharity(currentMonth, id, page) {
    const res = await baseAxios.get(
      `/v2/getuser-charity?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&resultsPerPage=10&userId=${id}`
    );
    console.log(res)
    return res?.data || res;
  }

  static async payoutSearchCharity(id, key, page) {
    const res = await baseAxios.get(
      `/user/charity/payout_requests/${id}?key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  static async insurancePurchases(currentMonth, page, id) {
    const res = await baseAxios.get(
      `/v2/getuser-insurance?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&resultsPerPage=10&userId=${id}`
      // `/user/insurance?start=${currentMonth.start}&end=`
      
    );
    return res?.data || res;
  }

  static async insuranceSearchPurchases(key, page) {
    const res = await baseAxios.get(`/user/insurance?key=${key}&page=${page}`);
    return res?.data || res;
  }
}
