import { Popover, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PickupModal from "../../../../components/UI/PickupModal";
import DataTable from "../../../../components/UI/Table";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import StyledButton from "../../../../components/UI/btn";
import {
  filterAcceptedMaterials,
  getAcceptedMaterials,
  searchAcceptedMaterials,
} from "../../../../store/actions";
import { truncate } from "../../../../utils/constants";

const AcceptedMaterial = ({ match }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setNewModal] = useState(false);
  const [rowInfo, setRowInfo] = useState([]);

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onFetch = async (page = 1) => {
    const res = await dispatch(
      getAcceptedMaterials({
        id: match?.params?.id,
        payload,
        page,
      })
    );
    if (!res.error) {
      const { landStock, ...paginationData } = res?.payload?.data;
      setTableData(landStock);
      setPaginationData({ ...paginationData, page });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      searchAcceptedMaterials({
        id: match?.params?.id,
        key,
        page,
      })
    );
    if (!res.error) {
      const { landStock, ...paginationData } = res?.payload?.data;
      setTableData(landStock);
      setPaginationData({ ...paginationData });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      filterAcceptedMaterials({
        id: match?.params?.id,
        payload: date,
        page,
      })
    );
    if (!res.error) {
      const { landStock, ...paginationData } = res?.payload?.data;
      setTableData(landStock);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData?.totalPages);
    }
  };

  useEffect(() => {
    onFetch();
  }, []);

  const onRefresh = () => {
    onFetch();
  };
  const data = tableData?.map((data) => ({
    key: data._id,
    address: data?.address + `, ${data?.area}`,
    phone: data?.contactPhone,
    createdAt: data?.createdAt,
    categories: data?.categories,
    collector: data?.recycler?.fullname,
    phone: data?.recycler?.phone,
    quantity: data?.quantity,
  }));

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    },

    {
      title: "Collector",
      dataIndex: "collector",
      key: "collector",
    },

    {
      title: "Waste Categories",
      dataIndex: "categories",
      key: "categories",

      render: (categories) => (
        <span>
          {(categories?.slice(0, 3) || []).map((waste) => {
            return (
              <Tag key={waste}>
                <Popover content={waste?.name || waste}>
                  {truncate(waste?.name, 10)}
                </Popover>
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Waste Quantity (Kg)",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Waste Quantity",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          <StyledButton
            buttonStyle="btn--primary--outline"
            buttonSize="btn--small"
            onClick={() => {
              setRowInfo(record);
              setShowModal(true);
            }}
          >
            See More
          </StyledButton>
        </Space>
      ),
    },
  ];

  const pages = [{ name: "Space Owner", link: "/admin/pakam-associates" }];
  const previous = [
    {
      name: "Profile details",
      link: `/admin/space_details/${match?.params?.id}`,
    },
  ];

  return (
    <div>
      <BreadCrumb
        pages={pages}
        previous={previous}
        current="Accepted Materials"
      />

      <PickupModal
        showModal={showModal}
        setShowModal={setShowModal}
        userData={rowInfo}
        acceptedMaterials
        // dashboard
      />

      <DataTable
        data={data}
        columns={columns}
        onSearch={onSearch}
        onFilter={onFilter}
        onRefresh={onRefresh}
        setCurrentPage={setCurrentPage}
        paginationData={paginationData}
        onFetch={onFetch}
        header
      />
    </div>
  );
};

export default AcceptedMaterial;
