import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Filter from "../../../../components/UI/Filter";
import Tabcontent from "../../../../components/UI/TabContent";
import StyledButton from "../../../../components/UI/btn";
import Modal from "../../../../components/UI/modal";
import Invoice from "../../../../components/adminFinancials/Invoice";
import PromptModal from "../../../../components/common/PromptModal";
import { FlexBetween } from "../../../../components/styledElements";
import tw from "twin.macro";
import {
	completeFinancialPayment,
	downloadInvoices,
} from "../../../../store/actions";
import { StatusTag, capitalize, formatAmount, getMonthYear } from "../../../../utils";
import { FinancialsCards } from "../../../../utils/data";
import { LinkText } from "../../../TotalUser/TotalUser";
import { PointCard } from "../..";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import FormInput from "../../../../components/auth/FormInput";
import { deleteOrgInvoice, downloadAsPDF, fetchFinancialOrganizations, fetchInvoicefilter, fetchOrgFinancialSummaryNew, fetchOrgInvoices, fetchOrgTransactions, fetchTransactionfilter, filterOrgFinancialSummaryNew, searchOrgInvoice, searchOrgTransactions, setInvoiceStatus } from "../../../../store/actions/financialsActions";
import InvoiceModal from "../../../../components/invoice/InvoiceModal";
import DeleteModal from "../../../../components/common/DeleteModal";

const invoicebaseURL = process.env.REACT_APP_NEW_TEST_INVOICE_URL;

const WastePickerContainer = styled.div`
	display: grid;
	grid-template-coloumns: auto 1fr;
	gap: 20px;
`;

export const UserContainer = styled.div`
  //   display: grid;
  //   grid-template-coloumns: auto 1fr;
  margin-bottom: 20px;
  display: grid;
`;

export const NavBarLeft = styled.div`
  ${tw`flex justify-between`}

  .text {
    font-size: 15px;
    color: "#0e0e0e";
  }
`;

const ModalBackground = styled.div`
  width: 255px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  border-radius: 10px;
  padding-bottom: 10px;
  z-index: 99999;
  left: 0;
  top: 70%;

  modalContainer {
    position: relative;
  }
`;

const GenerateInvoice = styled.div`
  margin-left: -10px !important;
  color: red;
`;

export const PartialLinkText = ({title})=>{
	const titleText = capitalize(title)
	return(
	  <p className="text-[#B67A12] font-Rale font-[400] text-[14px] underline">{titleText}</p>
	)
  }

const TransactionHistory = ({ match }) => {
	const user_claims = JSON.parse(localStorage.getItem('Claims'))
    const {
        params: { id },
      } = match;

	  const d = new Date();
	  d.setDate(d.getDate());
	  const payload = {
		  start: "2010-01-01",
		  end: d,
	  };
	/****************************
	 *
	 * states and hooks
	 *
	 ****************************/
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableBody, setTableBody] = useState([]);
	const [tableBody2, setTableBody2] = useState([]);
	const [outstandingPagination, setOustandingPagination] = useState();
	const [invoicePagination, setInvoicePagination] = useState();
	const [showPostModal, setPostModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showPrompt, setPrompt] = useState(false);
	const [summaryData, setSummaryData] = useState();
	const [showReceiptModal, setReceiptModal] = useState();
	const [showSetStatus, setShowSetStatus] = useState(false);
	const [payStatus, setPayStatus] = useState(null);
	const [openPayStatus, setOpenPayStatus] = useState(false);
	const [showSuccessModal, setSuccessModal] = useState(false);
	const [showDeleteModal, setDeleteModal] = useState(false);
	const [currentEntry, setCurrentEntry] = useState();
	const [orgBody, setOrgBody] = useState();
	const [orgData, setOrgData] = useState({});
	const [view, setView] = useState('0')
	const [invoiceOverview, setInvoiceOverview] = useState()
	const [selectedInvoice, setSelectedInvoice] = useState("")
	const [amount, setAmount] = useState("")
	const [statuserror, setError] = useState(false)

	const fetchOrganizations = async (page = 1) => {
		const res = await dispatch(
			fetchFinancialOrganizations({
				...payload,
				page,
			})
		);
		if (!res.error) {
			const { organizations, ...paginationData } = res.payload;
			setOrgBody(organizations);
			// setOustandingPagination({ ...paginationData, date: payload });
		}
	};

	const fetchOrganizationsTransactions = async (page = 1) => {

		const res = await dispatch(
			fetchOrgTransactions({
				...payload,
				page,
				id
			})
		);
		if (!res.error) {
			const { transactions, ...paginationData } = res.payload.data;
			setTableBody(transactions);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};
	const searchOrganizationsTransactions = async (key, page = 1) => {

		const res = await dispatch(
			searchOrgTransactions({
				...payload,
				page,
				id,
				key
			})
		);
		if (!res.error) {
			const { transactions, ...paginationData } = res.payload.data;
			setTableBody(transactions);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};
	const searchOrganizationsInvoice = async (key, page = 1) => {

		const res = await dispatch(
			searchOrgInvoice({
				...payload,
				page,
				id,
				key
			})
		);
		if (!res.error) {
			const { invoices, invoiceAmount, ...paginationData  } = res.payload.data;
			setInvoiceOverview(invoiceAmount)
			setTableBody2(invoices);
			setInvoicePagination({ ...paginationData, date: payload });
		}
	};


	const fetchOrganizationsInvoices = async (page = 1) => {

		const res = await dispatch(
			fetchOrgInvoices({
				...payload,
				page,
				id
			})
		);
		if (!res.error) {
			console.log(res)
			const { invoices, invoiceAmount, ...paginationData  } = res.payload.data;
			setInvoiceOverview(invoiceAmount)
			setTableBody2(invoices);
			setInvoicePagination({ ...paginationData, date: payload });
		}
	};
	const handleInvoiceDelete = async (invoiceNumber) => {
		console.log(invoiceNumber)
		const res = await dispatch(
			deleteOrgInvoice({
				invoiceNumber
			})
		);
		if (!res.error) {
			console.log(res)
			onRefresh()
		}
	};

	const handleCancel = ()=>{
			setAmount('')
			setPayStatus('')
			setOpenPayStatus(false)
			setSelectedInvoice()			
			setShowSetStatus(false)
	}

	const handleStatus = async (page = 1) => {

		const res = await dispatch(
			setInvoiceStatus({
				...payload,
				page,
				status: payStatus,
				amount,
				id : selectedInvoice?.invoiceNumber
			})
		);
		if (!res.error) {
			// const { invoices, invoiceAmount, ...pagination  } = res.payload.data;
			console.log(res)
			handleCancel()
			setSuccessModal(true)
			setError(false)
			onRefresh()
			// setInvoiceOverview(invoiceAmount)
			// setTableBody2(invoices);
			// setOustandingPagination({ ...pagination, date: payload });
		}else{
			setError(true)
			setSuccessModal(true)
		}
	};

	useEffect(()=>{
		const data = orgBody?.find((i)=> i._id === id)
		setOrgData(data)
	}, [orgBody])

	useEffect(()=>{
		fetchOrganizations()
	},[])

	const dispatch = useDispatch();
	const {
		area: { allAreas },
		app: { error },
		auth: {
			userInfo: { claims },
		},
		location: { locations },
		financials: { completedPayments, outstandingPayments },
	} = useSelector((state) => state);
	const invoiceRef = useRef();

	/****************************
	 *
	 * handlers and utils functions
	 *
	 ****************************/

	const totalSummaryCards = FinancialsCards.map((el) => {
		return { ...el };
	});



	totalSummaryCards[0].amount = summaryData?.totalPayment || 0;
	totalSummaryCards[1].amount = summaryData?.totalOutStanding || 0;
	totalSummaryCards[2].amount = summaryData?.totalCompleted || 0;
	totalSummaryCards[3].amount = summaryData?.totalMaintanceFee || 0;

	const handleTransactionFilter = async (date, page = 1) => {
		const res = await dispatch(
			fetchTransactionfilter({
				...date,
				id,
				page,
			})
		);
		if (!res.error) {
			const { transactions, ...paginationData } = res.payload.data;
			console.log(res)
			setTableBody(transactions);
			setOustandingPagination({ ...paginationData, date: payload });
		}
	};

	const handleInvoiceFilter = async (date, page = 1) => {
		const res = await dispatch(
			fetchInvoicefilter({
				...date,
				id,
				page,
			})
		);
		if (!res.error) {
			const { invoices, invoiceAmount, ...paginationData  } = res.payload.data;
			setInvoiceOverview(invoiceAmount)
			setTableBody2(invoices);
			setInvoicePagination({ ...paginationData, date: payload });
		}
	};

	const handleSendInvoice = async (invoiceNum) => {
		const res = await dispatch(downloadInvoices(invoiceNum));
		if (!res.error) console.log(res.error);
		setPostModal(true);
	};

	const fetchCardData = async () => {
		const data = await dispatch(fetchOrgFinancialSummaryNew({id}));
		setSummaryData(data.payload);
	};

	const completePayment = async (invoiceNumber) => {
		const res = await dispatch(completeFinancialPayment(invoiceNumber));
		if (!res.payload.error) onRefresh();
	};

	const handleSwitch = (key) => {
		setView(key);
	  };

	  const handleSummaryDataFilter = async (date, page = 1) => {

		const res = await dispatch(
			filterOrgFinancialSummaryNew({
				...date,
				id,
				page,
			})
		);
		if (!res.error) {

      const {payload} = res
      setSummaryData(payload)
		}
	};


	const data = [
		{
			title: "Transaction History",
			link: "Transaction History",
			data: tableBody,
			filterHandler: handleTransactionFilter  ,
			searchHandler: searchOrganizationsTransactions,
			fetch: fetchOrganizationsTransactions,
			totalPages: outstandingPagination?.totalPages,
			paginationData: outstandingPagination,
			columns: [
				// {
				// 	title: "Invoice ID",
				// 	dataIndex: "invoiceNumber",
				// 	key: "invoiceNumber",
				// 	render: (text) => <p>{truncate(text, 30)}</p>,
				// },
				{
					title: "Date",
					dataIndex: "createdAt",
					key: "createdAt",
					render: (text, record) => <p>{text.split('T')[0]}</p>
				},
				{
					title: <span>Weight (KG)</span>,
					dataIndex: "weight",
					key: "weight",
				},
				{
					title: <span>Income</span>,
					dataIndex: "coin",
					key: "coin",
				},
				// {
				// 	title: "Invoice",
				// 	dataIndex: "action",
				// 	key: "action",
				// 	render: (text, record) => {
				// 		return (
				// 			<Space size="middle">
				// 				<StyledButton
				// 					buttonStyle="btn--primary--outline"
				// 					buttonSize="btn--small"
				// 					onClick={() => {
				// 						setCurrentEntry(record);
				// 						setReceiptModal(true);
				// 					}}
				// 				>
				// 					View
				// 				</StyledButton>
				// 			</Space>
				// 		);
				// 	},
				// },
				// {
				// 	title: "Mark as paid",
				// 	dataIndex: "paid",
				// 	key: "action",
				// 	render: (text, record) => {
				// 		return (
				// 			<>
				// 				<Space size="middle">
				// 					<StyledButton
				// 						type=""
				// 						buttonSize="btn--small"
				// 						onClick={() => {
				// 							setCurrentEntry(record);
				// 							setPrompt(true);
				// 						}}
				// 					>
				// 						Mark
				// 					</StyledButton>
				// 				</Space>
				// 			</>
				// 		);
				// 	},
				// },
			],
		},
		{
			title: "Invoice",
			link: "Invoice",
			data: tableBody2.map((entry) => ({
				...entry,
				organisation: entry.company?.companyName,
			})),
			filterHandler: handleInvoiceFilter,
			searchHandler: searchOrganizationsInvoice,
			activityFilterHandler: searchOrganizationsInvoice,
			fetch: fetchOrganizationsInvoices,
			totalPages: invoicePagination?.totalPages,
			paginationData: invoicePagination,
			columns: [
				// {
				// 	title: "Invoice ID",
				// 	dataIndex: "invoiceNumber",
				// 	key: "invoiceNumber",
				// 	render: (text) => <p>{truncate(text, 30)}</p>,
				// },
				{
					title: "Date Generatd",
					dataIndex: "createdAt",
					key: "createdAt",
					render: (text, record) =>  <p>{text.split('T')[0]}</p>
				},
				{
					title: <span>Payment Status</span>,
					dataIndex: "paidStatus",
					key: "paidStatus",
                    render: (text, record) =>
						{
							if (text === 'pending'){
								return(<button 
									onClick={()=>{
										setShowSetStatus(true)
										setSelectedInvoice(record)
										console.log(record)
									}
									} 
									className="">
										<LinkText title={'Set Status'}/>
									</button>)
								
							}else if(text === 'partial payment'){
								return(
									<button 
									onClick={()=>{
										setShowSetStatus(true)
										setSelectedInvoice(record)
										console.log(record)
									}
									} 
									className="">
										<PartialLinkText title={'Partial Payment'}/>
									</button>
								)
								
							
							}else{
								return(<StatusTag status={[text]}/>)
								
							}
						}

                        

				},
				{
					title: <span>Expected Amount</span>,
					dataIndex: "amount",
					key: "amount",
				},
				{
					title: <span>Amount Paid</span>,
					dataIndex: "totalPaid",
					key: "totalPaid",
				},
				{
					title: <span>Balance</span>,
					dataIndex: "totalBalance",
					key: "totalBalance",
				},

				{
					title: "Action",
					dataIndex: "paid",
					key: "action",
					render: (text, record) => {
						return (
							<>
								<Space size="middle">
									<StyledButton
										type=""
										buttonSize="btn--small"
										onClick={() => {
											setCurrentEntry(record);
											setReceiptModal(true);
										}}
									>
										View
									</StyledButton>
									
									<StyledButton
										buttonStyle="btn--danger--outline"
										buttonSize="btn--small"
										onClick={() => {
											setCurrentEntry(record);
											setDeleteModal(true)
											
											// handleInvoiceDelete(record?.invoiceNumber)
										}}
									>
										Delete
									</StyledButton>
								</Space>
							</>
						);
					},
				},
			],
		},
	];

	const invoiceCardData = [
		{
		  title:'Outstanding Payment',
		  amount: invoiceOverview?.outstanding || 0,
		  icon:'/assets/images/outstanding.svg'
		},	
		{
		  title:'Amount Paid',
		  amount: invoiceOverview?.totalPaid || 0,
		  icon:'/assets/images/paid.svg'
		},	

	  
	  ]

	const cardData = [
		{
		  title:'Total Waste (KG)',
		  amount: summaryData?.totalWaste || 0,
		  icon:'/assets/images/f1.svg'
		},	
		{
		  title:'Income from Organizations',
		  amount:summaryData?.organizationIncome || 0,
		  icon:'/assets/images/f2.svg'
		},	
		{
		  title:'Transaction Point',
		  amount:summaryData?.transactionPoint || 0,
		  icon:'/assets/images/f3.svg'
		},	
		{
		  title:'Pakam’s Commission',
		  amount:summaryData?.commission || 0, 
		  icon:'/assets/images/f4.svg'
		  
		},	
	  
	  ]


	useEffect(() => {
		onRefresh();
	}, []);


	const onRefresh = () => {
		fetchCardData();
		// fetchCompleted();
		fetchOrganizationsTransactions()
		fetchOrganizationsInvoices()
	}

    const pages = [{ name: "Organizations", link: "/admin/financials/organizations" }];

	return (
		<>
            <UserContainer>
                <NavBarLeft>
                <BreadCrumb pages={pages} current={orgData?.companyName} />
                </NavBarLeft>
            </UserContainer>

			<DeleteModal
				showModal={showDeleteModal}
				setShowModal={setDeleteModal}
				handleDelete={()=>handleInvoiceDelete(currentEntry?.invoiceNumber)}
				title={'Invoice'}
				type="invoice"
			/>

			<PromptModal
				handler={() => completePayment(currentEntry.invoiceNumber)}
				showModal={showPrompt}
				setShowModal={setPrompt}
				successMessage="Invoice has been marked as paid!"
				promptMessage="Are you sure you want to mark this invoice as paid?"
				buttonText="Mark"
				title={"Mark as paid"}
			/>

				<Modal
					color={statuserror ? "red" : "#005700"}
					type="postAction"
					show={showSuccessModal}
					close={() => setSuccessModal(false)}
				>
					{!statuserror? "Invoice status set successfully" : 'Something went wrong. Pls try again!'}
				</Modal>


			<Modal
				show={showSetStatus}
				close={() => setShowSetStatus(false)}
				width="65em"
			>
				<FlexBetween className="mb-7">
					<div className="flex gap-2 items-center">
						<img src="/assets/images/setstat.svg" alt="icon" />
						<p className="text-xl font-semibold">Set the Status for the Invoice</p>						
					</div>

					<div>
						<StyledButton
							raffle
							buttonStyle="btn--primary--outline"
							// buttonSize="btn--small"
							onClick={handleCancel}
							className=""
						>
								Cancel
						</StyledButton>						
					</div>

				</FlexBetween>


				<div className="grid grid-cols-2 gap-6">

					<FormInput
					type={'text'}
					label={'Organization'}
					// changeHandler={(e) => setValue(key, e.target.value)}
					errorMsg={''}
					value={orgData?.companyName}
					disabled={true}
				/>
					<FormInput
					type={'text'}
					label={'Date'}
					// changeHandler={(e) => setValue(key, e.target.value)}
					errorMsg={''}
					value={getMonthYear(selectedInvoice?.createdAt?.split('T')[0])}
					disabled={true}
				/>

				<div  className="relative">
					<div onClick={()=>setOpenPayStatus(true)}>
						<FormInput
						type={'text'}
						label={'Set Status'}
						placeholder={'Select Payment Status'}
						// changeHandler={(e) => setValue(key, e.target.value)}
						errorMsg={''}
						value={payStatus}
					/>							
					</div>


					{
						openPayStatus ?
						<ModalBackground>
							<div className='w-full cursor-pointer user-text h-[30px] text-left flex items-center pl-4 mt-1' onClick={()=>{
								setPayStatus('paid')
								setOpenPayStatus(!openPayStatus)
								console.log("it is done")
							}}>
								<span onClick={()=>{
								setOpenPayStatus(false)
							}}>Mark as Paid</span>
								<img src={`${payStatus === 'paid'}`} alt="" />
							
							</div>
							<hr />
							<div className='w-full cursor-pointer user-text h-[30px] text-left flex items-center pl-4'onClick={()=>{
								setPayStatus('partial payment')
							 	setOpenPayStatus(false)
							}}>
							Mark as Partial Payment
							</div>   

							
						</ModalBackground>	:	""					
					}
			
				</div>


				{
					payStatus === 'partial payment'&& 
						<FormInput
						type={'text'}
						label={`${payStatus === 'partial payment' ? 'Amount': 'Full Payment'} `}
						placeholder={'Input the amount paid'}
						changeHandler={(e) => setAmount(e.target.value)}
						errorMsg={''}
						value={amount}
					/>					
				}

				</div>

				<div className="w-full flex justify-center">
                					<StyledButton
										type=""
										buttonSize="btn--small"
										onClick={handleStatus}
										disabled={!payStatus || (payStatus === 'partial payment' && amount === "")}
									>
										Set Status
									</StyledButton>
				</div>
			</Modal>
			<Modal
				show={showReceiptModal}
				close={() => setReceiptModal(false)}
				width="85em"
			>
				<FlexBetween className="mb-7">
					<p className="text-xl font-semibold">Organization Invoices</p>

					<div className="flex items-center gap-2">
						<StyledButton
							raffle
							buttonStyle="btn--primary--outline"
							buttonSize="btn--small"
							className="text-black"
							// onClick={()=>console.log(`${invoicebaseURL}/v2/invoice/generate/invoicepdf/${currentEntry?.invoiceNumber}`)}
						>
							<a download href={`${invoicebaseURL}/v2/invoice/generate/invoicepdf/${currentEntry?.invoiceNumber}`}>
								Download as PDF
							</a>
								
						</StyledButton>
						{/* <StyledButton
							raffle
							buttonStyle="btn--primary--outline"
							buttonSize="btn--small"
							className="text-black"
							onClick={()=>handleSendInvoice(currentEntry?.invoiceNumber)}
						>

								Send as Email
						</StyledButton>						 */}
					</div>


				</FlexBetween>
				<Invoice open={showReceiptModal} invoiceId={currentEntry?.invoiceNumber} ref={invoiceRef} />
			</Modal>

			<Modal
				color={error ? "red" : "#005700"}
				type="postAction"
				show={showPostModal}
				close={() => setPostModal(false)}
			>
				{!error ? "Invoice status set successfully" : error}
			</Modal>

			{showModal && (
				<InvoiceModal
				showModal={showModal}
				setShowModal={setShowModal}
				id={`org-${id}`}
				/>
			)}



            <div className="flex justify-between w-full">

                <div>
                	<h1 className=" font-Int text-[24px] font-[600]">{orgData?.companyName}</h1>
                </div>

				{view === '0' &&
					<div className="flex gap-2 items-center">

							<GenerateInvoice>
								<StyledButton
								buttonStyle="btn----outline"
								buttonSize="btn--small"
								className="flex justify-between items-center"
								onClick={() => setShowModal(true)}
								>
								<img src="/assets/images/generate.svg" alt="" />

								Generate Invoice
								</StyledButton>
							</GenerateInvoice>           
							


						<Filter onFilter={handleSummaryDataFilter}/>
					</div>
					}


											
            </div>

                <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container mt-2 mb-2">

					{view === '0'?
						cardData.map((i, id)=>{
							return(
							<PointCard key={id} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
							)
						}) 
						:
						invoiceCardData.map((i, id)=>{
							return(
							<PointCard key={id} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
							)
						}) 
					}
                </div>


			<div className="flex flex-col gap-3">


				<WastePickerContainer>
					<Tabcontent
						data={data}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						onRefresh={onRefresh}
						onSwitch={handleSwitch}
					/>
				</WastePickerContainer>
			</div>
		</>
	);
};

export default TransactionHistory;



