import { Avatar, Divider, Space, Tabs, Tag } from "antd";
import moment from "moment";
import React, { Children, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import Option from "../../components/UI/Option";
import BreadCrumb from "../../components/UI/breadCrumbs";
import { FlexContainer } from "../../components/styledElements/index";
import { userDetail } from "../../store/actions";
import DataTable from "../../components/UI/Table";
import UserPendingSchedule from "./schedules/UserPendingSchedule";
import UserAcceptedSchedule from "./schedules/UserAcceptedSchedule";
import UserMissedSchedule from "./schedules/UserMissedSchedule";
import UserCompletedSchedule from "./schedules/UserCompletedSchedule";
import UserCancelledSchedule from "./schedules/UserCancelledSchedule";
import UserDropOff from "./dropoff/UserDropOff";
import PayoutView from "./payout/PayoutView";
import StyledButton from "../../components/UI/btn";
import { CopyButton, capitalize, getInitials } from "../../utils";
import { MdOutlineManageAccounts } from "react-icons/md";

export const UserContainer = styled.div`
  //   display: grid;
  //   grid-template-coloumns: auto 1fr;
  margin-bottom: 20px;
  display: grid;
`;

export const NavBarLeft = styled.div`
  ${tw`flex justify-between`}

  .text {
    font-size: 15px;
    color: "#0e0e0e";
  }
`;
const ModalBackground = styled.div`
  ${tw`p-3`}
  background-color: white!important;
`;

const DescTitle = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.4px;
  text-align: left;
  color:#475467;

`;
const DescValue = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: left;
  color:#101928;


`;

const ColumnStyle = styled.div`
  letter-spacing: 1px;
  margin-top: 30px;
  margin-left: 30px;
  justify-content: space-between;
  color: green;
  font-size: 17px;
`;

const InfoWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-12 w-11/12`}
`;
const InfoItem = styled.div`
  ${tw`flex flex-col space-y-2`}
`;

const InfoTitle = styled.p`
  ${tw`font-semibold text-sm leading-[22px] text-secondary`}
`;
const InfoValue = styled.p`
  ${tw`font-bold text-base leading-[28px]`};
  color: ${(props) => (props.color ? props.color : "#464F54")};
`;

const MobileUserDetails = ({ match }) => {
  
  const [userData, setuserData] = useState({});
  const [userInitial, setUserInitial] = useState('FA');
  const [activeTab, setActiveTab] = useState(    {
    key:'1',
    label:'Pending Schedule',
    children: <UserPendingSchedule match={match}/>
  });
  


  useEffect(() => {
    if(userData){
      const inital = getInitials(userData?.firstName)
      setUserInitial(inital)      
    }

  }, [userData])
  

  const {
    params: { id },
  } = match;

  const fetchAll = async () => {
    const res = await dispatch(userDetail(id));
    if (!res.error) {
      const { data } = res.payload;
      // console.log(data)
      setuserData(data);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  function formatDate(dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }
  const pages = [{ name: "Total Users", link: "/admin/total_users" }];

  const dispatch = useDispatch();


  console.log(userData)

  const items = [
    {
      key:'1',
      label:'Pending Schedule',
      children: <UserPendingSchedule match={match}/>
    },
    {
      key:'2',
      label:'Missed Schedule',
      children: <UserMissedSchedule match={match}/>
    },
    {
      key:'3',
      label:'Accepted Schedule',
      children: <UserAcceptedSchedule match={match}/>
    },
    {
      key:'4',
      label:'Completed Schedule',
      children: <UserCompletedSchedule match={match}/>
    },
    {
      key:'5',
      label:'Cancelled Schedule',
      children: <UserCancelledSchedule match={match}/>
    },
    {
      key:'6',
      label:'Drop-off Request',
      children: <UserDropOff match={match}/>
    },
    {
      key:'7',
      label:'Payout History',
      children: <PayoutView match={match}/>
    },
  ]


  const scrollContainer = useRef(null);

  // Function to scroll the container left
  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: -200, // Adjust this value to control how far it scrolls
        behavior: 'smooth',
      });
    }
  };

  // Function to scroll the container right
  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: 200, // Adjust this value to control how far it scrolls
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <UserContainer>
        <NavBarLeft>
          <BreadCrumb pages={pages} current={capitalize(userData?.firstName)} />
        </NavBarLeft>
      </UserContainer>

      <div className="w-full flex items-center py-6">
      {/* Button to scroll left */}
      <button 
        className="bg-white rounded-full flex justify-center items-center h-[40px] w-[40px] font-[500]"
        onClick={scrollLeft}
      >
        {'<'}
      </button>

      {/* Scrollable container with horizontal overflow */}
      <div className="flex-1 overflow-x-scroll" ref={scrollContainer} style={{ whiteSpace: 'nowrap' }}>
        {items?.map((i) => (
          <button
            className={`transition-all ease-in-out duration-500 font-Rale font-[600] inline-block text-[14px] px-2 py-3 border-b text-[#848a8c] ${
              i.key === activeTab.key ? 'text-[#005A00] border-b-2 border-[#005A00]' : ''
            }`}
            onClick={() => setActiveTab(i)}
            key={i.key}
          >
            {i.label}
          </button>
        ))}
      </div>

      {/* Button to scroll right */}
      <button 
        className="bg-white rounded-full flex justify-center items-center h-[40px] w-[40px] font-[500]"
        onClick={scrollRight}
      >
        {'>'}
      </button>
    </div>


      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-2/3">
          {activeTab?.children}
        </div>

        <div className="w-full lg:w-1/3 flex flex-col md:flex-row lg:flex-col gap-4">
          <div className="w-full md:w-1/2 lg:w-full">
              <ModalBackground>
                <div>
                  <div className="flex items-center gap-3 p-3">
                    <div><Avatar style={{backgroundColor:'#E6EFE6', color:"#005700"}} size={40}>{userInitial}</Avatar></div>
                    <div>
                      <p className="font-int font-[600] text-[20px] text-[#002800]">{capitalize(userData?.firstName)} </p>
                      <p className="font-int font-[400] text-[14px] text-[#727A86] ">{userData?.email}</p>
                    </div>
                  </div>  
                  

                  {/* <div className="flex items-center gap-3 border-t py-3">
                    <div><img src="/assets/images/iconcus.svg" alt="" srcset="" /></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Customer ID</DescTitle>
                      <DescValue>gea3</DescValue>
                    </div>
                    <div><CopyButton tag={'Fullname'} text={capitalize(userData?.firstName)}/></div>
                  </div>     */}

                      
                  <div className="flex items-center gap-3 border-t py-3">
                    <div><img src="/assets/images/iconperson.png" alt="" srcset="" /></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Full name</DescTitle>
                      <DescValue>{capitalize(userData?.firstName)}</DescValue>
                    </div>
                    <div><CopyButton tag={'Fullname'} text={capitalize(userData?.firstName)}/></div>
                  </div>    

                      
                  <div className="flex items-center gap-3 border-t py-3">
                    <div><MdOutlineManageAccounts color='#9ba5b5' size={20}/></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Account Type</DescTitle>
                      <DescValue>{userData?.accountType || 'N/A'}</DescValue>
                    </div>
                    <div><CopyButton tag={'Account Type'} text={capitalize(userData?.accountType)}/></div>
                  </div>    

                      
                  <div className="flex items-center gap-3 border-t py-3">
                    <div><img src="/assets/images/iconmail.svg" alt="" srcset="" /></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Email</DescTitle>
                      <DescValue>{userData?.email}</DescValue>
                    </div>
                    <div><CopyButton tag={'Email'} text={capitalize(userData?.email)}/></div>
                  </div>    

                      
                  <div className="flex items-center gap-3 border-t py-3">
                    <div><img src="/assets/images/iconphone.svg" alt="" srcset="" /></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Phone number</DescTitle>
                      <DescValue>{userData?.phoneNumber}</DescValue>
                    </div>
                    <div><CopyButton tag={'Phone Number'} text={capitalize(userData?.phoneNumber)}/></div>
                  </div>    

                      
                  <div className="flex items-center gap-3 border-t py-3">
                    <div><img src="/assets/images/icondate.svg" alt="" srcset="" /></div>
                    <div className="w-[90%]">
                      <DescTitle className="">Date created</DescTitle>
                      <DescValue>{ userData?.createdAt?.split('T')[0]}</DescValue>
                      {/* <DescValue>{formatDate(userData?.createdAt.split('T')[0]) }</DescValue> */}
                    </div>
                  </div>    
    
                </div>




              </ModalBackground>
          </div>


          <div className="w-full md:w-1/2 lg:w-full">
            <DescValue>Schedule History</DescValue>
            <ModalBackground>
              <div className="space-y-2 p-3">
                <div className="flex items-center gap-3  py-3">
                  <div className="h-[30px] w-[35px] bg-[#FBEAE9] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Total Schedule Pickup</DescValue>
                    
                  </div>
                  <DescValue>{userData?.totalSchedulePickup}</DescValue>
                </div> 
                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#FBEAE9] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Total Schedule Drop-off</DescValue>
                    
                  </div>
                  <DescValue>{userData?.totalScheduleDrop}</DescValue>
                </div> 

                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#FBEAE9] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Pending Schedule</DescValue>
                    
                  </div>
                  <DescValue>{userData?.pendingSchedulePickup}</DescValue>
                </div> 

                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#E7F5EC] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Accepted Schedule</DescValue>
                    
                  </div>
                  <DescValue>0</DescValue>
                </div> 
                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#E7F5EC] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Completed Schedule</DescValue>
                    
                  </div>
                  <DescValue>{userData?.completedSchedulePickup}</DescValue>
                </div> 
                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#E7F5EC] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Missed Schedule</DescValue>
                    
                  </div>
                  <DescValue>{userData?.missedSchedulePickup}</DescValue>
                </div> 
                <div className="flex items-center gap-3 py-3">
                  <div className="h-[30px] w-[35px] bg-[#E7F5EC] rounded-full"></div>
                  <div className="w-[90%]">
                    <DescValue>Wallet Balance</DescValue>
                    
                  </div>
                  <DescValue>{userData?.walletBalance}</DescValue>
                </div> 
              </div>



            </ModalBackground>            
          </div>


        </div>
        
      </div>


    </>
  );
};

export default MobileUserDetails;
