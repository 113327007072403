import React, { useEffect, useState } from "react";
import DataTable from "../../../components/UI/Table";
import BreadCrumb from "../../../components/UI/breadCrumbs";
import { UserContainer, NavBarLeft } from "../MobileUserDetails";
import { useDispatch } from "react-redux";
import StyledButton from "../../../components/UI/btn";
import {
  userCompletedSchedule,
  userSearchPendingSchedule,
} from "../../../store/actions";
import PickupModal from "../../../components/UI/PickupModal";
import { Tag, Space } from "antd";
import Button from "../../../components/UI/button";
import { Popover } from "antd";
import { truncate } from "../../../utils/constants";
import moment from "moment";

const UserCompletedSchedule = ({ match }) => {
  const [bodyData, setBodyData] = useState();
  const [paginationData, setPaginationData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState([]);

  const dispatch = useDispatch();
  const {
    params: { id },
  } = match;

  // for payload
  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      userCompletedSchedule({
        page,
        key: key || "",
        id,
      })
    );

    if (!res.error) {
      const { schedules, ...paginationData } = res.payload.data;
      setBodyData(schedules);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      userCompletedSchedule({
        currentMonth: date,
        page,
        id,
      })
    );
    if (!res.error) {
      const { schedules, ...paginationData } = res.payload.data;
      setBodyData(schedules);

      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const fetchAll = async (page = 1) => {
    const res = await dispatch(
      userCompletedSchedule({
        currentMonth: payload,
        page,
        id,
      })
    );
    if (!res.error) {
      console.log(res.payload.data)
      const { schedules, ...paginationData } = res.payload.data;
      setBodyData(schedules);
      setPaginationData({ ...paginationData, date: payload });
    }
  };

  const onRefresh = () => {
    fetchAll();
  };


  useEffect(() => {
    onRefresh();
  }, []);

  const pages = [{ name: "Total Users", link: "/admin/total_users" }];
  const previous = [
    { name: "User Details", link: `/admin/user_details/${id}` },
  ];

  const columns = [
    {
      title: "Completed Date",
      dataIndex: "dropOffDate",
      key: "dropOffDate",
      render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    },
    {
      title: "Waste Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <p>{text} KG</p>,
    },
    // {
    //   title: "Phone Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Waste Categories",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (categories) => (
    //     <span>
    //       {(categories.slice(0, 3) || []).map((waste) => {
    //         return (
    //           <Tag key={waste}>
    //             <Popover content={waste?.name || waste}>
    //               {truncate(waste?.name, 10)}
    //             </Popover>
    //           </Tag>
    //         );
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Pickup Location",
    //   dataIndex: "address",
    //   key: "address",
    // },

    {
      title: "Completed By",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Schedule Type",
      dataIndex: "scheduleType",
      key: "scheduleType",
      render: (text) => (
        <>
          {text === 'schedulepick'
            ? 
            <div style={{ backgroundColor: '#e7f5ec', display: 'inline-flex' }} className="px-3 justify-center items-center py-[2px] rounded-[10px] border">
  
            <span style={{ color:'#0f973d' }} className="text-center font-Rob font-[600] text-[12px]">
            
              Pick up
            
            </span>
            
            </div>
            
            :              
            <div style={{ backgroundColor: '#fef6e8', display: 'inline-flex' }} className="px-3 justify-center items-center py-[2px] rounded-[10px] border">
  
            <span style={{ color:'#db9216' }} className="text-center font-Rob font-[600] text-[12px]">Drop Off</span>
            
            </div> }
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <StyledButton
            type=""
            buttonStyle="btn--primary--outline"
            buttonSize="btn--small"
            onClick={() => {
              setRowInfo(record);
              setShowModal(true);
            }}
          >
            See More
          </StyledButton>
          {/* <a>See More</a> */}
        </Space>
      ),
    },
  ]

  return (
    <div>
      <PickupModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={rowInfo}
        userData={rowInfo}
        pending
      />

      {/* <UserContainer>
        <NavBarLeft>
          <BreadCrumb
            pages={pages}
            current="Pending Schedule"
            previous={previous}
          />
        </NavBarLeft>
      </UserContainer> */}

      <DataTable
        data={bodyData}
        columns={columns}
        header
        onFilter={onFilter}
        onSearch={onSearch}
        onRefresh={onRefresh}
        // setCurrentPage={setCurrentPage}
        totalPages={paginationData?.totalPages}
        paginationData={paginationData}
        onFetch={fetchAll}
      />
    </div>
  );
};

export default UserCompletedSchedule;




// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import Tabcontent from "../../../components/UI/TabContent";

// import { Popover, Space, Tag } from "antd";
// import moment from "moment";
// import PickupModal from "../../../components/UI/PickupModal";
// import StyledButton from "../../../components/UI/btn";
// import {
//   totalUsersCompletedDropoffSchedule,
//   totalUsersCompletedPickupSchedule,
//   totalUsersDropoffSearchSchedules,
//   totalUsersPickupSearchSchedules,
// } from "../../../store/actions";
// import { truncate } from "../../../utils/constants";
// import { userCompletedSchedule } from "../../../store/actions/userAction";

// const UserCompletedSchedule = ({ match }) => {
//   const {
//     params: { id },
//   } = match;
//   const pages = [{ name: "Total Users", link: "/admin/total_users" }];
//   const previous = [
//     { name: "User Details", link: `/admin/user_details/${id}` },
//   ];

//   const [bodyData, setBodyData] = useState();
//   const [pickupBodyData, setPickupBodyData] = useState();
//   const [paginationData, setPaginationData] = useState();
//   const [pickuppaginationData, setPickupPaginationData] = useState();
//   const [totalPages, setTotalPages] = useState(1);
//   const [pickuptotalPages, setPickupTotalPages] = useState(1);
//   const [showModal, setShowModal] = useState(false);
//   const [rowInfo, setRowInfo] = useState([]);

//   const dispatch = useDispatch();
//   const d = new Date();
//   d.setDate(d.getDate());
//   const payload = {
//     start: "2010-01-01",
//     end: d,
//   };

//   const onSearchDropoff = async (key, page = 1) => {
//     const res = await dispatch(
//       totalUsersDropoffSearchSchedules({
//         key: key || "",
//         page,
//         id,
//       })
//     );

//     if (!res.error) {
//       const { schedule, ...paginationData } = res.payload.data;

//       setBodyData(schedule);
//       setPaginationData({ ...paginationData, key });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   console.log(bodyData)

//   const onSearchPickups = async (key, page = 1) => {
//     const res = await dispatch(
//       totalUsersPickupSearchSchedules({
//         key: key || "",
//         page,
//         id,
//       })
//     );

//     if (!res.error) {
//       const { schedules, ...paginationData } = res.payload.data;
//       setPickupBodyData(schedules);
//       setPickupPaginationData({ ...paginationData, key });
//       setPickupTotalPages(paginationData.totalPages);
//     }
//   };

//   const onFilterDropoff = async (date, page = 1) => {
//     const res = await dispatch(
//       totalUsersCompletedDropoffSchedule({
//         currentMonth: date,
//         page,
//         id,
//       })
//     );
//     if (!res.error) {
//       const { schedules, ...paginationData } = res.payload.data;
//       setBodyData(schedules);

//       setPaginationData({ ...paginationData, date });
//       setTotalPages(paginationData.totalPages);
//     }
//   };

//   const onFilterPickups = async (date, page = 1) => {
//     const res = await dispatch(
//       totalUsersCompletedPickupSchedule({
//         currentMonth: date,
//         page,
//         id,
//       })
//     );
//     if (!res.error) {
//       const { schedules, ...paginationData } = res.payload.data;
//       setPickupBodyData(schedules);

//       setPickupPaginationData({ ...paginationData, date });
//       setPickupTotalPages(paginationData.totalPages);
//     }
//   };

//   const fetchAll = async (page = 1) => {
//     const res = await dispatch(
//       userCompletedSchedule({
//         currentMonth: payload,
//         page,
//         id,
//       })
//     );

//     if (!res.error) {
//       console.log(res.payload.data)
//       const { dropoffs, ...paginationData } = res.payload.data;
//       setBodyData(dropoffs);
//       setPaginationData({ ...paginationData, date: payload });
//     }
//   };
//   const fetchAllDropoff = async (page = 1) => {
//     const res = await dispatch(
//       totalUsersCompletedDropoffSchedule({
//         currentMonth: payload,
//         page,
//         id,
//       })
//     );

//     if (!res.error) {
//       const { dropoffs, ...paginationData } = res.payload.data;
//       setBodyData(dropoffs);
//       setPaginationData({ ...paginationData, date: payload });
//     }
//   };
//   const fetchAllPickups = async (page = 1) => {
//     const res = await dispatch(
//       totalUsersCompletedPickupSchedule({
//         currentMonth: payload,
//         page,
//         id,
//       })
//     );

//     if (!res.error) {
//       const { schedules, ...paginationData } = res.payload.data;

//       setPickupBodyData(schedules);
//       setPickupPaginationData({ ...paginationData, date: payload });
//     }
//   };

//   const onRefresh = () => {
//     fetchAllDropoff();
//     fetchAllPickups();
//     fetchAll()

//   };

//   useEffect(() => {
//     onRefresh();
//   }, []);

  // const data = [
  //   {
  //     title: "Pickup",
  //     data: pickupBodyData,
  //     totalPages: pickuptotalPages,
  //     paginationData: pickuppaginationData,
  //     filterHandler: onFilterPickups,
  //     searchHandler: onSearchPickups,
  //     fetch: fetchAllPickups,

  //     columns: [
  //       {
  //         title: "Completed Date",
  //         dataIndex: "dropOffDate",
  //         key: "dropOffDate",
  //         render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
  //       },
  //       {
  //         title: "Waste Quantity",
  //         dataIndex: "quantity",
  //         key: "quantity",
  //       },
  //       // {
  //       //   title: "Phone Number",
  //       //   dataIndex: "phone",
  //       //   key: "phone",
  //       // },
  //       // {
  //       //   title: "Waste Categories",
  //       //   dataIndex: "categories",
  //       //   key: "categories",
  //       //   render: (categories) => (
  //       //     <span>
  //       //       {(categories.slice(0, 3) || []).map((waste) => {
  //       //         return (
  //       //           <Tag key={waste}>
  //       //             <Popover content={waste?.name || waste}>
  //       //               {truncate(waste?.name, 10)}
  //       //             </Popover>
  //       //           </Tag>
  //       //         );
  //       //       })}
  //       //     </span>
  //       //   ),
  //       // },
  //       // {
  //       //   title: "Pickup Location",
  //       //   dataIndex: "address",
  //       //   key: "address",
  //       // },

  //       {
  //         title: "Completed By",
  //         dataIndex: "fullname",
  //         key: "fullname",
  //       },
  //       {
  //         title: "Schedule Type",
  //         dataIndex: "action",
  //         key: "action",
  //         render: (text, record) => (
  //           <Space size="middle">
  //             <StyledButton
  //               type=""
  //               buttonStyle="btn--primary--outline"
  //               buttonSize="btn--small"
  //               onClick={() => {
  //                 setRowInfo(record);
  //                 setShowModal(true);
  //               }}
  //             >
  //               See More
  //             </StyledButton>
  //             {/* <a>See More</a> */}
  //           </Space>
  //         ),
  //       },
  //       {
  //         title: "Action",
  //         dataIndex: "action",
  //         key: "action",
  //         render: (text, record) => (
  //           <Space size="middle">
  //             <StyledButton
  //               type=""
  //               buttonStyle="btn--primary--outline"
  //               buttonSize="btn--small"
  //               onClick={() => {
  //                 setRowInfo(record);
  //                 setShowModal(true);
  //               }}
  //             >
  //               See More
  //             </StyledButton>
  //             {/* <a>See More</a> */}
  //           </Space>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Drop off",
  //     data: bodyData,
  //     totalPages: totalPages,
  //     paginationData: paginationData,
  //     filterHandler: onFilterDropoff,
  //     searchHandler: onSearchDropoff,
  //     fetch: fetchAllDropoff,

  //     columns: [
  //       {
  //         title: "Completed Date",
  //         dataIndex: "dropoffDate",
  //         key: "dropoffDate",
  //         render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
  //       },
  //       {
  //         title: "Waste Quantity",
  //         dataIndex: "quantity",
  //         key: "quantity",
  //       },
  //       {
  //         title: "Recycling Company",
  //         dataIndex: "organisation",
  //         key: "organisation",
  //       },
  //       {
  //         title: "Waste Categories",
  //         dataIndex: "categories",
  //         key: "categories",

  //         render: (categories) => (
  //           <span>
  //             {(categories?.slice(0, 3) || [])?.map((waste) => {
  //               return (
  //                 <Tag key={waste}>
  //                   <Popover content={waste?.name || waste}>
  //                     {truncate(waste?.name, 10)}
  //                   </Popover>
  //                 </Tag>
  //               );
  //             })}
  //           </span>
  //         ),
  //       },

  //       {
  //         title: "Amount",
  //         dataIndex: "amount",
  //         key: "amount",
  //       },
  //       {
  //         title: "Action",
  //         dataIndex: "action",
  //         key: "action",
  //         render: (text, record) => (
  //           <Space size="middle">
  //             <StyledButton
  //               type=""
  //               buttonStyle="btn--primary--outline"
  //               buttonSize="btn--small"
  //               onClick={() => {
  //                 setRowInfo(record);
  //                 setShowModal(true);
  //               }}
  //             >
  //               See More
  //             </StyledButton>
  //             {/* <a>See More</a> */}
  //           </Space>
  //         ),
  //       },
  //     ],
  //   },
  // ];

//   return (
//     <div>
//       <PickupModal
//         showModal={showModal}
//         setShowModal={setShowModal}
//         userData={rowInfo}
//         totalusercompleted
//       />

//       {/* <UserContainer>
//         <NavBarLeft>
//           <BreadCrumb
//             pages={pages}
//             current="Completed Schdeule"
//             previous={previous}
//           />
//         </NavBarLeft>
//       </UserContainer> */}
//       <Tabcontent
//         data={data}
//         totalPages={totalPages}
//         onRefresh={onRefresh}
//         // setCurrentPage={setCurrentPage}
//         // onSwitch={onSwitch}
//       />
//     </div>
//   );
// };

// export default UserCompletedSchedule;
