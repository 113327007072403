import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import ContentCard from "../../../../components/UI/ContentCard";
import DataTable from "../../../../components/UI/Table";
import StyledButton from "../../../../components/UI/btn";
import { deleteSpaceOwner } from "../../../../store/actions";

import {
  filterSpaceOwnersDataTable,
  getBank,
  getSpaceOwnerStat,
  getSpaceOwnersDataTable,
  searchSpaceOwnersDataTable,
} from "../../../../store/actions";
import {
  TotalCardSpaceOwner,
  spaceColors as colors,
} from "../../../../utils/data";

const SpaceOwnerHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;

const Vector = styled.div`
  ${tw`flex justify-between items-center px-4 py-3 h-[40px]`}
`;

const WastePickerHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;

const SpaceOwner = () => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const { spaceStats } = useSelector((state) => state.spaceOwner);
  const { allBanks } = useSelector((state) => state.pickers);
  const [spaceOwnerTableData, setSpaceOwnerTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [, , setCurrentPage] = useState(1);
  const [, , setTotalPages] = useState(1);
  const [, , setRowInfo] = useState([]);
  const [, , setShowPending] = useState(false);
  const [, , setPostMessage] = useState();
  const [showPostModal, setPostModal] = useState(false);

  // const selected =[]
  const history = useHistory();

  const spaceOwnerDetails = TotalCardSpaceOwner.map((card) => {
    switch (card.id) {
      case 1:
        return { ...card, user: spaceStats?.total?.count ?? 0 };
      case 2:
        return { ...card, user: spaceStats?.active?.count ?? 0 };
      case 3:
        return { ...card, user: spaceStats?.inactive?.count ?? 0 };
      default:
        return card;
    }
  });

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const dispatch = useDispatch();

  const fetchStats = async () => {
    return await dispatch(getSpaceOwnerStat());
  };

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    if (!allBanks) dispatch(getBank());
  }, []);

  const fetchSpaceOwnerDataTable = async (page = 1) => {
    const payloads = {
      payload,
      page,
    };
    const res = await dispatch(getSpaceOwnersDataTable(payloads));
    if (!res?.error) {
      const { partners, ...paginationData } = res?.payload?.data;
      setSpaceOwnerTableData(partners);

      setPaginationData({ ...paginationData, page });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const searchSpaceOwnerDataTable = async (key, page = 1) => {
    const payloads = {
      key,
      page,
    };
    const res = await dispatch(searchSpaceOwnersDataTable(payloads));
    if (!res?.error) {
      const { partners, ...paginationData } = res?.payload?.data;
      setSpaceOwnerTableData(partners);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData?.totalPages);
    }
  };

  const filtersSpaceOwnerDataTable = async (date, page = 1) => {
    const payloads = {
      payload: date,
      page,
    };
    const res = await dispatch(filterSpaceOwnersDataTable(payloads));
    if (!res?.error) {
      const { partners, ...paginationData } = res?.payload?.data;
      setSpaceOwnerTableData(partners);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData?.totalPages);
    }
  };

  useEffect(() => {
    fetchSpaceOwnerDataTable();
  }, []);

  const onRefresh = () => {
    fetchSpaceOwnerDataTable();
  };

  const deleteOwner = async (data) => {
    console.log("data t0 delete", data._id);
    const res = await dispatch(deleteSpaceOwner(data._id));
    const { message } = res.payload;
    setPostMessage(message);

    if (!res.error) {
      setPostModal(!showPostModal);
    }
  };

  const data = spaceOwnerTableData?.map((data) => ({
    _id: data?._id,
    name: data?.fullname,
    dropOffLocations: data?.dropOffLocations,
    phone: data?.phone,
    email: data?.email,
    isActive: data?.isActive ? "active" : "in-active",
  }));

  const columns = [
    {
      title: "Partners Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No Of Locations",
      dataIndex: "dropOffLocations",
      key: "dropOffLocations",
      render: (_, record) => {
        return <p>{record?.dropOffLocations?.length}</p>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `/admin/space_details/${record?._id}`,
            }}
          >
            <StyledButton
              type=""
              buttonStyle="btn--primary--outline"
              buttonSize="btn--small"
              onClick={() => {
                setRowInfo(record);
                setShowPending(true);
              }}
            >
              See More
            </StyledButton>
          </Link>

          {/* <Vector>
            <Disable
              data={selectedInfo}
              onRefresh={onRefresh}
              deletehandler={() => deleteOwner(record)}
              deactivate={deactivate}
            />
          </Vector> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="relative">
      <div className="flex flex-col gap-3">
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container ">
          {spaceOwnerDetails?.map((el, i) => {
            return (
              <ContentCard
                ImgUrl={el.icon}
                title={el.title}
                amount={el.user}
                style={{ color: colors[i] }}
                key={i}
              />
            );
          })}
        </div>

        <SpaceOwnerHeader>
          {
            user_claims?.create &&
            <Link to="/admin/space_owner">
              <StyledButton
                buttonStyle="btn--primary--outline"
                buttonSize="btn--medium"
                className="flex justify-between items-center"
                btnClass="!text-sm !px-3"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Add Space Owners
              </StyledButton>
            </Link>            
          }

        </SpaceOwnerHeader>
      </div>

      <div className="mt-10">
        <DataTable
          data={data}
          onSearch={searchSpaceOwnerDataTable}
          columns={columns}
          setCurrentPage={setCurrentPage}
          totalPages={paginationData?.totalPages}
          onFilter={filtersSpaceOwnerDataTable}
          onRefresh={onRefresh}
          paginationData={paginationData}
          onFetch={fetchSpaceOwnerDataTable}
          header
        />
      </div>
    </div>
  );
};

export default SpaceOwner;
