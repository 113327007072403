import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import BreadCrumb from "../../../../components/UI/breadCrumbs";
import StyledButton from "../../../../components/UI/btn";
import Button from "../../../../components/UI/button";
import Modal from "../../../../components/UI/modal";
import MultiSelect from "../../../../components/UI/multiSelect";
import Select from "../../../../components/UI/select";
import FormInput from "../../../../components/auth/FormInput";
import DeleteModal from "../../../../components/common/DeleteModal";
import SpaceOwnerCard from "../../../../components/spaceOwner/SpaceOwnerDropoffCard";
import { FormContainer } from "../../../../components/styledElements";
import useForm from "../../../../hooks/useForm";
import {
  deleteDropOffLocation,
  editSpaceOwner,
  getBank,
  getOwnerDetails,
  validateAccount,
} from "../../../../store/actions";
import { formatSelectOptions, formatSpaceDetails } from "../../../../utils";
import NewSpaceModal from "./NewSpaceModal";

const SpaceOwnerContainer = styled.div`
  ${tw`grid items-center gap-4 w-full`}
`;
const InfoContainer = styled.div`
  ${tw`bg-white pt-4 pb-1 px-10`}
`;
const FormTitle = styled.h1`
  ${tw`text-2xl font-extrabold tracking-wide `}
`;

let bioFormEntries = {
  name: {
    label: "Name",
    value: "",
    placeholder: "Organisation Name",
    rules: [(v) => !!v || "Company Name is required"],
  },
  phone: {
    label: "Phone Number",
    value: "",
    placeholder: "Phone Number",
    rules: [
      (v) => !!v || "Phone Number is required",
      (v) =>
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v) ||
        "contact line is invalid",
    ],
  },
  email: {
    label: "Email Address",
    value: "",
    placeholder: "Email Address",
    rules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "▲ E-mail must be valid",
    ],
  },
  address: {
    label: "Address",
    value: "",
    placeholder: "Address",
    rules: [(v) => !!v || "Address is required"],
  },
};

const bankFormEntries = {
  bank: {
    label: "Bank",
    optionIdxs: null,
    type: "select",
    options: [],
    rules: [(v) => !!v || "select a bank"],
  },
  accountNumber: {
    label: "Account Number",
    value: "",
    placeholder: "Account Number",

    rules: [
      (v) => {
        if (!v) return true;
        else
          return (
            (v.length > 4 && v.length < 18) || "Enter a valid account number"
          );
      },
    ],
  },
  accountName: {
    label: "Account Name",
    value: "",
    placeholder: "",
    rules: [(v) => !!v || "Account data not found"],
    disabled: true,
  },
};

const EditSpaceOwnerForm = ({ match }) => {
  const { allBanks } = useSelector((state) => state.pickers);
  const [bankData, setBankData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const dispatch = useDispatch();
  let bankName;
  const {
    app: { error },
  } = useSelector((state) => state);

  const {
    spaceOwnerDetails: { partner, dropoffLocations },
  } = useSelector((state) => state.spaceOwner);
  const [message, setMessage] = useState("");
  const [showPostAction, setPostAction] = useState(false);
  const [toActive, ,] = useState(false);
  const history = useHistory();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [landId, ,] = useState();
  const [dropOffLocations, ,] = useState(dropoffLocations);
  const { pathname } = useLocation();
  const pathId = pathname.split("/")[3];
  const { setValue, errorMsgs, formValues } = useForm(bioFormEntries);
  const {
    setValue: setBankValue,
    errorMsgs: setErrMsg,
    formValues: formBankValue,
  } = useForm(bankFormEntries);

  const textClass = " text-[#464F54] text-[24px] font-bold opacity-50 pt-4";

  useEffect(() => {
    dispatch(getOwnerDetails(pathId));
  }, []);

  const pages = [
    {
      name: "Space Owner Details",
      link: `/admin/space_details/${match?.params?.id}`,
    },
  ];

  useEffect(() => {
    if (!partner) return;
    bioFormEntries.name.value = partner && partner?.fullname;
    bioFormEntries.phone.value = partner?.phone;
    bioFormEntries.email.value = partner?.email;
    bioFormEntries.address.value = partner?.address;
  }, [partner]);

  useEffect(() => {
    if (!allBanks) return;
    const banks = formatSelectOptions(allBanks, "name", "value");
    bankFormEntries.bank.options = banks;
    // bankFormEntries.accountName.value = partner?.accountName;
    // bankFormEntries.accountNumber.value = partner?.accountNumber;
  }, [allBanks]);

  useEffect(() => {
    const resolveAccount = async (accountNumber) => {
      const bank_code = formBankValue.bank;
      bankName = allBanks.find((bank) => bank.value === formBankValue.bank);

      const data = {
        accountNumber,
        bank_code,
      };
      const res = await dispatch(validateAccount(data));
      if (res.payload.account_name) {
        setBankValue("accountName", res.payload.account_name);
        const accountInfo = {
          accountName: res.payload.account_name,
          accountNo: res.payload.account_number,
          bankName: bankName.name,
          sortCode: formBankValue.bank,
        };

        setBankData(accountInfo);
      } else {
        setBankValue("accountName", "");
        setPostAction(true);
      }
    };
    const accountNum = formBankValue.accountNumber;

    if (accountNum.length === 10) {
      resolveAccount(accountNum);
    } else if (accountNum === "") setBankValue("accountName", "none");
  }, [formBankValue.accountNumber]);

  useEffect(() => {
    if (!allBanks) dispatch(getBank());
  }, []);

  const handleSubmit = async () => {
    const spaceOwnerData = {
      fullname: formValues?.name,
      email: formValues?.email,
      phone: formValues?.phone,
      address: formValues?.address,
      accountName: bankData?.accountName,
      accountNumber: bankData?.accountNo,
      bankName: bankData?.bankName,
      setToActive: toActive,
      landAddresses: dropOffLocations,
    };

    const data = { id: match?.params?.id, updateData: spaceOwnerData };
    const res = await dispatch(editSpaceOwner(data));
    if (!res.error) {
      setPostAction(true);
      setMessage(res?.payload?.message);

      setTimeout(() => {
        history.push(`/admin/space_details/${match?.params?.id}`);
      }, 2000);
    }
  };

  const deleteHandler = async () => {
    const data = {
      locationId: landId,
      partnerId: match?.params?.id,
    };
    const res = await dispatch(deleteDropOffLocation(data));
    if (!res.error) {
      setTimeout(() => {
        history.push(`/admin/space_details/${match?.params?.id}`);
      }, 2000);
    }
  };

  useEffect(() => {
    if (partner) {
      [bioFormEntries] = formatSpaceDetails(partner, dropoffLocations);
    }
  }, [partner, dropoffLocations]);

  return (
    <>
      <BreadCrumb pages={pages} current="Edit Profile Details" />

      <NewSpaceModal
        mode={modalMode}
        showModal={showModal}
        setShowModal={setShowModal}
        id={match?.params?.id}
      />

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={deleteHandler}
        type="dropoff"
        title="Dropoff"
      />

      <Modal
        show={showPostAction}
        close={() => {
          setPostAction(false);
        }}
        type="postAction"
      >
        <p>{!error ? message : error}</p>
      </Modal>

      <SpaceOwnerContainer>
        <InfoContainer>
          <FormTitle className={textClass}>Bio</FormTitle>
          <FormContainer>
            {Object.entries(bioFormEntries).map(([key, input]) => (
              <div className="w-full md:w-[45%] md:gap-x-10">
                <FormInput
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.label}
                  key={key}
                  changeHandler={(e) => setValue(key, e.target.value)}
                  errorMsg={errorMsgs[key]}
                  value={formValues[key]}
                  disabled={input.disabled}
                />
              </div>
            ))}
          </FormContainer>
        </InfoContainer>

        <InfoContainer>
          <FormTitle className={textClass}>Bank & Account Details</FormTitle>
          <FormContainer>
            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
              {Object.entries(bankFormEntries).map(([key, input]) => {
                switch (input.type) {
                  case "select":
                    return (
                      <Select
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.label}
                        changeHandler={(v) => setBankValue(key, v)}
                        optionIdx={input.optionIdx !== null && input.optionIdx}
                        disabled={input.disabled}
                        checkbox={input.checkbox}
                      />
                    );
                  case "multiselect":
                    return (
                      <MultiSelect
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.title || input.label}
                        changeHandler={(v) => setBankValue(key, v)}
                        optionIdxs={input.optionIdxs || null}
                        disabled={input.disabled}
                      />
                    );
                  default:
                    return (
                      <FormInput
                        placeholder={input.placeholder}
                        type={input.type}
                        label={input.label}
                        key={key}
                        changeHandler={(e) => setBankValue(key, e.target.value)}
                        errorMsg={setErrMsg[key]}
                        value={formBankValue[key]}
                        disabled={input.disabled}
                      />
                    );
                }
              })}
            </div>
          </FormContainer>
        </InfoContainer>

        <InfoContainer className="!pb-3">
          <div className="flex justify-between items-center py-5">
            <FormTitle className={textClass}>Drop-Off Locations</FormTitle>
            <StyledButton
              buttonStyle="btn--primary--outline"
              buttonSize="btn--medium"
              className="flex justify-between items-center"
              onClick={() => {
                setModalMode("create");
                setShowModal(true);
              }}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Add Location
            </StyledButton>
          </div>
          <br />
          <div>
            {dropoffLocations?.map((location) => {
              return (
                <SpaceOwnerCard
                  name={location?.address}
                  key={location?._id}
                  value={location?.address}
                  phone={location?.contactPhone}
                  id={location?._id}
                  matchId={match?.params?.id}
                />
              );
            })}
          </div>
        </InfoContainer>

        <div className="mt-5 mb-3">
          <Button width="" submit onClick={() => handleSubmit()}>
            save changes
          </Button>
        </div>
      </SpaceOwnerContainer>
    </>
  );
};

export default EditSpaceOwnerForm;
