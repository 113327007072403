import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../components/UI/btn";
import ResourcesCard from "../../components/UI/Resources/ResourcesCard";
import { getResources } from "../../store/actions";
import UploadResourceModal from "../../components/UI/Resources/UploadResourceModal";
// import PaginationBars from "../../components/UI/Paginationbars";
import { PaginationBars } from "../../components/UI/Resources/Pagination";
import DataTable from "../../components/UI/Table";
import moment from "moment";
import AreaFilter from "../../components/UI/AreaFilter";
import { IoChevronDownSharp } from "react-icons/io5";
import Filter from "../../components/UI/Filter";
import DateRangeFilter from "../../components/UI/DateRangeFilter";
import DropDownFilter from "../../components/UI/DropDownFilter";
import { Popover, Tag } from "antd";
import { truncate } from "../../utils/constants";
import BarChart from "../../components/UI/BarChart";
import PerLocation from "./PerLocation";
import WasteCollected from "./WasteCollected";
import { fetchResourceRecycleMaterial, fetchResourceSummaryData } from "../../store/actions/resourceActions";
import { formatAmount } from "../../utils";

const ResourceSummaryContainer = styled.div`
    ${tw`bg-white gap-[20px] rounded-sm grid grid-cols-2 md:grid-cols-4 p-2 w-full`}
  // display: grid;
  // gap: 20px;
`;
const ResourceHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;
const ResourceList = styled.div`
  ${tw`bg-white`}
`;
const BarChartHeader = styled.div`
  ${tw`flex items-center justify-between py-3`}
  border-bottom: 1px solid rgba(194, 194, 194, 0.4);
`;
const BarChartTitle = styled.h3`
  ${tw`text-xl font-extrabold`}
  letter-spacing:2;
`;
const BarChartContainer = styled.div`
  ${tw`bg-white `}
  padding: 3rem 1rem 0;
`;
const StatsBody = styled.div`
  ${tw`bg-white text-center text-secondary text-lg`}
  padding: 4rem 1rem;
`;

const RecyclerWaste = () => {

  const [bodyData, setBodyData] = useState();
  const [activeBtn, setActiveBtn] = useState('location');
  const [statsBodyData, setStats] = useState([]);
  const current = moment(new Date()).format("MMMM");
  const [summaryData, setSummaryData] = useState();
  const [recycleWaste, setRecycleWaste] = useState();
  const dispatch = useDispatch()

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2024-01-01",
    end: d,
  };

  const monthIndex = d.getMonth() + 1;
  console.log(monthIndex)

  const[year, setYear] = useState(d.getFullYear())


  const onActivityFilter =() =>{

  }


  const fetchSummaryData = async (page = 1) => {

		const res = await dispatch(
			fetchResourceSummaryData({
				monthIndex
			})
		);
		if (!res.error) {
      const {payload} = res
      console.log(payload)
      setSummaryData(payload)
		}
	};

  const FilterSummaryData = async (key) => {

    console.log(key)
		const res = await dispatch(
			fetchResourceSummaryData({
				monthIndex:key 
			})
		);
		if (!res.error) {
      const {payload} = res
      console.log(payload)
      setSummaryData(payload)
		}
	};


  const fetchRecycleMaterial = async (date, page = 1) => {

		const res = await dispatch(
			fetchResourceRecycleMaterial({
				monthIndex
			})
		);
		if (!res.error) {
      const {payload} = res
      setRecycleWaste(payload)
		}
	};
  const filterRecycleMaterial = async (date) => {

		const res = await dispatch(
			fetchResourceRecycleMaterial({
				monthIndex:date
			})
		);
		if (!res.error) {
      const {payload} = res
      setRecycleWaste(payload)
		}
	};

  const handleFilter= (key)=>{
    FilterSummaryData(key)
    filterRecycleMaterial(key)
  }

  useEffect(()=>{
    fetchSummaryData()
    fetchRecycleMaterial()
  },[])




  const cardData = [
		{
		  title:"Total Recycled",
  amount: `${summaryData?.totalRecycledMaterials?.data || 0}KG`,
		  percent: summaryData?.totalRecycledMaterials?.percentage || 0,
		  icon:'/assets/images/r1.svg'
		},	
		{
		  title:'Highest Recycled Material',
		  amount:`${recycleWaste?.totalQuantity || 0}KG`,
		  percent:'Transparent PET Bottles',
		  icon:'/assets/images/r2.svg'
		},	
		{
		  title:'Pending Schedules',
		  amount:summaryData?.pendingSchedules?.data || 0,
		  percent:summaryData?.pendingSchedules?.percentage || 0,
		  icon:'/assets/images/r3.svg'
		},	
		{
		  title:"Completed Schedules",
		  amount:summaryData?.completedSchedules?.data || 0, 
		  percent:summaryData?.completedSchedules?.percentage || 0, 
		  icon:'/assets/images/r4.svg'
		  
		},	
	  
	  ]



  const columns = [
    {
      title: "Organization",
      dataIndex: "aggregatorId",
      key: "aggregatorId",
    },
    {
      title: "Total Waste Collected (KG)",
      dataIndex: "fullname",
      key: "fullname",
    },
    // {
    //   title: "Category",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (wastes) => (
    //     <span>
    //       {(wastes.slice(0, 3) || []).map((waste) => {
    //         return <Tag key={waste}>{waste?.name || waste}</Tag>;
    //       })}
    //     </span>
    //   ),
    // },
  
    // {
    //   title: "Number of Users",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (categories) => (
    //     <span>
    //       {(categories.slice(0, 3) || []).map((waste) => {
    //         return (
    //           <Tag key={waste}>
    //             <Popover content={waste?.name || waste}>
    //               {truncate(waste?.name, 10)}
    //             </Popover>
    //           </Tag>
    //         );
    //       })}
    //     </span>
    //   ),
    // },
  
    // {
    //   title: "Recycler",
    //   dataIndex: "recycler",
    //   key: "recycler",
    // },
    // {
    //   title: "Organization",
    //   dataIndex: "organisation",
    //   key: "organisation",
    // },
  ];

  return (
    <div className="space-y-6">

      <div className="flex justify-between">
        <div>
          <h1 className=" font-Int text-[24px] font-[600]">Waste Collection Impact</h1>
          <p className="text-[#667185] font-Int text-[14px] mt-[-20px]">Manage all Pakam household impact</p>
        </div>
        <Filter isMonth={true} onFilter={handleFilter} />
      </div>

      <ResourceSummaryContainer>
      {/* <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container mt-2 mb-2"> */}

        {
          cardData.map((i, id)=>{
            return(
            <ResourceSummaryCard key={id} percent={i.percent} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
            )
          })
        }
      {/* </div> */}

      
      </ResourceSummaryContainer>

      <ResourceSummaryContainer>
        {/* <div className="flex p-2 w-full gap-6"> */}
          <button onClick={()=>setActiveBtn('location')} className={` py-2 font-bold rounded-md border ${activeBtn === 'location' ? 'bg-[#FAFCEC]  border-[#B0CCB0]' : 'bg-[#F0F2F5] border-[#D0D5DD]'}`}>Impart Per Location</button>
          <button onClick={()=>setActiveBtn('waste')} className={`py-2  font-bold rounded-md border ${activeBtn === 'waste' ? 'bg-[#FAFCEC]  border-[#B0CCB0]' : 'bg-[#F0F2F5] border-[#D0D5DD]'}`}>Total Waste Collected</button>
        {/* </div> */}
      </ResourceSummaryContainer>

      {
        activeBtn === 'location' ? <PerLocation/> : <WasteCollected/>
      }

    </div>
  )


  
};

export default RecyclerWaste;


export const ResourceSummaryCard = ({title, amount , icon, percent}) =>{
	return(
		<div className="shadow-md rounded-lg bg-white border border-[#E4E7EC] p-3 flex justify-between items-center gap-3">
			<div>
				<p className="font-[400] text-[#475367] font-Int text-[12px]">{title}</p>
				<h1 className="font-[600] text-[#344054] font-Int text-[18px] ">{amount}</h1>

        <div>
          {
            !isNaN(percent) ? 
            <p className={`${percent > 0?  "text-[#036B26]": percent < 0 ? 'text-[#D42620]' : 'text-[#333]'}`}><span className={`${percent > 0?  "bg-[#E7F6EC]": percent < 0 ? 'bg-[#F9DEDE]' : 'bg-[#f1f1f1]'} rounded-lg px-1 py-[2px] text-[11px]`}> <img className="inline pr-1" src={`/assets/images/${percent > 0 ? 'increase.svg': percent < 0 ?  'decrease.svg' : ''}`} alt="" /> {percent}%</span>
            <span className="font-medium text-[12px]"> {percent > 0 ? 'Increase' : percent < 0 ? 'Decrease' : null}</span> </p>
            :
            <span className="font-medium text-[12px]"> {percent}</span>

          }

          
        </div>

			</div>
			<img className="w-40px] h-[40px]" src={icon} alt="icon" />
		</div>
	)
}