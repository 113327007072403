import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { getCategory, updateCategory, updateSubCategory } from "../../store/actions/wasteCategoryAction";
import StyledButton from "../UI/btn";
import modal from "../UI/modal";
import FormInput from "../auth/FormInput";
import { FlexContainer, TitleText } from "../styledElements";

const Modal = styled(modal)`
  ${tw``};
`;

const UpdateMainCategoryModal = ({ showModal, setShowModal, name, id }) => {
  const [showPostModal, setPostModal] = useState(false);
  const [catName, setCatName] = useState('');


  const {
    app: { error },
  } = useSelector((state) => state);
  
  const dispatch = useDispatch();

  const closePostModal = () => {
    setPostModal(false);
    console.log('close')
  };

  useEffect(()=>{
    if (name){
      setCatName(name)
    }
  }, [name])
  
  useEffect(()=>{
    if (!showModal){
      setCatName(name)
    }
  }, [showModal])

  const handleChangeCategory = (name) =>{
    // if (catName !== ''){
      setCatName(name)
    // }
  }

  const updateCategoryHandler = async () => {
    setShowModal(false);
    const categoryData = {
      name: catName
    };

    const payload = {
      id,
      categoryData,
    };

    const res = await dispatch(updateCategory(payload));
    if (!res.error) {
      dispatch(getCategory());
      setPostModal(true);
      // Add your logic for success message here
    }
  };

  return (
    <>
      <Modal
        color={error ? "red" : "#005700"}
        type="postAction"
        show={showPostModal} // Handle the post modal logic as needed
        close={closePostModal} // Handle the post modal close logic as needed
      >
        {!error ? "Waste Category updated successfully" : error}
      </Modal>
      <Modal show={showModal} close={() => setShowModal(false)} width="30rem">
        <FlexContainer className="justify-between mb-4">
          <TitleText>Edit Waste category</TitleText>
          <StyledButton
            buttonSize="btn--medium"
            onClick={() => setShowModal(false)}
          >
            close
          </StyledButton>
        </FlexContainer>
        <div className="flex flex-col">
          <FormInput
            placeholder="Type Waste Category"
            label="Waste Category"
            height="3.5rem"
            type="text"
            value={catName}
            changeHandler={(e) => handleChangeCategory(e.target.value)}
          />

          <StyledButton
            buttonSize="btn--medium"
            buttonStyle="btn--primary"
            onClick={updateCategoryHandler}
            disabled={!catName}
          >
            Update
          </StyledButton>
        </div>
      </Modal>
    </>
  );
};

export default UpdateMainCategoryModal;
